import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, FormControlLabel, Button } from '@mui/material';
import { COLOR_WHITE } from '../../constants/colors';
import { useStore } from '../../hooks/useStore';
import { PrimarySwitch } from '../../components/Switch/PrimarySwitch';
import { observer } from 'mobx-react-lite';
import { WorkSpaceItems } from '../../models/WorkSpace';
import { useToasts } from 'react-toast-notifications';

const PageContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin: 0;
  gap: 16px;
`;

const WorkPageContainer = styled(Stack)`
  border-radius: 8px;
  background-color: ${COLOR_WHITE};
  box-shadow:
    0 2px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.05);
  height: 100%;
  overflow: auto;
  padding: 24px;
  gap: 16px;
  width: 100%;
`;

const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface Setting {
  id: string;
  [key: string]: boolean | string;
}

const WorkSpaceSettingPage = () => {
  const {
    localizationStore: { i18next: i18n },
    WorkSpaceStore: { getWorkSpaceSetting, workSpaceList, createWorkSpace },
  } = useStore();

  const [settings, setSettings] = useState<Setting[]>([]);
  const [initialSettings, setInitialSettings] = useState<Setting[]>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [workSpaceData, setWorkSpaceData] = useState<WorkSpaceItems[]>([]);
  const { addToast } = useToasts();

  useEffect(() => {
    (async () => {
      await getWorkSpaceSetting();
    })();
  }, []);

  useEffect(() => {
     if (workSpaceList) {
       const sortedList = workSpaceList.sort((a, b) => a.order - b.order);
       setWorkSpaceData(sortedList);
     }
  }, [workSpaceList]);

  useEffect(() => {
    const initialSettings: Setting[] = workSpaceData.map(option => ({
      id: option.id,
      [option.workflowKey]: option.isEnabled,
    }));
    setSettings(initialSettings);
    setInitialSettings(initialSettings);
  }, [workSpaceData]);

  useEffect(() => {
    setIsChanged(JSON.stringify(settings) !== JSON.stringify(initialSettings));
  }, [settings, initialSettings]);

  const handleSwitchChange = (id: string, workflowKey: string) => {
    setSettings(prevSettings =>
      prevSettings.map(setting => (setting.id === id ? { ...setting, [workflowKey]: !setting[workflowKey] } : setting))
    );
  };

  const handleSaveChanges = async () => {
    const transformedSettings = settings.map(setting => {
      const [workflowKey] = Object.keys(setting).filter(key => key !== 'id');
      return {
        id: setting.id,
        isEnabled: workflowKey ? Boolean(setting[workflowKey]) : false,
      };
    });
    await createWorkSpace(transformedSettings);
    addToast(i18n.t('adminPortal.compliance.save.successful'), { appearance: 'success' });
    setInitialSettings(settings);
  };

  return (
    <PageContainer>
      <WorkPageContainer spacing={3}>
        <HeaderContainer>
          <Typography variant="h4" sx={{ fontSize: '22px', fontWeight: 700 }}>
            {i18n.t('adminPortal.tabs.setting.action')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
            disabled={!isChanged}
            sx={{
              background: isChanged ? '#131F4D' : '#E0E0E0',
              color: isChanged ? 'white' : '#A0A0A0',
            }}
          >
            {i18n.t('common.saveChanges.action')}
          </Button>
        </HeaderContainer>
        <Stack spacing={2}>
          {workSpaceData?.map(option => {
            const currentSetting = settings.find(setting => setting.id === option.id);
            return (
              <FormControlLabel
                key={option.workflowKey}
                control={
                  <PrimarySwitch
                    checked={Boolean(currentSetting?.[option.workflowKey])}
                    onChange={() => handleSwitchChange(option.id, option.workflowKey)}
                  />
                }
                label={option.displayKey}
              />
            );
          })}
        </Stack>
      </WorkPageContainer>
    </PageContainer>
  );
};

export default observer(WorkSpaceSettingPage);
