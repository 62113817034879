import { ApiConfig } from './ApiConfig';
import { CreateFileDto, File } from '../models/File';
import { fileStatus } from '../constants/fileStatus';

export class FileApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  create(file: CreateFileDto): Promise<File> {
    return this.apiConfig.sendRequest('POST', '/files', file);
  }

  getById(id: string): Promise<File> {
    return this.apiConfig.sendRequest('GET', `/files/${id}`);
  }

  startProcessing(conversationId: string, fileId: string) {
    return this.apiConfig.sendRequest('GET', `/files/start-processing/${conversationId}/${fileId}`);
  }

  updateFileStatus(fileId: string, status: fileStatus) {
    return this.apiConfig.sendRequest('PATCH', `/files/${fileId}`, { status });
  }

  updateFileName(fileId: string, name: string) {
    return this.apiConfig.sendRequest('PATCH', `/files/${fileId}`, { name });
  }
}
