import { ApiConfig } from './ApiConfig';
import { SelectedSqlConnection, IntegrationModel } from '../models/IntegrationModel';

export class IntegrationApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getIntegrations(): Promise<IntegrationModel[]> {
    return await this.apiConfig.sendRequest('GET', '/integrations');
  }

  async upsertIntegrations({ integrations }: { integrations: IntegrationModel[] }) {
    return await this.apiConfig.sendRequest('POST', '/integrations', { integrations });
  }

  // api for checking SQL connection
  async checkConnection(data: SelectedSqlConnection) {
    return await this.apiConfig.sendRequest('POST', '/integrations/check-connection', data);
  }
}
