import React, { useState } from 'react';
import { Menu, MenuItem, SxProps } from '@mui/material';
import { Check, KeyboardArrowDown } from '@mui/icons-material';

import { COLOR_PRIMARY } from '../../constants/colors';
import { roleOptions } from '../../constants/roleOptions';

import { useStore } from '../../hooks/useStore';
import RoleLabel from './RoleLabel';

const RolesDropdown = ({
  onChange,
  value,
  disabled,
  backgroundColor,
  color,
  sx,
  ellipsis = false,
  backgroundOnActive = false,
}: {
  onChange: (value: string) => void;
  value?: string;
  disabled: Array<string>;
  backgroundColor?: string;
  color?: string;
  sx?: SxProps;
  ellipsis?: boolean;
  backgroundOnActive?: boolean;
}) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeOption = roleOptions.find(item => item.id === value) || roleOptions[0];

  const onValueChange = (value: string) => {
    onChange(value);
    handleClose();
  };

  let background = backgroundColor;
  let currentColor = color;
  if (backgroundOnActive) {
    background = anchorEl ? backgroundColor : undefined;
    currentColor = anchorEl ? color : undefined;
  }

  return (
    <>
      <RoleLabel
        type={i18n.t(activeOption?.name)}
        onClick={handleClick}
        icon={<KeyboardArrowDown sx={{ width: '24px', height: '24px', marginLeft: '8px' }} />}
        color={currentColor}
        ellipsis={ellipsis}
        sx={{
          height: '42px',
          padding: '8px 16px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: background,
          color: currentColor,
          ...sx,
        }}
      />
      <Menu id="name" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {roleOptions?.map((item, index) => (
          <div key={`role-${index}`}>
            {!disabled.includes(item.id) ? (
              <MenuItem
                onClick={() => onValueChange(item.id)}
                key={index}
                sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '46px' }}
              >
                <RoleLabel type={i18n.t(item.name)} color={item.id === value ? COLOR_PRIMARY : undefined} />
                {item.id === value && <Check fontSize={'small'} sx={{ color: COLOR_PRIMARY }} />}
              </MenuItem>
            ) : null}
          </div>
        ))}
      </Menu>
    </>
  );
};

export default RolesDropdown;
