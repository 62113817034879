import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Stack } from '@mui/material';
import styled from 'styled-components';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import Flex from '../../utils/flex/Flex';
import { useStore } from '../../../hooks/useStore';
import { LoadingSpinner } from '../../spinner/LoadingSpinner';
import FlexRowStart from '../../utils/flex/FlexRowStart';
import IconByAction from './IconByAction';
import ViewModeSimpleRow from '../../viewMode/ViewModeSimpleRow';
import LockIcon from '@mui/icons-material/Lock';

interface ViewPromptModalProps {
  id: string | null;
  closeModal: () => void;
}

const TeamContainer = styled.div`
  padding: 6px 8px;
  background: ${GRAY_COLORS.GRAY_100};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const PromptDetailPage = ({ id, closeModal }: ViewPromptModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    promptsStore: { getPromptById, setEmptyPrompt, currentPrompt },
    localizationStore: { locale },
    auditLogsStore: { currentAuditLog },
  } = useStore();

  moment.locale(locale);

  const [loading, setLoading] = useState(false);

  const { title, content, user, teams, recommendedModel } = currentPrompt;

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      setLoading(true);
      await getPromptById(id);
      setLoading(false);
    })();

    return () => {
      setEmptyPrompt();
    };
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Stack sx={{ padding: '24px', display: 'flex', height: '100%' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          backgroundColor: COLOR_WHITE,
          gap: '16px',
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          {teams?.length ? (
            <Flex sx={{ gap: '8px' }}>
              {teams?.map((team, index) => (
                <div key={`prompt-detail-team-${index}`}>
                  {index > 2 ? null : (
                    <TeamContainer>
                      <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
                        {teams.length > 2 ? (index < 2 ? team.name : `+${teams.length - 2}`) : team.name}
                      </Typography>
                    </TeamContainer>
                  )}
                </div>
              ))}
            </Flex>
          ) : (
            <TeamContainer>
              <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
                {'Private'}
              </Typography>
              <LockIcon sx={{ color: GRAY_COLORS.GRAY_5, height: '12px' }} />
            </TeamContainer>
          )}

          <IconButton size={'small'} onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
        <Typography variant={'h6'} sx={{ fontSize: '18px', fontWeight: 700 }}>
          {title}
        </Typography>
        {currentAuditLog?.action && (
          <FlexRowStart sx={{ gap: '12px' }}>
            <IconByAction action={currentAuditLog?.action} />
            <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
              {`This prompt was ${currentAuditLog?.action}d by ${user?.fullName || currentAuditLog.userName} on ${moment
                .tz(currentAuditLog?.createdAt, 'EST')
                .format('MMM DD, YYYY')}`}
            </Typography>
          </FlexRowStart>
        )}
        <Typography variant={'body1'} sx={{ color: GRAY_COLORS.GRAY_8, lineHeight: '26px' }}>
          {content}
        </Typography>
        <ViewModeSimpleRow
          title={i18n.t('home.newConversation.settings.model')}
          text={recommendedModel?.modelVersion || 'Default'}
        />{' '}
        <ViewModeSimpleRow
          title={i18n.t('home.newConversation.settings.accuracy')}
          text={recommendedModel?.temperature || 'Default'}
        />
        <ViewModeSimpleRow
          title={i18n.t('home.newConversation.settings.length')}
          text={recommendedModel?.maxTokens || 'Default'}
        />
      </Flex>
    </Stack>
  );
};

export default observer(PromptDetailPage);
