import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { COLOR_BACKGROUND, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import { IntegrationModel } from '../../../models/IntegrationModel';
import { PrimarySwitch } from '../../Switch/PrimarySwitch';
import { Headline4 } from '../../typography/Headlines';
import Flex from '../../utils/flex/Flex';
import FlexRowStart from '../../utils/flex/FlexRowStart';
import { getIntegrationLogo } from './IntegrationLogo';
import { handleGoogleDriveClientLoad } from './GoogleDriveIntegration';
import Button from '../../../components/buttons/Button';

const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${GRAY_COLORS.GRAY_300};
  background: ${COLOR_BACKGROUND};
`;

export const CategoryContainer = styled(Box)`
  height: 24px;
  width: fit-content;
  padding: 2px 8px;
  border: 1px solid #84e1bc;
  background: #def7ec;
  color: #057a55;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
`;

const IntegrationLogo = styled('img')`
  max-height: 24px;
`;

interface CardProps {
  integration: IntegrationModel;
  handleIsActiveChange: (id: string, isActive: boolean) => void;
}

const IntegrationCard = ({ integration, handleIsActiveChange }: CardProps) => {
  const {
    integrationsStore: { setSelectedIntegration },
    localizationStore: { i18next: i18n },
  } = useStore();

  const { name, description, id } = integration;
  // checking if settings of integration is array
  if (!Array.isArray(integration?.settings)) {
    integration.settings = [integration?.settings as any];
  }

  // managing on/off switch from configname only to make it more generic
  const isActiveDisabled = !integration?.settings?.[0].configuration?.configName;
  const integrationLogo = getIntegrationLogo(integration?.serviceName);

  return (
    <CardContainer>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
        {integration.tags ? <CategoryContainer>{integration.tags}</CategoryContainer> : <div></div>}
        <PrimarySwitch
          disabled={isActiveDisabled}
          checked={integration.isActive}
          onChange={event => handleIsActiveChange(id, event.target.checked)}
        />
      </Flex>
      <Box>
        <FlexRowStart sx={{ gap: '16px' }}>
          {integrationLogo ? <IntegrationLogo src={integrationLogo.src} alt={integrationLogo.alt} /> : <div></div>}
          <Headline4>{name}</Headline4>
        </FlexRowStart>

        <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '16px', color: GRAY_COLORS.GRAY_8 }}>
          {description}
        </Typography>
        <Button
          onClick={() => {
            integration.serviceName === 'google_workspace'
              ? handleGoogleDriveClientLoad()
              : setSelectedIntegration(integration);
          }}
          variant={'outlined'}
          sx={{
            border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
            color: `${COLOR_TEXT_PRIMARY} !important`,
            width: 'fit-content',
            padding: '7px 16px !important',
            height: '36px !important',
          }}
        >
          {i18n.t('config.button.action')}
        </Button>
      </Box>
    </CardContainer>
  );
};

export default observer(IntegrationCard);
