import { i18n } from 'i18next';

interface MethodProps {
  i18n: i18n;
}

export const getTableHeader = ({ i18n }: MethodProps) => {
  let headerCells = [
    {
      label: i18n.t('logsList.table.header.user'),
      property: 'fullName',
      width: '25%',
    },
    {
      label: i18n.t('logsList.table.header.source'),
      property: 'source',
      width: '10%',
    },
    {
      label: i18n.t('logsList.table.header.eventTimeAndDate'),
      property: 'createdAt',
      width: '25%',
    },
    {
      label: i18n.t('logsList.table.header.eventType'),
      property: 'type',
      width: '15%',
    },
    {
      label: i18n.t('logsList.table.header.status'),
      property: 'status',
      width: '20%',
    },
    {
      label: i18n.t('usersList.table.header.actions'),
      property: 'actions',
      width: '5%',
    },
  ];

  return headerCells;
};
