import { UserToken } from '../models/Token';
import { ApiConfig } from './ApiConfig';


export class TokenApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: any) {
    this.apiConfig = apiConfig;
  }
  getTokensByUserOrganization() {
    return this.apiConfig.sendRequest('GET', '/api-token');
  }
  createToken(tokenDto: UserToken) {
    return this.apiConfig.sendRequest('POST', '/api-token', tokenDto);
  }
  updateToken(id: string, updateTokenDto: Partial<UserToken>) {
    return this.apiConfig.sendRequest('PATCH', `/api-token/${id}`, updateTokenDto);
  }
  getTokenById(id: string) {
    return this.apiConfig.sendRequest('GET', `/api-token/getToken/${id}`);
  }
  removeToken(id: string) {
    return this.apiConfig.sendRequest('DELETE', `/api-token/${id}`);
  }
}