import React, { ChangeEvent, ReactNode, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import { SxProps, Typography } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import { SIZES_NUMBER_TINY_SMALL } from '../../constants/sizes';
import { COLOR_PRIMARY } from '../../constants/colors';

import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import { useStore } from '../../hooks/useStore';
import TextInput from '../inputs/TextInput';
import FlexRowEnd from '../utils/flex/FlexRowEnd';
import FlexColumnStart from '../utils/flex/FlexColumnStart';
import FlexRowCenter from '../utils/flex/FlexRowCenter';
import FlexRowStart from '../utils/flex/FlexRowStart';

interface ListActionsHeaderProps {
  pageSubtitle?: string;
  searchValue: string;
  searchInputStyles?: SxProps;
  currentSelection: string;
  componentType?: string;
  hasFilters?: boolean;
  hasRefresh?: boolean;
  changeCurrentSelection?: (newSelection: string) => void;
  onInputChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  displaySearch?: boolean;
  displayListViewType?: boolean;
  tableTitle?: string;
  clearFilters?: () => void;
  customRight?: ReactNode;
  additionalRow?: ReactNode;
  isRulesConfigList?: boolean;
  isServiceRequestListMobile: boolean;
  refreshData?: () => void;
  isSearchFoundList?: boolean;
  isConfiguration?: boolean;
  isGateway?: boolean;
  selectedItem?: any;
}

const inputStyle = {
  width: '229px',
  '.MuiInputBase-root': {
    height: '40px',
  },
  '.MuiOutlinedInput-input': {
    padding: '10px 14px',
    width: '229px',
  },
};

const ListActionsHeader = ({
  pageSubtitle,
  searchValue,
  searchInputStyles,
  changeCurrentSelection,
  currentSelection,
  onInputChange,
  hasFilters,
  componentType,
  isRulesConfigList = false,
  displaySearch = true,
  displayListViewType = true,
  hasRefresh,
  tableTitle,
  customRight,
  isServiceRequestListMobile,
  refreshData,
  isSearchFoundList,
  isConfiguration,
  isGateway = false,
  selectedItem,
  additionalRow,
  clearFilters,
}: ListActionsHeaderProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();
  const isMobile = useMediaQuery({ maxWidth: SIZES_NUMBER_TINY_SMALL });

  const [showFilter, setShowFilter] = useState(false);

  const serviceReqStyles = isServiceRequestListMobile ? { flexDirection: 'column', alignItems: 'flex-start' } : {};

  const rulesConfigStyles = isRulesConfigList && isMobile ? { flexDirection: 'column', alignItems: 'flex-start' } : {};

  const configurationStyle = isConfiguration && isMobile && { flexDirection: 'column', alignItems: 'flex-start' };

  const gatewayStyles = isGateway && isMobile ? { flexDirection: 'column' } : {};

  return (
    <>
      <FlexRowSpaceBetween
        sx={{
          padding: isSearchFoundList ? '0' : '24px',
          ...serviceReqStyles,
          ...configurationStyle,
          ...rulesConfigStyles,
          ...gatewayStyles,
        }}
      >
        {selectedItem?.id ? (
          <FlexColumnStart>
            <FlexRowStart sx={{ gap: '20px' }}>
              {tableTitle && (
                <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
                  {tableTitle}
                </Typography>
              )}
              {clearFilters && (
                <FlexRowCenter sx={{ gap: '8px', cursor: 'pointer' }} onClick={clearFilters}>
                  <SettingsBackupRestoreIcon sx={{ color: COLOR_PRIMARY, width: '16px', height: '16px' }} />
                  <Typography variant="subtitle2" sx={{ color: COLOR_PRIMARY }}>
                    {i18n.t('filters.clearFilters.action')}
                  </Typography>
                </FlexRowCenter>
              )}
            </FlexRowStart>

            <FlexRowSpaceBetween>
              {displaySearch && !isMobile && (
                <TextInput
                  id="search-input"
                  value={searchValue}
                  onChange={onInputChange}
                  placeholder={'Search'}
                  name={'searchFilter'}
                  variant={'outlined'}
                  isSearchField
                  sx={{ ...inputStyle, ...searchInputStyles }}
                />
              )}
              {customRight}
            </FlexRowSpaceBetween>
          </FlexColumnStart>
        ) : (
          <>
            <FlexRowStart sx={{ gap: '20px' }}>
              {tableTitle && (
                <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
                  {tableTitle}
                </Typography>
              )}
              {clearFilters && (
                <FlexRowCenter sx={{ gap: '8px', cursor: 'pointer' }} onClick={clearFilters}>
                  <SettingsBackupRestoreIcon sx={{ color: COLOR_PRIMARY, width: '16px', height: '16px' }} />
                  <Typography variant="subtitle2" sx={{ color: COLOR_PRIMARY }}>
                    {i18n.t('filters.clearFilters.action')}
                  </Typography>
                </FlexRowCenter>
              )}
            </FlexRowStart>

            <FlexRowEnd>
              {displaySearch && !isMobile && (
                <TextInput
                  id="search-input"
                  value={searchValue}
                  onChange={onInputChange}
                  placeholder={'Search'}
                  name={'searchFilter'}
                  variant={'outlined'}
                  isSearchField
                  sx={{ ...inputStyle, ...searchInputStyles }}
                />
              )}
              {customRight}
            </FlexRowEnd>
          </>
        )}
      </FlexRowSpaceBetween>
      {additionalRow}
    </>
  );
};

export default observer(ListActionsHeader);
