import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { ComplianceApi } from '../api/ComplianceApi';
import { ComplianceModel, INITIAL_COMPLIANCE, UpdateComplianceRuleDto } from '../models/ComplianceModel';

export class ComplianceStore {
  complianceApi: ComplianceApi;
  compliances: Array<ComplianceModel> = [INITIAL_COMPLIANCE];
  compliancesToSave: Array<ComplianceModel> = [INITIAL_COMPLIANCE];

  constructor(complianceApi: ComplianceApi) {
    this.complianceApi = complianceApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setCompliances(compliances: Array<ComplianceModel>) {
    runInAction(() => {
      this.compliances = compliances;
    });
  }

  async getCompliances() {
    const compliances = await this.complianceApi.getCompliances();
    this.setCompliancesToSave(compliances);
    runInAction(() => {
      this.compliances = compliances;
    });
    return compliances;
  }

  async updateCompliances(compliances: Array<UpdateComplianceRuleDto>) {
    await this.complianceApi.updateCompliances(compliances);

    return this.getCompliances();
  }

  setCompliancesToSave(compliancesToSave: Array<ComplianceModel>) {
    runInAction(() => {
      this.compliancesToSave = compliancesToSave;
    });
  }
}
