import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Avatar, Box, InputAdornment, MenuItem, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as moment from 'moment';
import 'moment-timezone';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { roleOptions } from '../../constants/roleOptions';
import { COLOR_TEAM_PROVECTUS_BACKGROUND, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { SIZES_SMALL } from '../../constants/sizes';
import { Roles } from '../../constants/userRoles';

import ActionModal from '../../components/adminPortal/users/ActionModal';
import ItemsList from '../../components/itemsList/ItemsList';
import { UserFromGetModel } from '../../models/UserModel';
import Button from '../../components/buttons/Button';
import InviteUserModal from '../../components/adminPortal/users/InviteUserModal';
import FlexRowCenter from '../../components/utils/flex/FlexRowCenter';
import FlexRowStart from '../../components/utils/flex/FlexRowStart';
import UserDetailPanel from '../../components/adminPortal/users/UserDetailPanel';
import { useToasts } from 'react-toast-notifications';

import { SortOptionsUsers } from '../../constants/sortOptions';

import { getTableHeader } from '../../components/adminPortal/users/tableHeaders';
import ActionButton from '../../components/adminPortal/users/ActionButton';
import RolesDropdown from '../../components/role/RolesDropdown';
import { Team } from '../../models/Team';
import { SortUserFormulas } from '../../utils/sortUsers';
import { CustomSearchBar } from '../../components/CustomSearchBar/CustomSearchBar';

const PageContainer = styled(Box)`
  align-items: center;
  height: calc(100vh - 176px);
  margin: 0;
  gap: 16px;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const UsersListContainer = styled(Stack)`
  border-radius: 8px;
  background-color: ${COLOR_WHITE};
  box-shadow:
    0 2px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.05);

  height: 100%;
  overflow: auto;
`;

const LastActiveContainer = styled.div<{ pending: boolean }>`
  padding: 8px 16px;
  background: ${props => (props.pending ? COLOR_TEAM_PROVECTUS_BACKGROUND : '#EFF1F7')};
  color: ${props => (props.pending ? '#1E429F' : GRAY_COLORS.GRAY_6)};
  border: 1px solid ${props => (props.pending ? '#C3DDFD' : GRAY_COLORS.GRAY_2)};
  width: fit-content;
  border-radius: 4px;
  text-align: center;
`;

const LastActive = ({ lastActive, isPending }: { lastActive?: string; isPending: boolean }) => (
  <>
    {lastActive || isPending ? (
      <LastActiveContainer pending={isPending}>{isPending ? 'Pending Invite' : lastActive}</LastActiveContainer>
    ) : null}
  </>
);

const UsersList = ({ adminActiveTab }: { adminActiveTab?: number }) => {
  const {
    localizationStore: { i18next: i18n },
    userStore: {
      users: activeUsers,
      inactiveUsers,
      getUsersByUserOrganization,
      filterUserByRoleAndStatus,
      deleteUser,
      enableUser,
      getInactiveUsersByUserOrganization,
      editUser,
      selectedUser,
      setSelectedUser,
      attachUsersSummaryForMostLeastActive,
      loadingUser,
    },
  } = useStore();
  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedDetailItem, setSelectedDetailItem] = useState<any>();
  const [activeTab, setActiveTab] = useState(0);
  const [userEditData, setUserEditData] = useState<any>();
  const [sortOrder, setSortOrder] = useState(SortOptionsUsers.fromAtoZ);
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(activeUsers);
  const [page, setPage] = useState(1); // default pagination page to 1

  useEffect(() => {
    (async () => {
      await onFilterChange(selectedFilter);
      setIsLoading(false);
    })();
  }, [activeTab]);

  useEffect(() => {
    (async () => {
      await onFilterChange(selectedFilter);
      setIsLoading(false);
    })();

    return () => {
      setSelectedDetailItem(null);
    };
  }, []);

  useEffect(() => {
    if ([SortOptionsUsers.mostToLeastActive, SortOptionsUsers.leastToMostActive].includes(sortOrder)) {
      attachUsersSummaryForMostLeastActive();
    }
  }, [sortOrder]);

  useEffect(() => {
    let users;
    if (activeTab === 0) {
      users = activeUsers || [];
    } else {
      users = inactiveUsers || [];
    }

    const searchValue = search.trim().toLowerCase();
    const sortFunction = SortUserFormulas[sortOrder];

    if (searchValue) {
      setFilteredUsers(
        sortFunction(
          users.filter(
            user =>
              user.fullName?.toLowerCase().includes(searchValue) || user.email?.toLowerCase().includes(searchValue)
          )
        )
      );
      return;
    }

    setFilteredUsers(sortFunction(users));
  }, [activeTab, sortOrder, search, activeUsers, inactiveUsers, selectedFilter]);

  const changeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
    setIsLoading(true);
    setSelectedItem(null);
    setSelectedDetailItem(null);
  };

  const onEnableDisableUser = async () => {
    try {
      if (showDeleteTeamModal) {
        await onEditUser(userEditData);
      } else if (showEditModal) {
        await onEditUser(userEditData);
      } else {
        if (selectedItem?.archived) {
          await enableUser(selectedItem.id);
          addToast(i18n.t('adminPortal.usersList.enableUser.successful'), { appearance: 'success' });
        } else {
          if (selectedItem?.isPendingInvite) {
            await deleteUser(selectedItem.id);
            addToast(i18n.t('adminPortal.usersList.cancelInvite.successful'), { appearance: 'success' });
          } else {
            await deleteUser(selectedItem.id);
            addToast(i18n.t('adminPortal.usersList.disableUser.successful'), { appearance: 'success' });
          }
        }
      }

      closeActionModal();
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  const onEditUser = async (data: any) => {
    const currentUser = selectedUser.id ? selectedUser : selectedItem;
    const user = await editUser(data, currentUser.id);
    addToast(i18n.t('adminPortal.usersList.userUpdated.successful'), { appearance: 'success' });
    setSelectedItem({ ...user, summaryDetails: currentUser?.summaryDetails });
    setSelectedUser({ ...user, summaryDetails: currentUser?.summaryDetails });
  };

  const onCloseModal = () => {
    setShowInviteUser(false);
  };

  const onOpenModal = () => {
    setShowInviteUser(true);
  };

  const tabs = [
    {
      title: i18n.t('statuses.active.message'),
    },
    {
      title: i18n.t('statuses.inactive.message'),
    },
  ];

  const openActionModal = (item: any) => {
    setSelectedItem(item);
    setShowActionModal(true);
  };
  const closeActionModal = () => {
    setSelectedItem(null);
    setUserEditData(null);
    setShowDeleteTeamModal(false);
    setShowActionModal(false);
    setShowEditModal(false);
  };

  const onChangeRole = (value: string, item: any) => {
    setShowActionModal(true);
    setSelectedItem(item);
    setShowEditModal(true);
    setUserEditData({ role: value });
  };

  const onDeleteTeam = (team: Team) => {
    setShowDeleteTeamModal(true);
    setShowActionModal(true);

    const newTeams = selectedUser?.teams
      ?.map((item: Team) => ({
        id: item.id,
      }))
      .filter((item: any) => item?.id !== team.id);

    setUserEditData({ teams: newTeams });
  };

  const headerCells = getTableHeader({ i18n });

  const tableData = filteredUsers?.map((item: UserFromGetModel, index: number) => {
    return {
      ...item,
      fullName: (
        <FlexRowStart onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          <Avatar
            src={item?.profilePicture}
            sx={{ height: '24px', width: '24px', marginRight: '12px' }}
            alt={`avatar_image_${item.id}`}
          />
          {item.fullName || item.email}
        </FlexRowStart>
      ),
      lastActive: (
        <LastActive
          isPending={item?.isPendingInvite || false}
          lastActive={item?.lastActive && moment.tz(item?.lastActive, 'EST').format('DD MMM YYYY')}
        />
      ),
      role: <RolesDropdown value={item.role} onChange={value => onChangeRole(value, item)} disabled={[Roles.admin]} />,
      status: item?.archived ? i18n.t('statuses.inactive.message') : i18n.t('statuses.active.message'),
      more: (
        <ActionButton
          isPending={item?.isPendingInvite || false}
          archived={item?.archived || false}
          onClick={() => openActionModal(item)}
          id={activeTab === 0 ? `disable-Btn-${index}` : `enable-Btn-${index}`}
        />
      ),
    };
  });

  const onFilterChange = async (value: string) => {
    if (value === 'all') {
      if (activeTab === 0) {
        await getUsersByUserOrganization();
      } else {
        await getInactiveUsersByUserOrganization();
      }
    } else {
      await filterUserByRoleAndStatus(value);
    }
    setSelectedFilter(value);
  };

  const onRowClick = (item: any) => {
    setSelectedDetailItem(item);
    setSelectedItem(item);
  };

  const getDataForActionModal = () => {
    let title;
    let description;
    if (showDeleteTeamModal) {
      title = i18n.t('menuPopUp.removeTeam.action');
      description = i18n.t('usersList.removeTeam.description.message');
    } else if (showEditModal) {
      title = i18n.t('menuPopUp.changeRole.action');
      description = i18n.t('usersList.changeRole.description.message');
    } else {
      title = selectedItem?.archived
        ? i18n.t('menuPopUp.enableUser.action')
        : selectedItem?.isPendingInvite
          ? i18n.t('menuPopUp.cancelInvite.action')
          : i18n.t('menuPopUp.disableUser.action');
      description = selectedItem?.archived
        ? i18n.t('usersList.enableItem.description.message')
        : selectedItem?.isPendingInvite
          ? i18n.t('usersList.cancelInvite.description.message')
          : i18n.t('usersList.deleteItem.description.message');
    }

    return { title, description };
  };

  return (
    <PageContainer sx={selectedDetailItem?.id && { display: 'flex' }}>
      <UsersListContainer sx={{ width: selectedDetailItem?.id ? '60%' : 'auto' }}>
        <ItemsList
          headerCells={headerCells}
          tableData={tableData}
          onRowClick={() => {}}
          pageTitle={i18n.t('adminPortal.tabs.users.action')}
          selectedItem={selectedDetailItem}
          displaySearch={false}
          loading={isLoading || loadingUser}
          widthPagination={true}
          page={page}
          setPage={page => setPage(page)}
          sxHeader={{ textTransform: 'uppercase', color: GRAY_COLORS.GRAY_500, fontWeight: '600 !important' }}
          additionalRow={
            <Tabs value={activeTab} onChange={changeTab} variant="scrollable">
              {tabs.map((tab: { title: string }, index: number) => (
                <Tab
                  key={`admin-portal-users-tab-${index}`}
                  label={tab.title}
                  id={`admin-portal-users-tab-${index}`}
                  aria-controls={`admin-portal-users-tab-panel-${index}`}
                />
              ))}
            </Tabs>
          }
          customRight={
            <FlexRowCenter sx={{ gap: '8px' }}>
              <CustomSearchBar
                onChange={setSearch}
                onCancelSearch={() => setSearch('')}
                sx={{ width: 'fit-content' }}
                placeholder={i18n.t('common.search.message')}
              />
              <TextField
                id="filter-by-role"
                value={selectedFilter}
                select
                onChange={(event: any) => onFilterChange(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ width: '100px', marginRight: '14px' }} position="start">
                      Filter by role:
                    </InputAdornment>
                  ),
                }}
              >
                {[
                  {
                    id: 'all',
                    name: 'All',
                  },
                  ...roleOptions,
                ]?.map((item, index) => (
                  <MenuItem value={item.id} key={`filter-by-role-${index}`}>
                    <Typography variant={'subtitle2'}>{i18n.t(item.name)}</Typography>
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="sort-users"
                value={sortOrder}
                select
                onChange={(event: any) => setSortOrder(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ width: '100px', marginRight: '14px' }} position="start">
                      Sort:
                    </InputAdornment>
                  ),
                }}
              >
                {[
                  SortOptionsUsers.fromAtoZ,
                  SortOptionsUsers.fromZtoA,
                  SortOptionsUsers.mostToLeastActive,
                  SortOptionsUsers.leastToMostActive,
                ]?.map((item, index) => (
                  <MenuItem value={item} key={`filter-by-role-${index}`}>
                    <Typography variant={'subtitle2'}>{i18n.t(`teamsList.sort.${item}`)}</Typography>
                  </MenuItem>
                ))}
              </TextField>
              <Button
                onClick={onOpenModal}
                sx={{
                  padding: '9px 16px !important',
                  height: '40px !important',
                  width: 'fit-content',
                  fontWeight: 400,
                }}
              >
                {i18n.t('usersList.button.invite')}
              </Button>
            </FlexRowCenter>
          }
        />
      </UsersListContainer>

      {selectedDetailItem?.id && (
        <UsersListContainer sx={{ width: '40%' }}>
          <UserDetailPanel
            onCloseDetailPanel={() => setSelectedDetailItem(null)}
            userId={selectedDetailItem.id}
            onChangeRole={onChangeRole}
            openActionModal={openActionModal}
            onDeleteTeam={onDeleteTeam}
          />
        </UsersListContainer>
      )}

      <ActionModal
        isOpen={showActionModal}
        onClick={onEnableDisableUser}
        onClose={closeActionModal}
        title={getDataForActionModal()?.title}
        description={getDataForActionModal()?.description}
      />
      <InviteUserModal isOpen={showInviteUser} onClose={onCloseModal} />
    </PageContainer>
  );
};

export default observer(UsersList);
