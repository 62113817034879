import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { COLOR_BACKGROUND, GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import Flex from '../../components/utils/flex/Flex';
import { Headline3 } from '../../components/typography/Headlines';
import Button from '../../components/buttons/Button';
import { EMPTY_INTEGRATION_MODEL, IntegrationModel } from '../../models/IntegrationModel';
import IntegrationCard from '../../components/adminPortal/integrations/IntegrationCard';
import ConfigureIntegrationPage from './ConfigureIntegrationPage';
import FlexColumnStart from '../../components/utils/flex/FlexColumnStart';
import FlexRowStart from '../../components/utils/flex/FlexRowStart';

const PageContainer = styled(Box)`
  padding: 24px;
  border-radius: 8px;
  background: ${COLOR_BACKGROUND};
  box-shadow:
    0 2px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.05);
  overflow: scroll;
`;

const CardsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 0 4px 0 4px;
`;

const IntegrationsPage = ({ activeTab }: { activeTab?: number }) => {
  const {
    integrationsStore: {
      selectedIntegration,
      setSelectedIntegration,
      getIntegrations,
      integrations: integrationsArray,
      upsertIntegrations,
    },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [integrations, setIntegrations] = useState<IntegrationModel[]>(integrationsArray);

  useEffect(() => {
    (async () => {
      await getIntegrations();
    })();

    return () => {
      setSaveButtonDisabled(true);
      setSelectedIntegration(EMPTY_INTEGRATION_MODEL);
    };
  }, []);

  useEffect(() => {
    setIntegrations(integrationsArray);
  }, [integrationsArray]);

  const handleSaveChanges = async () => {
    setSaveChangesLoading(true);

    const integrationsToSave = integrations?.map(item => {
      if (selectedIntegration.id && item.id === selectedIntegration.id) {
        return selectedIntegration;
      }
      return item;
    });

    await upsertIntegrations({ integrations: integrationsToSave });
    setSelectedIntegration(EMPTY_INTEGRATION_MODEL);
    setSaveChangesLoading(false);
    setSaveButtonDisabled(true);
  };

  const handleIsActiveChange = (id: string, isActive: boolean) => {
    const newIntegrations = integrations?.map(item => {
      if (item.id === id) {
        return { ...item, isActive };
      }
      return item;
    });
    setIntegrations(newIntegrations);
    saveButtonDisabled && setSaveButtonDisabled(false);
  };

  const handleUpdateConfig = (
    // accepting additional optional parameters for sql connection functionality
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number = 0, // to change data on that specific index
    isMultiple: boolean = false
  ) => {
    let name = null;
    let value = null;
    if (event.target) {
      name = event.target.name;
      value = event.target.value;
    }

    if (!value?.length && !isMultiple) {
      handleIsActiveChange(selectedIntegration.id, false);
    }
    // checking if name and value are provided if not then updation whole object otherwise only key with that name
    const editingSettings = [...selectedIntegration.settings];
    if (name && value) {
      editingSettings[index] = {
        configuration: { ...selectedIntegration?.settings?.[index].configuration, [name]: value },
      };
    } else {
      editingSettings[index] = {
        configuration: event as any,
      };
    }

    setSelectedIntegration({
      ...selectedIntegration,
      isActive: isMultiple ? true : !!value?.length,
      settings: editingSettings,
    });
    saveButtonDisabled && setSaveButtonDisabled(false);
  };

  if (selectedIntegration?.id) {
    return (
      <FlexColumnStart sx={{ gap: '16px', height: '100%' }}>
        <FlexRowStart
          sx={{ gap: '8px', cursor: 'pointer' }}
          onClick={() => setSelectedIntegration(EMPTY_INTEGRATION_MODEL)}
        >
          <ArrowBackIcon sx={{ color: GRAY_COLORS.GRAY_500, width: '16px', height: '16px' }} />
          <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_500 }}>
            {i18n.t('adminPortal.tabs.integrations.header')}
          </Typography>
        </FlexRowStart>
        <PageContainer height={'unset'}>
          <ConfigureIntegrationPage
            handleIsActiveChange={handleIsActiveChange}
            handleSaveChanges={handleSaveChanges}
            handleUpdateConfig={handleUpdateConfig}
          />
        </PageContainer>
      </FlexColumnStart>
    );
  }

  return (
    <PageContainer sx={{ height: '100%' }}>
      <Flex
        sx={
          saveChangesLoading
            ? { pointerEvents: 'none', opacity: '0.7', justifyContent: 'space-between' }
            : { justifyContent: 'space-between' }
        }
      >
        <Box>
          <Headline3>{i18n.t('adminPortal.tabs.integrations.header')}</Headline3>
          <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '24px', color: GRAY_COLORS.GRAY_7 }}>
            {i18n.t('adminPortal.tabs.integrations.subtitle')}
          </Typography>
        </Box>

        <Box>
          <Button disabled={saveButtonDisabled} onClick={handleSaveChanges}>
            {i18n.t('common.saveChanges.action')}
          </Button>
        </Box>
      </Flex>

      <CardsContainer>
        {integrations?.map(item => (
          <IntegrationCard
            key={`integration-card-${item.id}`}
            integration={item}
            handleIsActiveChange={handleIsActiveChange}
          />
        ))}
      </CardsContainer>
    </PageContainer>
  );
};

export default observer(IntegrationsPage);
