import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import { COLOR_BLACK, COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import Flex from '../../utils/flex/Flex';
import FlexRowStart from '../../utils/flex/FlexRowStart';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sx?: SxProps;
}

const WrapperModal = ({ isOpen, onClose, children, sx }: ModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack
        flexDirection={'row'}
        sx={{
          background: COLOR_WHITE,
          padding: '24px',
          position: 'absolute',
          flexDirection: 'column',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          borderRadius: '12px',
          maxWidth: '400px',
          ...sx,
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Box sx={{ position: 'absolute', top: '18px', right: '16px' }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </Modal>
  );
};

const ActionModal = ({
  isOpen,
  onClick,
  title,
  description,
  onClose,
  sx,
}: {
  onClick: () => void;
  onClose: () => void;
  isOpen: boolean;
  sx?: SxProps;
  title: string;
  description: string;
}) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <WrapperModal isOpen={isOpen} onClose={onClose} sx={sx}>
      <FlexRowStart>
        <NotificationImportantIcon sx={{ color: GRAY_COLORS.GRAY_5 }} />
        <Typography variant="subtitle1" sx={{ marginLeft: '8px', fontWeight: 500, color: COLOR_BLACK }}>
          {title}
        </Typography>
      </FlexRowStart>
      <Typography variant="subtitle2" sx={{ margin: '16px 0', color: '#3C3F48' }}>
        {description}
      </Typography>
      <Flex sx={{ justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button variant="outlined" onClick={onClose} sx={{ padding: '14px' }}>
          {i18n.t('archiveModal.cancel.action')}
        </Button>
        <Button variant="contained" onClick={onClick} sx={{ padding: '14px', marginLeft: '10px' }} id='Action-Btn'>
          {title}
        </Button>
      </Flex>
    </WrapperModal>
  );
};

export default observer(ActionModal);
