import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import UserAvatar from '../UserAvatar/UserAvatar';

interface UserAutocompleteProps {
  options: any[];
  onChange: (values: any) => void;
  value?: any[];
  renderTags?: boolean;
  fixedWidth?: string;
  inputRef?: any;
  error?: string;
  startAdornment?: React.ReactNode;
  placeholder?: string;
  isDisable?: boolean;
}

const UserAutocompleteRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const wrapperStyle = {
  minWidth: 500,
  width: '100%',
  '& > * + *': {
    marginTop: '24px',
  },
};

const UserAutocomplete = ({
  options,
  onChange,
  value,
  fixedWidth,
  inputRef,
  error,
  startAdornment,
  placeholder,
  isDisable,
}: UserAutocompleteProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const displayOption = (option: any) => {
    return option ? `${option.fullName || option.email}` : '';
  };

  return (
    <Box sx={[wrapperStyle, { width: fixedWidth ? fixedWidth : '100%' }]}>
      <Autocomplete
        disabled={isDisable}
        multiple
        limitTags={3}
        id="multiple-limit-tags"
        options={options}
        freeSolo
        disableClearable
        isOptionEqualToValue={(option: any, option2: any) => {
          return option.id === option2.id;
        }}
        renderTags={(value: any[], getTagProps) => null}
        onChange={(event, value) => {
          onChange(value);
        }}
        getOptionLabel={(option: any) => {
          return displayOption(option);
        }}
        value={value}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            height: '50px',
          },
        }}
        renderInput={params => {
          return (
            <TextField
              inputRef={inputRef}
              {...params}
              variant="outlined"
              placeholder={placeholder || i18n.t('users.placeholder.chooseUsers')}
              error={!!error}
              helperText={error}
              sx={{
                '& .MuiOutlinedInput-input': {
                  height: '0.4375em',
                },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment,
              }}
            />
          );
        }}
        renderOption={(props: any, option, index) => {
          return (
            <li {...props} id={`Select-User-${index.index}`}>
              <UserAutocompleteRow>
                <UserAvatar user={option} size={32} sx={{ marginRight: '10px' }} />
                <UserInfoContainer>
                  <Typography variant={'body2'} style={{ marginRight: 5 }}>{`${
                    option.fullName || option.email
                  }`}</Typography>
                  {(option.position || option.isPendingInvite) && (
                    <Typography
                      variant={'body2'}
                      sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_6, marginLeft: '4px' }}
                    >{`${option.position || (option.isPendingInvite && 'Pending invite')}`}</Typography>
                  )}
                </UserInfoContainer>
              </UserAutocompleteRow>
            </li>
          );
        }}
      />
    </Box>
  );
};

export default UserAutocomplete;
