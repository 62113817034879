import React from 'react';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { SxProps } from '@mui/material';

import { COLOR_BORDER_QUARTERNARY, COLOR_BUTTON_OUTLINED_HOVER, COLOR_TEXT_PRIMARY } from '../../constants/colors';

import { TableHeaderModel } from '../../models/TableModel';
import { ItemTableModel } from '../../models/ItemProps';
import DragAndDropWrapper from '../DragAndDropWrapper';

interface ItemsTableListRowProps {
  rowIndex: number;
  onClick: () => void;
  selectedRowIndex: number | null;
  hasMultipleSelection?: boolean;
  onRowCheckClick: (rowIndex: number) => void;
  isRowSelected: boolean;
  header: TableHeaderModel[];
  leftAlign?: boolean;
  item: ItemTableModel;
  allowDragAndDrop?: boolean;
  moveAttachment?: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  allowBackgroundColor?: boolean;
  sxCell?: SxProps;
}

const ItemsTableListRow = ({
  rowIndex,
  onClick,
  selectedRowIndex,
  hasMultipleSelection,
  onRowCheckClick,
  isRowSelected,
  header,
  leftAlign,
  item,
  allowDragAndDrop = false,
  allowBackgroundColor = false,
  moveAttachment,
  index,
  sxCell,
}: ItemsTableListRowProps) => {
  const backgroundColor = allowBackgroundColor ? (selectedRowIndex === rowIndex ? COLOR_BORDER_QUARTERNARY : '') : '';
  return (
    <DragAndDropWrapper
      isTableRow
      index={index}
      moveElement={moveAttachment}
      onClick={onClick}
      selectedRowIndex={selectedRowIndex}
      disabled={!allowDragAndDrop}
    >
      {hasMultipleSelection && (
        <TableCell
          padding="checkbox"
          onClick={e => {
            e.stopPropagation();
            onRowCheckClick(rowIndex);
          }}
        >
          <Checkbox color="primary" checked={false} />
        </TableCell>
      )}
      {header.map((headerItem, columnIndex) => (
        <TableCell
          key={`table-row-cell-${rowIndex}-${columnIndex}`}
          component={columnIndex === 0 ? 'th' : undefined}
          scope={columnIndex === 0 ? 'row' : undefined}
          align={columnIndex === header.length - 1 && !leftAlign ? 'right' : 'left'}
          sx={{
            color: COLOR_TEXT_PRIMARY,
            backgroundColor: isRowSelected ? COLOR_BUTTON_OUTLINED_HOVER : backgroundColor,
            ...sxCell,
          }}
        >
          {item[headerItem.property]}
        </TableCell>
      ))}
    </DragAndDropWrapper>
  );
};

export default ItemsTableListRow;
