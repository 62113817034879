import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { COLOR_BACKGROUND, GRAY_COLORS } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';

import ModelsTable from './components/ModelsTable';
import EditModel from './components/EditModel';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/utils/flex/Flex';
import AddDefaultModelModal from '../../../components/modal/AddDefaultModelModal';
import { useStore } from '../../../hooks/useStore';

const ModelsPage = () => {
  const [isEditScreenOpen, setIsEditScreenOpen] = useState(false);
  const [isDirtyState, setIsDirtyState] = useState(false);
  const [isAddDefaultModelModalOpen, setIsAddDefaultModelModalOpen] = useState(false);
  const [headerText, setHeaderText] = useState('');

  const {
    modelStore: { organizationModels },
  } = useStore();

  return (
    <>
      {isEditScreenOpen && (
        <Flex
          sx={{
            width: 'fit-content',
            marginBottom: '16px',
            padding: '4px',
            cursor: 'pointer',
            '&:hover': { backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '4px' },
          }}
          onClick={() => setIsEditScreenOpen(false)}
        >
          <ArrowBackIcon sx={{ height: '20px', width: '20px', color: GRAY_COLORS.GRAY_6, marginRight: '4px' }} />
          <Typography variant={'subtitle2'} sx={{ fontSize: '16px', color: GRAY_COLORS.GRAY_500 }}>
            Models
          </Typography>
        </Flex>
      )}
      <ModelsPageContainer>
        {isEditScreenOpen ? (
          <EditModel
            openAddDefaultModelModal={headerTextValue => {
              setHeaderText(headerTextValue);
              setIsAddDefaultModelModalOpen(true);
            }}
            onClose={() => setIsEditScreenOpen(false)}
          />
        ) : (
          <ModelsTable
            setIsDirtyState={setIsDirtyState}
            setHeaderText={setHeaderText}
            setIsAddDefaultModelModalOpen={setIsAddDefaultModelModalOpen}
            openEditScreen={() => {
              setIsEditScreenOpen(true);
            }}
          />
        )}
        {isAddDefaultModelModalOpen && (
          <AddDefaultModelModal
            headerText={headerText}
            isOpen={isAddDefaultModelModalOpen}
            onClose={() => setIsAddDefaultModelModalOpen(false)}
            options={organizationModels}
          />
        )}
      </ModelsPageContainer>
    </>
  );
};

const ModelsPageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  background: ${COLOR_BACKGROUND};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  height: 100%;
  margin: 0;
  overflow: auto;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

export default observer(ModelsPage);
