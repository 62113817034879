import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import Close from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import 'moment-timezone';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { useStore } from '../../../hooks/useStore';
import { AuditLog } from '../../../models/AuditLog';
import { UserModel } from '../../../models/UserModel';

import ConversationChat from '../../../pages/ConversationPage/components/ConversationChat/ConversationChat';
import OpenAIDetailsSection from '../../../pages/ConversationPage/components/OpenAIDetailsSection';
import Flex from '../../utils/flex/Flex';
import FlexRowStart from '../../utils/flex/FlexRowStart';
import IconByAction from './IconByAction';

interface ViewConversationModalProps {
  id: string | null;
  closeModal: () => void;
}

export const getInfoDescription = (currentAuditLog: AuditLog) => {
  if (currentAuditLog?.action === 'share' && currentAuditLog?.additionalInformation?.sharedTo) {
    const userNames = currentAuditLog?.additionalInformation?.sharedTo
      ?.map((item: UserModel) => item.fullName || item.email)
      .join(', ');
    return `This chat was ${currentAuditLog?.action}d by ${currentAuditLog.userName} to ${userNames} on ${moment
      .tz(currentAuditLog?.createdAt, 'EST')
      .format('MMM DD, YYYY')}`;
  }
  return `This chat was ${currentAuditLog?.action}d by ${currentAuditLog.userName} on ${moment
    .tz(currentAuditLog?.createdAt, 'EST')
    .format('MMM DD, YYYY')}`;
};

const getInfoDescriptionHackathonLog = (currentAuditLog: AuditLog) => {
  return `This chat was ${currentAuditLog?.action}d by ${currentAuditLog?.additionalInformation?.team} on ${moment
    .tz(currentAuditLog?.createdAt, 'EST')
    .format('MMM DD, YYYY')}`;
};

const ConversationDetailPage = ({ id, closeModal }: ViewConversationModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      getConversationById,
      setEmptyConversation,
      conversationSettingsState,
      currentConversation,
      secondaryConversationSettingsState,
      isSecondaryModelSelected,
    },
    auditLogsStore: { currentAuditLog },
  } = useStore();
  const { model, accuracy, length } = conversationSettingsState;
  const { chats, integration } = currentConversation;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      setLoading(true);
      await getConversationById(id);
      setLoading(false);
    })();

    return () => {
      setEmptyConversation();
    };
  }, [id]);

  useEffect(() => {
    return () => {
      setEmptyConversation();
    };
  }, []);

  return (
    <Stack sx={{ padding: '24px', display: 'flex', height: '100%' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          backgroundColor: COLOR_WHITE,
          height: '100%',
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {i18n.t('adminPortal.auditLog.panels.chat.title') as string}
          </Typography>
          <IconButton size={'small'} onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
        {currentAuditLog?.action && (
          <FlexRowStart sx={{ gap: '12px' }}>
            <IconByAction action={currentAuditLog?.action} />
            <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
              {!currentAuditLog?.additionalInformation?.isHackathonLog
                ? getInfoDescription(currentAuditLog)
                : getInfoDescriptionHackathonLog(currentAuditLog)}
            </Typography>
          </FlexRowStart>
        )}

        {!currentAuditLog?.additionalInformation?.isHackathonLog && (
          <>
            <Box sx={{ maxWidth: id ? '80%' : '100%', display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <OpenAIDetailsSection
                model={model}
                accuracy={accuracy}
                length={length}
                lightBackground
                renderButton={!id && !chats?.length && !isSecondaryModelSelected}
                integration={integration?.configName} // passing integration name to show with model
              />

              {isSecondaryModelSelected && (
                <>
                  <Typography variant={'subtitle2'} sx={{ margin: '0 8px' }}>
                    or
                  </Typography>
                  <OpenAIDetailsSection
                    model={secondaryConversationSettingsState.model}
                    accuracy={secondaryConversationSettingsState.accuracy}
                    length={secondaryConversationSettingsState.length}
                    lightBackground
                    renderButton={true}
                  />
                </>
              )}
            </Box>
            <ConversationChat loading={loading} />
          </>
        )}

        {currentAuditLog?.additionalInformation?.isHackathonLog && (
          <Box sx={{ marginTop: '24px' }}>
            <SyntaxHighlighter language={'json'} wrapLines showLineNumbers>
              {JSON.stringify(currentAuditLog?.additionalInformation || '{}', null, 2)}
            </SyntaxHighlighter>
          </Box>
        )}
      </Flex>
    </Stack>
  );
};

export default observer(ConversationDetailPage);
