import { PiiEntity } from '@aws-sdk/client-comprehend';
import { isString } from 'lodash';
import InvalidTextIcon from '../assets/icons/chat/blocked-text.svg';
import WarnedTextIcon from '../assets/icons/chat/warned-text.svg';

import { COLOR_ERROR_LIGHT, COLOR_TEXT_PRIMARY, COLOR_WARNING_LIGHT } from '../constants/colors';
import { RestrictionsEnum } from '../constants/restrictionsEnum';
import { RedactionMapping } from '../models/File';

export const redactText = (
  text: string,
  response: PiiEntity[],
  restrictionType: RestrictionsEnum,
  overrideBackgroundColor?: boolean
) => {
  let redactedText = text;

  if (!response.length) {
    return text;
  }

  for (const entity of response) {
    const { BeginOffset: startOffset, EndOffset: endOffset } = entity;

    if (!endOffset || !startOffset) {
      continue;
    }

    const redactedValue = text.substring(startOffset, endOffset);

    if (restrictionType === RestrictionsEnum.BLOCKED) {
      redactedText = redactedText.replace(
        redactedValue,
        `<span style='display: inline-flex; align-items:center; width: fit-content; padding: 2px 10px; background-color:${
          overrideBackgroundColor ? COLOR_ERROR_LIGHT : 'white'
        }; color: ${COLOR_TEXT_PRIMARY}; font-weight: 700; border-radius: 50px; margin-top: 4px'>
      <img src='${InvalidTextIcon}' alt='invalid-text-icon'/>
      <span style='margin-left: 6px'>${redactedValue}</span></span>`
      );
    } else {
      redactedText = redactedText.replace(
        redactedValue,
        `<span style='display: inline-flex; align-items:center; width: fit-content; padding: 2px 10px; background-color:${
          overrideBackgroundColor ? COLOR_WARNING_LIGHT : 'white'
        }; color: ${COLOR_TEXT_PRIMARY}; font-weight: 700; border-radius: 50px; margin-top: 4px'>
      <img src='${WarnedTextIcon}' alt='invalid-text-icon'/>
      <span style='margin-left: 6px'>${redactedValue}</span></span>`
      );
    }
  }

  return redactedText;
};

export const parseRedactionMapping = (redactionMapping: RedactionMapping[] | string | undefined) => {
  if (!redactionMapping) {
    return [];
  }

  if (!isString(redactionMapping)) {
    return redactionMapping;
  }

  try {
    return JSON.parse(redactionMapping);
  } catch (error) {
    console.log(error);
  }
};

export const redactFileContent = (content: string, redactionMapping: RedactionMapping[]) => {
  if (!Array.isArray(redactionMapping) || redactionMapping.length === 0) {
    return content;
  }

  const sortedOffsets = redactionMapping
    .map(redactionMappingItem => redactionMappingItem.offsets)
    .flat(1)
    .slice()
    .sort((offset1, offset2) => offset1.beginOffset - offset2.beginOffset);

  let redactedContent = content;

  let totalIndexDiff = 0;
  for (const offset of sortedOffsets) {
    const beginOffset = offset.beginOffset - totalIndexDiff;
    const endOffset = offset.endOffset - totalIndexDiff;

    const redactedValue = redactedContent.substring(beginOffset, endOffset);
    const replaceText = `<span style='display: inline-flex; align-items:center; width: fit-content; padding: 2px 10px; background-color: #FEE3DD; color: ${COLOR_TEXT_PRIMARY}; font-weight: 700; border-radius: 50px; margin-top: 4px'><img src='${WarnedTextIcon}' alt='invalid-text-icon' style='margin-right: 6px'/>${redactedValue}</span>`;

    redactedContent = redactedContent.substring(0, beginOffset) + replaceText + redactedContent.substring(endOffset);

    totalIndexDiff += endOffset - beginOffset - replaceText.length;
  }

  return redactedContent;
};
