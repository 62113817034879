import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import Joi from 'joi';
import { COLOR_TEXT_QUATERNARY } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import { Card, CardActionsContainer } from './LoginComponents';
import Button from '../../components/buttons/Button';
import TextInput from '../inputs/TextInput';
import { Paths } from '../../constants/routes';
import { useNavigate } from 'react-router';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { Box } from '@mui/material';
import Flex from '../utils/flex/Flex';
import Logo from '../logo/Logo';
import UploadProfilePictureComponent from '../uploadProfilePicture/UploadProfilePictureComponent';

const getSchema = (i18n: any) => {
  return Joi.object().keys({
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: false })
      .messages({
        'string.email': 'errors.invalidEmailFormat.message',
        'string.empty': 'errors.emailRequired.message',
      })
      .label(i18n.t('labels.email')),
    fullName: Joi.string().required().label(i18n.t('labels.fullName')),
    position: Joi.string().label(i18n.t('labels.position')),
  });
};

interface FormProps {
  fullName: string;
  position?: string;
}

const SignUpCard = () => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { userData, getUserInformation, updateUserInformation },
  } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    position: '',
    profilePicture: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
    fullName: '',
    position: '',
  });

  useEffect(() => {
    (async () => {
      await getUserInformation();
    })();
  }, []);

  useEffect(() => {
    setFormData({
      email: userData.email,
      fullName: userData.fullName,
      position: userData.position,
      profilePicture: userData.profilePicture,
    });
  }, [userData]);

  const generalValidation = (input: FormProps, name?: string): string | undefined | null | { [key: string]: any } => {
    const results = getSchema(i18n).validate(input, { abortEarly: false });

    if (results.error && name) {
      const error = results.error.details.find(obj => obj.path[0] === name);
      return error?.message;
    }

    if (results.error && !name) {
      let errorsObj: { [key: string]: any } | null = null;
      results.error.details.forEach(detail => {
        errorsObj = {
          ...(errorsObj || {}),
          [detail.path[0]]: detail.message,
        };
      });

      return errorsObj;
    }

    return null;
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log('name, value', name, value);
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    const error = generalValidation(updatedFormData, name);

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
  };

  const onSave = async () => {
    const updateData = { fullName: formData.fullName, position: formData.position };
    const errors = generalValidation(updateData);
    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }

    await updateUserInformation(updateData);
    navigate(Paths.ADMIN_PORTAL);
  };

  return (
    <Card height={'auto'}>
      <Logo size={60} />

      {loading && (
        <Box>
          <LoadingSpinner />
        </Box>
      )}

      {!loading && (
        <CardActionsContainer width={'350px'} style={{ marginTop: 40 }}>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: '6px' }}>
              {i18n.t('authentication.signUp.message')}
            </Typography>
            <Typography variant="body2" sx={{ width: '265px', color: COLOR_TEXT_QUATERNARY }}>
              {i18n.t('authentication.signUp.description')}
            </Typography>
          </Flex>

          <Flex sx={{ marginBottom: '16px', flexDirection: 'column', alignItems: 'flex-start', marginTop: '24px' }}>
            <Typography variant={'subtitle2'}>{i18n.t('labels.email')}</Typography>
            <TextInput
              disabled={true}
              id="email"
              name={'email'}
              value={formData.email}
              variant={'outlined'}
              onChange={() => {}}
              type={'email'}
              placeholder={'Enter your email'}
              testId={'sing-up-email'}
            />
          </Flex>
          <Flex sx={{ marginBottom: '16px', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant={'subtitle2'}>{i18n.t('labels.fullName')}</Typography>
            <TextInput
              id="fullName"
              name={'fullName'}
              value={formData.fullName}
              variant={'outlined'}
              onChange={onChange}
              placeholder={'Enter your full name'}
              testId={'sing-up-full-name'}
              error={formErrors.fullName}
            />
          </Flex>

          <Flex sx={{ marginBottom: '16px', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant={'subtitle2'}>{i18n.t('labels.position')}</Typography>
            <TextInput
              id="position"
              name={'position'}
              value={formData.position}
              variant={'outlined'}
              onChange={onChange}
              placeholder={'Enter your position'}
              testId={'sing-up-position'}
              error={formErrors.position}
            />
          </Flex>

          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '40px',
              height: '72px',
            }}
          >
            <Typography variant={'subtitle2'}>{i18n.t('labels.profilePhoto')}</Typography>
            <UploadProfilePictureComponent size={72} user={userData} />
          </Flex>
          <Button onClick={onSave} sx={{ fontWeight: 600 }}>
            {i18n.t('authentication.finish.action')}
          </Button>
        </CardActionsContainer>
      )}
    </Card>
  );
};

export default observer(SignUpCard);
