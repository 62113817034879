import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Paths } from '../../constants/routes';
import { COLOR_WHITE } from '../../constants/colors';

import UnsaveChangesModal from '../../components/adminPortal/compliance/UnsavedChangesModal';
import Button from '../../components/buttons/Button';
import GenericCard from '../../components/cards/GenericCard';
import FlexColumnStart from '../../components/utils/flex/FlexColumnStart';
import { useStore } from '../../hooks/useStore';
import { ComplianceModel, UpdateComplianceRuleDto } from '../../models/ComplianceModel';
import CompliancePageCard from './CompliancePageCard';
import TabPanel from '../../components/tabs/TabPanel';
import FlexRowEnd from '../../components/utils/flex/FlexRowEnd';

const CompliancePage = ({ activeTab, setActiveTab }: { setActiveTab?: (arg: number) => void; activeTab?: number }) => {
  const {
    localizationStore: { i18next: i18n },
    complianceStore: { getCompliances, compliances, updateCompliances, setCompliances },
  } = useStore();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [initialCompliances, setInitialCompliances] = useState<ComplianceModel[] | []>([]);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [complianceActiveTab, setComplianceActiveTab] = useState(0);

  useEffect(() => {
    (async () => {
      const initial = await getCompliances();
      setInitialCompliances(initial);
    })();

    return () => {
      handleDisplayModal();
    };
  }, []);

  useEffect(() => {
    if (activeTab !== 5) {
      handleDisplayModal();
    }
    setSaveButtonDisabled(true);
  }, [activeTab]);

  const handleDisplayModal = () => {
    compliances?.forEach((compliance, index) => {
      const rules = getRulesObject(compliance);
      const newRules: UpdateComplianceRuleDto[] = getRulesObject(initialCompliances[index]);
      const diff = _.differenceWith(rules, newRules, _.isEqual);

      if (rules?.length === newRules?.length && diff?.length) {
        setShowUnsavedModal(true);
        return;
      }
    });
  };

  const getRulesObject = (entity: ComplianceModel) => {
    const newRules: UpdateComplianceRuleDto[] = entity?.rules?.map(item => ({
      id: item.id,
      isActive: item.isActive,
      restrictionLevel: item.restrictionLevel || (item.isActive ? 'blocked' : undefined),
      anonymizeText: item.anonymizeText || '',
    }));
    return newRules;
  };

  const onSave = async () => {
    const newRules: UpdateComplianceRuleDto[] = [];
    compliances?.forEach(compliance => {
      const rules = getRulesObject(compliance);
      newRules.push(...rules);
    });

    try {
      const compliances = await updateCompliances(newRules);
      setInitialCompliances(compliances);

      setShowUnsavedModal(false);
      addToast(i18n.t('adminPortal.compliance.save.successful'), { appearance: 'success' });

      setSaveButtonDisabled(true);
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  const onClose = () => {
    setShowUnsavedModal(false);
    navigate(Paths.ADMIN_PORTAL);
    // setActiveTab(5);
  };

  const onDismiss = () => {
    setShowUnsavedModal(false);
    setSaveButtonDisabled(false);
    setCompliances(initialCompliances);
  };

  const onChangeComplianceTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setComplianceActiveTab(newValue);
  };

  const tabs = [
    {
      title: i18n.t('CHAT'),
      component:
        <CompliancePageCard
          saveButtonDisabled={saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
          onSave={onSave}
          type={'chat'}
        />,
      index: 0,
    },
    {
      title: i18n.t('FILE'),
      component:
        <CompliancePageCard
          saveButtonDisabled={saveButtonDisabled}
          setSaveButtonDisabled={setSaveButtonDisabled}
          onSave={onSave}
          type={'file'}
        />,
      index: 1,
    },
  ];

  return (
    <FlexColumnStart>
      <GenericCard
        disableHover
        height={'fit-content'}
      >
        <FlexRowEnd>
          <Button
            disabled={saveButtonDisabled}
            onClick={onSave}
            sx={{
              marginTop: '12px',
              padding: '9px 16px',
              height: '40px !important',
              width: 'fit-content',
              fontWeight: 400,
              marginLeft: '12px',
            }}
          >
            {i18n.t('common.saveChanges.action')}
          </Button>
        </FlexRowEnd>

        <Tabs
          value={activeTab}
          onChange={onChangeComplianceTab}
          variant="scrollable"
          sx={{
            padding: '0 40px',
            background: COLOR_WHITE,
            width: '100%',
          }}
        >
          {tabs.map((tab, index: number) => (
            <Tab
              key={`admin-portal-tab-${index}`}
              label={tab.title}
              id={`admin-portal-tab-${index}`}
              aria-controls={`admin-portal-tab-panel-${index}`}
            />
          ))}
        </Tabs>

        {tabs.map((tab, index: number) => (
          <TabPanel key={`tab_${index}`} value={complianceActiveTab} index={index} sx={{ marginTop: '32px' }} >
            {tab.component}
          </TabPanel>
        ))}
      </GenericCard>

      <UnsaveChangesModal
        isOpen={showUnsavedModal}
        onSave={onSave}
        onClose={onClose}
        onDismiss={onDismiss}
        sx={{ width: '450px' }}
      />
    </FlexColumnStart>
  );
};

export default observer(CompliancePage);
