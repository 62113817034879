import { ApiConfig } from './ApiConfig';

export class S3DocumentsApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: any) {
    this.apiConfig = apiConfig;
  }

  generateAssetsWriteUrl(fileKey: string) {
    return this.apiConfig.sendRequest('POST', '/upload/assets/signed-url', { fileKey });
  }

  generateAssetsReadUrl(fileKey: string): Promise<{ signedUrl: string; expirationDate: Date }> {
    return this.apiConfig.sendRequest('GET', `/upload/assets/signed-url?fileKey=${fileKey}`);
  }

  generateDocumentsWriteUrl(fileKey: string, fileType: string) {
    return this.apiConfig.sendRequest('POST', '/upload/documents/signed-url', { fileKey, fileType });
  }

  generateDocumentsReadUrl(fileKey: string): Promise<{ signedUrl: string; expirationDate: Date }> {
    return this.apiConfig.sendRequest('GET', `/upload/documents/signed-url?fileKey=${fileKey}`);
  }

  uploadDocuments(file: any, fileId: string, options?: { setProgress?: any; setCancelToken?: any }) {
    return this.apiConfig.sendFile(`/upload/documents`, file, fileId, options);
  }
}
