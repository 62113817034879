import { Roles } from './userRoles';

export const roleOptions = [
  {
    id: Roles.admin,
    name: 'roles.admin.message',
  },
  {
    id: Roles.organizationAdmin,
    name: 'roles.organizationAdmin.message',
  },
  {
    id: Roles.member,
    name: 'roles.member.message',
  },
];
