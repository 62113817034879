import { ApiConfig } from './ApiConfig';
import { Conversation, CreateConversationDto, ShareConversationModel } from '../models/Conversation';
import { AddFileToConversationDto, CreateChatDto } from '../models/Chat';
import { CreateCompletionRequestDto } from '../models/CreateCompletionDto';

export class ConversationApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }
  create(createConversationDto: CreateConversationDto): Promise<Conversation> {
    return this.apiConfig.sendRequest('POST', '/conversations', createConversationDto);
  }

  attachFile(conversationFileDto: AddFileToConversationDto) {
    return this.apiConfig.sendRequest('POST', '/conversations/attach-file', conversationFileDto);
  }

  createWithoutGettingChatResponse(createConversationDto: CreateConversationDto): Promise<Conversation> {
    return this.apiConfig.sendRequest('POST', '/conversations/createWithoutGettingChatResponse', createConversationDto);
  }

  createCompletion(createCompletionRequestDto: CreateCompletionRequestDto) {
    return this.apiConfig.sendRequest('POST', '/conversations/createCompletion', createCompletionRequestDto);
  }

  redactPiiData(text: string) {
    return this.apiConfig.sendRequest('POST', '/conversations/redactPiiData', { text });
  }

  getById(id: string): Promise<Conversation> {
    return this.apiConfig.sendRequest('GET', `/conversations/${id}`);
  }

  getHistoryLogs(): Promise<Array<Conversation>> {
    return this.apiConfig.sendRequest('GET', `/conversations/audit`);
  }

  // TODO: change when we implement server pagination
  getAllUsersConversations(): Promise<Conversation[]> {
    return this.apiConfig.sendRequest('GET', '/conversations');
  }

  // TODO: change when endpoint is created or when we implement server pagination
  getUsersRecentConversations(): Promise<Conversation[]> {
    return this.apiConfig.sendRequest('GET', `/conversations`);
  }

  addChat(createChatDto: CreateChatDto): Promise<Conversation> {
    return this.apiConfig.sendRequest('POST', '/conversations/chat', createChatDto);
  }

  updateConversation(id: string, conversationDto: Partial<Conversation>) {
    return this.apiConfig.sendRequest('PUT', `/conversations/${id}`, conversationDto);
  }

  remove(id: string) {
    return this.apiConfig.sendRequest('DELETE', `/conversations/${id}`);
  }

  shareConversation(shareObj: ShareConversationModel) {
    return this.apiConfig.sendRequest('POST', '/conversations/share', shareObj);
  }

  getSharedWithMe() {
    return this.apiConfig.sendRequest('GET', '/conversations/shared-with-me');
  }

  getNewConversations() {
    return this.apiConfig.sendRequest('GET', '/conversations/new-conversations');
  }

  getConversationsByFileId(fileId: string) {
    return this.apiConfig.sendRequest('GET', `/conversations/file/${fileId}`);
  }

  addDataAnalysisBotChat(conversationId: string, dataAnalysisMessage: string): Promise<Conversation> {
    return this.apiConfig.sendRequest('POST', '/conversations/dataAnalysisBotMessage', {
      conversationId,
      dataAnalysisMessage,
    });
  }

  regenerateResponse(conversationId: string) {
    return this.apiConfig.sendRequest('POST', '/conversations/regenerate', { conversationId });
  }
}
