import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemButton, ListItemText, PopoverProps, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../constants/colors';
import { Roles } from '../../constants/userRoles';
import { Paths } from '../../constants/routes';

import { useStore } from '../../hooks/useStore';
import Logo from '../logo/Logo';
import UserAvatar from '../UserAvatar/UserAvatar';
import UserMenu from '../userMenu/UserMenu';
import Flex from '../utils/flex/Flex';
import AnnounceKitComponent from '../AnnouncementKit';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import DrawerComponent from '../Drawer/Drawer';
import doubleBackArrow from '../../config/addvaluemachine/doubleBackArrow.svg';
import NotificationIconLogo from '../../config/addvaluemachine/NotificationIconLogo.svg';
import notificationsBellLogo from '../../config/addvaluemachine/notificationsBellLogo.svg';

const drawerWidth = 250;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    min-height: 54px;
    padding: 0 32px;
  }
`;

const TopMenuItemButton = styled(ListItemButton)`
  padding: 0 16px;
  border-radius: 6px;

  & .Mui-selected {
    background-color: ${GRAY_COLORS.GRAY_100};
  }
`;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
  color: 'black',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface NavigationContainerProps {
  children: React.ReactNode;
}

const NavigationContainer = ({ children }: NavigationContainerProps) => {
  const {
    uiStore: { toggleIsNavigationDrawerExpanded, isNavigationDrawerExpanded, dimensions },
    userStore: { userData },
    localizationStore: { i18next: i18n },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [anchorMenuElement, setAnchorMenuElement] = useState<PopoverProps['anchorEl']>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [isNotification, setIsNotification] = useState<boolean>(false);

  const isSmallScreen = dimensions.small || dimensions.tinySmall || dimensions.tiny;

  useEffect(() => {
    if (isSmallScreen) {
      toggleIsNavigationDrawerExpanded(false);
    }
  }, [isSmallScreen]);

  const handleExpand = (event: { currentTarget: Element }) => {
    setIsMenuExpanded(prevState => !prevState);
    if (!isMenuExpanded) {
      setAnchorMenuElement(event.currentTarget);
    } else {
      setAnchorMenuElement(null);
    }
  };

  const handleCloseMenu = () => {
    setIsMenuExpanded(false);
    setAnchorMenuElement(null);
  };

  const handleDrawerToggle = () => {
    const newDrawerOpenState = !isDrawerOpen;
    setIsDrawerOpen(newDrawerOpenState);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <AppBar position="fixed" open={isDrawerOpen}>
        <StyledToolbar>
          <Flex sx={{ gap: '8px', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              {isDrawerOpen ? <img src={doubleBackArrow} alt="doubleBackArrow" /> : <MenuIcon />}
            </IconButton>
            {userData?.role !== Roles.member && (
              <TopMenuItemButton
                onClick={() => navigate(Paths.ADMIN_PORTAL)}
                selected={pathname.startsWith(Paths.ADMIN_PORTAL)}
              >
                <ListItemText>{i18n.t('sidebarMenu.adminPortal.action')}</ListItemText>
              </TopMenuItemButton>
            )}
          </Flex>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* {!isNotification ? (
              <img src={NotificationIconLogo} alt="NotificationIconLogo" style={{ marginRight: '10px' }} />
            ) : (
              <img src={notificationsBellLogo} alt="notificationsBellLogo" style={{ marginRight: '10px' }} />
            )} */}
            <IconButton size={'small'} onClick={handleExpand} disableRipple id="user-Profile">
              <UserAvatar user={userData} sx={{ marginRight: '8px' }} />
              {isMenuExpanded ? (
                <ExpandLessIcon fontSize="small" sx={{ fill: GRAY_COLORS.GRAY_500 }} />
              ) : (
                <ExpandMoreIcon fontSize="small" sx={{ fill: GRAY_COLORS.GRAY_500 }} />
              )}
            </IconButton>
          </Box>
          <UserMenu anchorElement={anchorMenuElement as any} handleCloseMenu={handleCloseMenu} />
        </StyledToolbar>
      </AppBar>
      <DrawerComponent isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        sx={{
          flex: 1,
          transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          marginTop: '100px',
          minHeight: 'calc(100vh - 176px)',
          marginLeft: isDrawerOpen ? '300px' : '100px',
          marginRight: '20px',
          marginBottom: '20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default observer(NavigationContainer);
