export const filterValues = [
  {
    value: 'chat',
    name: 'Chat',
  },
  {
    value: 'response',
    name: 'Response',
  },
  {
    value: 'user',
    name: 'User',
  },
  {
    value: 'team',
    name: 'Team',
  },
  {
    value: 'prompt',
    name: 'Prompt',
  },
];
