import React from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { FormHelperText } from '@mui/material';

import { COLOR_BUTTON_CONTAINED_ERROR, COLOR_PRIMARY, COLOR_UTILS_TERTIARY } from '../../../constants/colors';
import { Roles } from '../../../constants/userRoles';
import RolesDropdown from '../../role/RolesDropdown';

import FlexRowCenter from '../../utils/flex/FlexRowCenter';
import { useStore } from '../../../hooks/useStore';
import { Team } from '../../../models/Team';
import SelectWithSearch from '../../select/SelectWithSearch';

interface Props {
  onChangeRole: (arg: string) => void;
  selectedTeams: string[] | Team[];
  setSelectedTeams: (event: any) => void;
  teamFilters: Team[];
  selectedRole: string;
  backgroundOnActive?: boolean;
  error?: string;
}

const SelectRoleAndTeams = ({
  onChangeRole,
  selectedTeams,
  setSelectedTeams,
  teamFilters,
  selectedRole,
  backgroundOnActive = false,
  error,
}: Props) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <FlexRowCenter sx={{ gap: '8px', position: 'relative' }}>
      <RolesDropdown
        value={selectedRole}
        onChange={onChangeRole}
        disabled={[Roles.admin]}
        color={COLOR_PRIMARY}
        backgroundColor={COLOR_UTILS_TERTIARY}
        ellipsis={true}
        sx={{
          height: '32px',
          borderRadius: '6px',
        }}
        backgroundOnActive={backgroundOnActive}
      />
      <SelectWithSearch
        value={selectedTeams}
        placeholder={i18n.t('prompts.select.teams.placeHolder')}
        multiple={true}
        onChange={(event: any) => setSelectedTeams(event.target.value)}
        options={teamFilters.map(team => {
          return { name: team.name, value: team.name };
        })}
        icon={KeyboardArrowDown}
        menuSX={{ height: '32px' }}
        backgroundOnActive={backgroundOnActive}
        onClearFilters={() => setSelectedTeams([])}
      />
      {error && (
        <FormHelperText sx={{ marginLeft: 0, color: COLOR_BUTTON_CONTAINED_ERROR, position: 'absolute', top: '35px' }}>
          {error}
        </FormHelperText>
      )}
    </FlexRowCenter>
  );
};

export default observer(SelectRoleAndTeams);
