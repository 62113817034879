import React from 'react';
import { Tooltip } from '@mui/material';

const tooltipStyle = {
  tooltip: {
    sx: {
      bgcolor: 'common.black',
      '& .MuiTooltip-arrow': {
        color: '#1B1B1B',
        fontSize: '18px',
      },

      maxWidth: '300px',
      boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },
  },
};

const InfoTooltip = ({ children, title, open }: { children: any; title: string; open: boolean }) => (
  <Tooltip title={title} arrow TransitionProps={{ timeout: 800 }} open={open} componentsProps={tooltipStyle}>
    {children}
  </Tooltip>
);

export default InfoTooltip;
