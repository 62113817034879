import React from 'react';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import { i18n } from 'i18next';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReportIcon from '@mui/icons-material/Report';
import styled from 'styled-components';

import { Statuses } from '../../constants/Statuses';

import { useStore } from '../../hooks/useStore';
import FlexRowCenter from '../utils/flex/FlexRowCenter';
import Change from '../../assets/icons/Change.svg';

interface StatusProps {
  type: string | number;
  label?: string;
  displayOnlyIcon?: boolean;
  sx?: SxProps;
  onClick?: (arg1?: any) => void;
}

const Image = styled.img`
  width: 16px;
  height: 16px;
`;

export const getCardInfo = (type: string | number, i18n: i18n) => {
  switch (type) {
    case Statuses.allowed:
      return {
        text: i18n.t('statuses.allowed.message'),
        backgroundColor: 'rgba(5, 122, 85, 0.15)',
        textColor: '#057A55',
        icon: <CheckIcon sx={{ color: '#057A55', width: '16px', height: '16px' }} />,
      };
    case Statuses.warning:
      return {
        text: i18n.t('statuses.warning.message'),
        backgroundColor: 'rgba(227, 160, 8, 0.15)',
        textColor: '#E3A008',
        icon: <WarningAmberIcon sx={{ color: '#E3A008', width: '16px', height: '16px' }} />,
      };
    case Statuses.blocked:
      return {
        text: i18n.t('statuses.blocked.message'),
        backgroundColor: 'rgba(200, 30, 30, 0.15)',
        textColor: '#C81E1E',
        icon: <ReportIcon sx={{ color: '#C81E1E', width: '16px', height: '16px' }} />,
      };
    case Statuses.anonymize:
      return {
        text: i18n.t('statuses.anonymize.message'),
        backgroundColor: 'rgba(114, 59, 19, 0.15)',
        textColor: '#723B13',
        icon: <Image src={Change} alt={'Change'} />,
      };
    default:
      return {
        text: '',
        backgroundColor: '',
        textColor: '',
        Icon: null,
      };
  }
};

const Status = ({ type, label, sx, onClick, displayOnlyIcon = false }: StatusProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const { text, backgroundColor, icon, textColor } = getCardInfo(type, i18n);

  return (
    <FlexRowCenter
      onClick={onClick}
      sx={{
        gap: '8px',
        backgroundColor,
        width: 'fit-content',
        padding: '6px 8px',
        borderRadius: '4px',
        ...sx,
      }}
    >
      {icon}
      {!displayOnlyIcon && (
        <Typography variant={'subtitle2'} color={textColor}>
          {label || text}
        </Typography>
      )}
    </FlexRowCenter>
  );
};

export default Status;
