export interface AuditLog {
  id: string;
  entityId: string;
  entityType: string;
  organizationId: string;
  userId: string;
  userName: string;
  status: string;
  createdAt: Date;
  deleted: boolean;
  action?: string | null;
  additionalInformation?: AuditLogAdditionalInfo;
  source?: string;
}

export interface AuditLogAdditionalInfo {
  sharedTo?: any[];
  chat?: AuditLogChatInfo;
  // Fields added for hackathon logs
  isHackathonLog?: boolean;
  secureEndpoint?: boolean;
  team?: string;
  modelVersion?: string;
  response?: string;
  settings?: any;
  messageId?: string;
  responseMessageId?: string;
  tokenName?: string;
}

export interface AuditLogChatInfo {
  id: string;
  providerName: string;
  model: string;
  message: string;
  responseMessage?: string;
}

export const INITIAL_AUDIT_LOG: AuditLog = {
  id: 'id',
  entityId: '',
  entityType: '',
  organizationId: '',
  userId: '',
  userName: '',
  status: '',
  deleted: false,
  createdAt: new Date(),
  action: null,
};

export interface CreateAuditLogDto {
  entityType: 'login' | 'logout';
  userEmail?: string | null;
  status: 'allowed' | 'not-allowed';
}

export interface FullSearchModel {
  page?: number;
  userId?: string[];
  teamId?: string[];
  status?: string[];
  entityType?: string[];
  limit?: number;
  search?: string;
  startDate?: any;
  endDate?: any;
}

export interface MetaDataModel {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
  sortBy: any;
  filter: any;
}
