import { useToasts } from 'react-toast-notifications';
import { useStore } from '../../hooks/useStore';
import Flex from '../utils/flex/Flex';
import { SxProps, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { GRAY_COLORS } from '../../constants/colors';
import React from 'react';

type CopyTypographyProps = {
  label: string;
  value: string;
  sx?: SxProps;
};

const CopyTypography = ({ label, value, sx }: CopyTypographyProps) => {
  const { addToast } = useToasts();
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value);
      addToast(i18n.t('conversation.copyMessage.successful'), { appearance: 'success' });
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  return (
    <Flex sx={{ ...sx, alignItems: 'center' }}>
      <Typography variant={'subtitle2'}>
        <span style={{ fontWeight: '600', marginRight: '6px' }}>{label}:</span>
        {value}
      </Typography>

      <IconButton sx={{ marginLeft: '8px' }} onClick={handleCopyToClipboard}>
        <ContentCopyIcon
          key={`${label}-${value}`}
          sx={{ width: '16px', height: '16px', color: GRAY_COLORS.GRAY_5, cursor: 'pointer' }}
        />
      </IconButton>
    </Flex>
  );
};

export default CopyTypography;
