import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import GenericCard from '../../cards/GenericCard';
import { Headline3 } from '../../typography/Headlines';
import FilterComponent from './FilterComponent';
import TopPromptsTable from '../../table/AppTable';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import PromptRow from './TopPromptsTableRow';

const Top10PromptsCard = () => {
  const {
    localizationStore: { i18next: i18n },
    insightsStore: { top10Prompts, getTop10Prompts },
  } = useStore();

  const [selectedFilter, setSelectedFilter] = useState<string>('allTime');

  useEffect(() => {
    (async () => {
      await getTop10Prompts(selectedFilter);
    })();
  }, [selectedFilter]);

  const tableHeaderColumnNames = [
    {
      name: i18n.t('adminPortal.insights.top10.header.prompts'),
      width: 50,
    },
    {
      name: i18n.t('adminPortal.insights.top10.header.usageCount'),
      width: 10,
    },
  ];

  return (
    <GenericCard
      disableHover
      sx={{
        flex: 1,
      }}
    >
      <FlexRowSpaceBetween sx={{ marginBottom: '16px' }}>
        <Headline3>{i18n.t('adminPortal.insights.top10Prompts.subtitle')}</Headline3>
        <FilterComponent id={'top-prompts-filter'} setFilter={setSelectedFilter} value={selectedFilter} />
      </FlexRowSpaceBetween>

      <TopPromptsTable
        tableHeaderColumnNames={tableHeaderColumnNames}
        style={{ height: 'calc(100% - 56px)', overflow: 'scroll', minHeight: 0 }}
      >
        {Array.isArray(top10Prompts) &&
          top10Prompts.map((prompt, index) => {
            return <PromptRow key={`prompt-row-${index}`} prompt={prompt} index={index} />;
          })}
      </TopPromptsTable>
    </GenericCard>
  );
};

export default observer(Top10PromptsCard);
