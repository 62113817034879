import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RssFeedIcon from '@mui/icons-material/RssFeed';

import { CategoryContainer } from '../../components/adminPortal/integrations/IntegrationCard';
import { getIntegrationLogo } from '../../components/adminPortal/integrations/IntegrationLogo';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/inputs/TextInput';
import { PrimarySwitch } from '../../components/Switch/PrimarySwitch';
import InfoTooltip from '../../components/tooltip/InfoTooltip';
import { Headline3 } from '../../components/typography/Headlines';
import Flex from '../../components/utils/flex/Flex';
import FlexRowStart from '../../components/utils/flex/FlexRowStart';

import { COLOR_PRIMARY, COLOR_RED, GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import { SUPPORTED_INTEGRATIONS } from '../../models/IntegrationModel';

const Logo = styled.img`
  margin-right: 12px;
  max-height: 32px;
`;

interface ConfigProps {
  handleIsActiveChange: (id: string, isActive: boolean) => void;
  handleSaveChanges: () => void;
  handleUpdateConfig: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index?: number,
    isMultiple?: boolean
  ) => void;
}
const ConfigureIntegrationPage = ({ handleIsActiveChange, handleSaveChanges, handleUpdateConfig }: ConfigProps) => {
  const {
    integrationsStore: { selectedIntegration, setSelectedIntegration, checkConnection },
    localizationStore: { i18next: i18n },
  } = useStore();
  const timeout = useRef(null);
  const { addToast } = useToasts();

  const [checkingConnection, setCheckingConnection] = useState(false); // used for loader while checking connection
  const [disabledButton, setDisabledButton] = useState(true);
  const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined); // used for editting sql connection
  const [checkConnectionIndex, setCheckConnectionIndex] = useState<number | undefined>(undefined); // used for checking connection status

  const isActiveDisabled = !selectedIntegration?.settings?.[0].configuration?.configName;
  const [isShowing, setIsShowing] = useState(isActiveDisabled);

  const { sqlServer, sqlPort, sqlDatabase, sqlUsername, sqlPassword, configName, url, token } =
    selectedIntegration?.settings?.[editingIndex ?? 0]?.configuration ?? {}; // setting this if editing index is there otherwise get from 1st element

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: {
      configName: configName || null,
      sqlServer: sqlServer || null,
      sqlPort: sqlPort || null,
      sqlDatabase: sqlDatabase || null,
      sqlUsername: sqlUsername || null,
      sqlPassword: sqlPassword || null,
    },
  });

  // calling this use effect while editing index is changed
  useEffect(() => {
    if (editingIndex !== undefined) {
      const currentData = selectedIntegration.settings[editingIndex]?.configuration; //this will get the sql connection details from specified index
      reset({
        configName: currentData?.configName || '',
        sqlServer: currentData?.sqlServer || '',
        sqlPort: currentData?.sqlPort || '',
        sqlDatabase: currentData?.sqlDatabase || '',
        sqlUsername: currentData?.sqlUsername || '',
        sqlPassword: currentData?.sqlPassword || '',
      });
    }
  }, [editingIndex]);

  const onSubmit = async (data: any) => {
    setEditingIndex(undefined);
    // this is for if new connection is added
    if (editingIndex === selectedIntegration.settings.length) {
      selectedIntegration.settings.push({
        // pushing into setting array to add new connection
        configuration: data,
      });
    } else {
      // this for updating existing connection
      handleUpdateConfig(data as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, editingIndex, true);
    }
    setDisabledButton(false); // this enables save button to save changes
  };

  useEffect(() => {
    // @ts-ignore
    timeout.current = setTimeout(() => setIsShowing(false), 3000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, []);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleUpdateConfig(event);
    if (event.target.value.trim()) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleActive = (isActive: boolean) => {
    setDisabledButton(false);
    setSelectedIntegration({ ...selectedIntegration, isActive });
    handleIsActiveChange(selectedIntegration.id, isActive);
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index); // setting edit index
  };

  const handleDelete = (index: number) => {
    setEditingIndex(undefined); // setting edit index to undefined to close edit form
    selectedIntegration.settings.splice(index, 1);
    setDisabledButton(false);
  };

  const handleCheckConnection = async (data: any, index: number) => {
    // this is for checking connection status for that particular connection
    setEditingIndex(undefined);
    setCheckConnectionIndex(index);
    setCheckingConnection(true);
    const { sqlServer, sqlPort, sqlDatabase, sqlPassword, sqlUsername } = data.configuration;
    const checkConnectionData = {
      sqlServer,
      sqlPort,
      sqlDatabase,
      sqlUsername,
      sqlPassword,
    };
    let connectionStatus = await checkConnection(checkConnectionData);
    // showing message according to connection
    if (connectionStatus.status === 'Connection successful') {
      addToast(`Connection successful`, { appearance: 'success' });
    } else {
      addToast(`Something went wrong`, { appearance: 'error' });
    }
    setCheckingConnection(false);
  };

  const handleAddConnection = () => {
    // this is to add new connection
    setEditingIndex(selectedIntegration?.settings?.length);
    reset();
  };

  if (!selectedIntegration) {
    return null;
  }

  const integrationLogo = getIntegrationLogo(selectedIntegration?.serviceName);

  return (
    <>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        {selectedIntegration.tags ? <CategoryContainer>{selectedIntegration.tags}</CategoryContainer> : <div></div>}
        <Flex>
          <Button
            onClick={() => {
              selectedIntegration.serviceName === 'splunk' &&
                window.open('https://avm-public-docs.s3.amazonaws.com/Splunk+Cloud.pdf', '_blank');
            }}
            variant={'outlined'}
            sx={{ marginRight: '16px' }}
            startIcon={<OpenInNewIcon />}
          >
            {i18n.t('documentation.button.action')}
          </Button>
          <Button sx={{ marginRight: '16px' }} onClick={handleSaveChanges} disabled={disabledButton}>
            {i18n.t('common.saveChanges.action')}
          </Button>
        </Flex>
      </Flex>
      <FlexRowStart>
        {integrationLogo ? <Logo src={integrationLogo.src} alt={integrationLogo.alt} /> : <div></div>}
        <Headline3>{selectedIntegration.name}</Headline3>
        <InfoTooltip
          title={i18n.t('adminPortal.integrations.tooltip.switchActive')}
          open={isShowing && isActiveDisabled}
        >
          <PrimarySwitch
            disabled={isActiveDisabled}
            checked={selectedIntegration.isActive}
            onChange={event => handleActive(event.target.checked)}
          />
        </InfoTooltip>
      </FlexRowStart>
      <Typography variant={'body2'} sx={{ marginTop: '12px', marginBottom: '24px', color: GRAY_COLORS.GRAY_8 }}>
        {selectedIntegration.description}
      </Typography>

      {selectedIntegration.name === SUPPORTED_INTEGRATIONS.SQL_SERVER ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t('adminPortal.integrations.label.splunk.name')}</TableCell>
                  <TableCell>{i18n.t('adminPortal.integrations.sql.server')}</TableCell>
                  <TableCell>{i18n.t('adminPortal.integrations.sql.port')}</TableCell>
                  <TableCell>{i18n.t('adminPortal.integrations.sql.database')}</TableCell>
                  <TableCell>{i18n.t('adminPortal.integrations.sql.username')}</TableCell>
                  <TableCell>{i18n.t('adminPortal.tabs.integrations.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedIntegration.settings.map((data: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{data?.configuration?.configName}</TableCell>
                    <TableCell>{data?.configuration?.sqlServer}</TableCell>
                    <TableCell>{data?.configuration?.sqlPort}</TableCell>
                    <TableCell>{data?.configuration?.sqlDatabase}</TableCell>
                    <TableCell>{data?.configuration?.sqlUsername}</TableCell>
                    <TableCell>
                      <IconButton
                        size={'small'}
                        sx={{ color: COLOR_PRIMARY }}
                        title="Edit"
                        onClick={() => handleEdit(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size={'small'}
                        sx={{ color: COLOR_RED }}
                        title="Delete"
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        size={'small'}
                        title={i18n.t('adminPortal.tabs.integrations.testConnection')}
                        onClick={() => handleCheckConnection(data, index)}
                      >
                        {checkingConnection && checkConnectionIndex === index ? (
                          <CircularProgress style={{ width: '18px', height: '18px' }} />
                        ) : (
                          <RssFeedIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            type="submit"
            onClick={() => handleAddConnection()}
            loading={checkingConnection}
            disabled={!isValid || !!editingIndex?.toString()}
            sx={{ marginTop: '16px' }}
          >
            {i18n.t('adminPortal.tabs.integrations.addConnection')}
          </Button>
          {editingIndex !== undefined && (
            <div style={{ marginTop: '20px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb="1rem">
                <Typography variant={'h6'}>Edit Configuration</Typography>
                <Box display="flex" gap="1rem">
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    loading={checkingConnection}
                    disabled={!isValid}
                  >
                    {i18n.t('Save')}
                  </Button>
                  <IconButton
                    onClick={() => {
                      setEditingIndex(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <div style={{ width: '50%' }}>
                    <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
                      {i18n.t('adminPortal.integrations.label.splunk.name')}
                    </Typography>
                    <Controller
                      name="configName"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id="configName"
                          variant={'outlined'}
                          type={'text'}
                          sx={{ marginTop: '4px', height: '40px', marginBottom: '16px', width: '100%' }}
                        />
                      )}
                    />

                    <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
                      {i18n.t('adminPortal.integrations.sql.server')}
                    </Typography>
                    <Controller
                      name="sqlServer"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id="sql-server"
                          variant={'outlined'}
                          type={'text'}
                          sx={{ marginTop: '4px', height: '40px', marginBottom: '16px', width: '100%' }}
                        />
                      )}
                    />

                    <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
                      {i18n.t('adminPortal.integrations.sql.port')}
                    </Typography>
                    <Controller
                      name="sqlPort"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id="sql-port"
                          variant={'outlined'}
                          type={'number'}
                          sx={{ marginTop: '4px', height: '40px', marginBottom: '16px', width: '100%' }}
                        />
                      )}
                    />
                  </div>

                  <div style={{ width: '50%' }}>
                    <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
                      {i18n.t('adminPortal.integrations.sql.database')}
                    </Typography>
                    <Controller
                      name="sqlDatabase"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id="sql-database"
                          variant={'outlined'}
                          type={'text'}
                          sx={{ marginTop: '4px', height: '40px', marginBottom: '16px', width: '100%' }}
                        />
                      )}
                    />

                    <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
                      {i18n.t('adminPortal.integrations.sql.username')}
                    </Typography>
                    <Controller
                      name="sqlUsername"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id="sql-username"
                          variant={'outlined'}
                          type={'text'}
                          sx={{ marginTop: '4px', height: '40px', marginBottom: '16px', width: '100%' }}
                        />
                      )}
                    />

                    <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
                      {i18n.t('adminPortal.integrations.sql.password')}
                    </Typography>
                    <Controller
                      name="sqlPassword"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id="sql-password"
                          variant={'outlined'}
                          type={'password'}
                          sx={{ marginTop: '4px', height: '40px', marginBottom: '16px', width: '100%' }}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </>
      ) : (
        <>
          <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
            {i18n.t('adminPortal.integrations.label.splunk.name')}
          </Typography>
          <TextInput
            id="integration-configName"
            name={'configName'}
            value={configName}
            onChange={onChange}
            variant={'outlined'}
            type={'text'}
            sx={{ marginTop: '4px', height: '40px', marginBottom: '16px' }}
          />
          <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
            {i18n.t('adminPortal.integrations.label.splunk.url')}
          </Typography>
          <TextInput
            id="integration-url"
            name={'url'}
            placeholder={'https://'}
            value={url}
            onChange={onChange}
            variant={'outlined'}
            type={'text'}
            sx={{ marginTop: '4px', height: '40px', marginBottom: '16px' }}
          />

          <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
            {i18n.t('adminPortal.integrations.label.splunk.token')}
          </Typography>
          <TextInput
            id="integration-token"
            name={'token'}
            value={token}
            onChange={onChange}
            variant={'outlined'}
            type={'text'}
            sx={{ marginTop: '4px', height: '40px' }}
          />
        </>
      )}
    </>
  );
};

export default observer(ConfigureIntegrationPage);
