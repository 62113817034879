import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { i18n } from 'i18next';

import { GRAY_COLORS } from '../../../constants/colors';

import Flex from '../../utils/flex/Flex';
import InfoTooltip from '../../tooltip/InfoTooltip';

const HeaderWithTooltip = ({
  name,
  i18n,
  handleTooltip,
  openTooltip,
}: {
  name: string;
  i18n: i18n;
  handleTooltip: (arg: string) => void;
  openTooltip: string;
}) => (
  <Flex sx={{ color: GRAY_COLORS.GRAY_500 }}>
    {i18n.t(`adminPortal.compliance.header.${name}`)}
    <InfoTooltip title={i18n.t(`adminPortal.compliance.tooltip.${name}`)} open={openTooltip === name}>
      <InfoOutlinedIcon
        onMouseEnter={() => handleTooltip(name)}
        onMouseLeave={() => handleTooltip('')}
        sx={{ marginLeft: '9px' }}
      />
    </InfoTooltip>
  </Flex>
);

export default HeaderWithTooltip;
