import { Chat } from './Chat';
import { Team } from './Team';
import { EMPTY_USER, User } from './User';
import { File } from './File';
import { SelectedSqlConnection } from './IntegrationModel';

export enum SupportedExtensions {
  DataAnalysis = 'dataAnalysis',
}

export interface Conversation {
  id: string;
  title: string;
  content: string;
  type: string;
  numberOfChats: number;
  model: string;
  modelDisplayName: string;
  temperature: number;
  maxTokens: number;
  organizationId: string;
  options: string;
  isClosed: boolean;
  isInTeamGallery: boolean;
  isInMyGallery: boolean;
  chats: Chat[];
  teams: Team[];
  user: User;
  createdAt: Date;
  updatedAt: Date;
  fileId?: string;
  files?: File[];
  providerName: string;
  sharedBy?: string;
  loadingResponse: boolean;
  // FE helper field
  isStreaming?: boolean;
  isNewConversation?: boolean;
  sharedByName?: string;
  extension?: SupportedExtensions;
  sharedWithMe?: boolean;
  integration?: SelectedSqlConnection;
}

export const INITIAL_CONVERSATION: Conversation = {
  chats: [],
  content: '',
  createdAt: new Date(),
  id: '',
  isClosed: false,
  isInMyGallery: false,
  isInTeamGallery: false,
  maxTokens: 0,
  model: '',
  modelDisplayName: '',
  numberOfChats: 0,
  options: '',
  organizationId: '',
  teams: [],
  temperature: 0,
  title: '',
  type: '',
  updatedAt: new Date(),
  user: EMPTY_USER,
  providerName: '',
  loadingResponse: false,
  sharedWithMe: false,
};

export interface CreateConversationDto {
  type: 'chat' | 'image';
  model: string;
  temperature: number;
  maxTokens: number;
  providerName?: string;
  promptId?: string;
  chats?: Partial<Chat>[];
  chat?: {
    message?: string;
    promptId?: string;
    files?: {
      id: string;
      name: string;
    }[];
  };
  fileId?: string;
  extension?: SupportedExtensions;
}

export interface ConversationSettings {
  model: string;
  accuracy: number;
  length: number;
  providerName: string;
  modelDisplayName: string;
}

export const DEFAULT_SETTINGS: ConversationSettings = {
  accuracy: 0.8,
  length: 1024,
  model: 'gpt-4-32k',
  providerName: 'open_ai',
  modelDisplayName: 'GPT-4 32k',
};

export interface ShareConversationModel {
  conversationId: string;
  userIds: string[];
}
