import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';

import { COLOR_BORDER_PRIMARY, COLOR_WHITE } from '../../../constants/colors';

import Flex from '../../utils/flex/Flex';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sx?: SxProps;
}

const WrapperModal = ({ isOpen, onClose, children, sx }: ModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack
        flexDirection={'row'}
        sx={{
          background: COLOR_WHITE,
          padding: '24px',
          position: 'absolute',
          flexDirection: 'column',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: `2px solid ${COLOR_BORDER_PRIMARY}`,
          boxShadow: 24,
          borderRadius: '16px',
          ...sx,
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </Modal>
  );
};

const UnsaveChangesModal = ({
  isOpen,
  onClose,
  onDismiss,
  onSave,
  sx,
}: {
  onClose: () => void;
  onSave: () => void;
  onDismiss: () => void;
  isOpen: boolean;
  sx?: SxProps;
}) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <WrapperModal isOpen={isOpen} onClose={onClose} sx={sx}>
      <Typography variant="h6" sx={{ marginTop: '-16px' }}>
        {i18n.t('compliance.modal.unsavedChanges.title')}
      </Typography>
      <Typography variant="subtitle2" sx={{ margin: '12px 0' }}>
        {i18n.t('compliance.modal.unsavedChanges.description')}
      </Typography>

      <FlexRowSpaceBetween>
        <Button variant="outlined" onClick={onClose} sx={{ padding: '14px' }}>
          {i18n.t('common.cancel.action')}
        </Button>
        <Flex sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={onDismiss} sx={{ padding: '14px' }}>
            {i18n.t('common.discardChanges.action')}
          </Button>
          <Button variant="contained" onClick={onSave} sx={{ padding: '14px', marginLeft: '10px' }}>
            {i18n.t('common.save.action')}
          </Button>
        </Flex>
      </FlexRowSpaceBetween>
    </WrapperModal>
  );
};

export default observer(UnsaveChangesModal);
