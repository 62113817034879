import logo from './logo.svg';
import AVMLogo from './AVMLogo.svg';
import dashboardLogo from './dashboardLogo.svg';
import teamsLogo from './teamsLogo.svg';
import promptLogo from './promptLogo.svg';
import knowledgeBaseLogo from './knowledgeBaseLogo.svg';
import modelsLogo from './modelsLogo.svg';
import integrationLogo from './integrationLogo.svg';
import playgroundLogo from './playgroundLogo.svg';
import modelevaluationLogo from './modelevaluationLogo.svg';
import securityPoliciesLogo from './securityPoliciesLogo.svg';
import warningLogo from './warningLogo.svg';
import auditLogo from './auditLogo.svg';
import logoutLogo from './logoutLogo.svg';
import helpCenterLogo from './helpCenterLogo.svg';
import doubleBackArrow from './doubleBackArrow.svg';
import notificationsBellLogo from './notificationsBellLogo.svg';
import NotificationIconLogo from './NotificationIconLogo.svg';

const config = {
  name: 'Add Value Machine',
  domain: 'addvaluemachine',
  logo,
  AVMLogo,
  dashboardLogo,
  teamsLogo,
  promptLogo,
  knowledgeBaseLogo,
  modelsLogo,
  integrationLogo,
  playgroundLogo,
  modelevaluationLogo,
  securityPoliciesLogo,
  warningLogo,
  auditLogo,
  logoutLogo,
  helpCenterLogo,
  doubleBackArrow,
  notificationsBellLogo,
  NotificationIconLogo,
  features: {
    // feature flags
  },
};

export const googleDriveConfig = {
  clientId:
    process.env.GOOGLE_DRIVE_CLIENT_ID || '171553118899-7j1k4ldns7lrqefrjvf5ob79bg0l6oii.apps.googleusercontent.com',
  developerKey: process.env.GOOGLE_DRIVE_DEVELOPER_KEY || 'AIzaSyBw5o2_TCudraFzPrM2VheVthf2yDcmqcQ',
};
export default config;
