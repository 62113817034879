import { i18n } from 'i18next';

interface MethodProps {
  i18n: i18n;
}

export const getTableHeader = ({ i18n }: MethodProps) => {
  let headerCells = [
    {
      label: i18n.t('usersList.table.header.name'),
      property: 'fullName',
      width: '20%',
    },
    {
      label: i18n.t('usersList.table.header.addedBy'),
      property: 'addedBy',
      width: '20%',
    },
    {
      label: i18n.t('usersList.table.header.role'),
      property: 'role',
      width: '15%',
    },
    {
      label: i18n.t('usersList.table.header.position'),
      property: 'position',
      width: '10%',
    },
    {
      label: i18n.t('usersList.table.header.lastActive'),
      property: 'lastActive',
      width: '15%',
    },
    {
      label: i18n.t('usersList.table.header.actions'),
      property: 'more',
      width: '5%',
    },
  ];

  return headerCells;
};
