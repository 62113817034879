import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import {
  SelectedSqlConnection,
  EMPTY_INTEGRATION_MODEL,
  IntegrationModel,
  SUPPORTED_INTEGRATIONS,
} from '../models/IntegrationModel';
import { IntegrationApi } from '../api/IntegrationApi';

const SUPPORTED_NAMES = [
  SUPPORTED_INTEGRATIONS.SPLUNK,
  SUPPORTED_INTEGRATIONS.GOOGLE_DRIVE,
  SUPPORTED_INTEGRATIONS.SQL_SERVER,
];

export class IntegrationsStore {
  integrationsApi: IntegrationApi;
  selectedIntegration: IntegrationModel = EMPTY_INTEGRATION_MODEL;
  integrations: IntegrationModel[] = [];

  constructor(integrationsApi: IntegrationApi) {
    this.integrationsApi = integrationsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedIntegration(selectedIntegration: IntegrationModel) {
    runInAction(() => {
      this.selectedIntegration = selectedIntegration;
    });
  }

  async getIntegrations() {
    const integrations = await this.integrationsApi.getIntegrations();

    runInAction(() => {
      this.integrations = integrations.filter(integration => SUPPORTED_NAMES.includes(integration.name));
    });
  }

  async upsertIntegrations({ integrations }: { integrations: IntegrationModel[] }) {
    await this.integrationsApi.upsertIntegrations({ integrations });
    await this.getIntegrations();
  }

  async checkConnection(data: SelectedSqlConnection) {
    return this.integrationsApi.checkConnection(data);
  }
}
