import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Virtuoso } from 'react-virtuoso';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import LoopIcon from '@mui/icons-material/Loop';

import { SocketEvents } from '../../../../constants/events/SocketEvents';
import { Paths } from '../../../../constants/routes';
import { GRAY_COLORS } from '../../../../constants/colors';

import Flex from '../../../../components/utils/flex/Flex';
import ChatMessage from './Components/ChatMessage';
import { useStore } from '../../../../hooks/useStore';
import { LoadingSpinner } from '../../../../components/spinner/LoadingSpinner';
import ConversationEmptyState from '../ConversationEmptyState';
import Button from '../../../../components/buttons/Button';
import { Chat } from '../../../../models/Chat';

interface ConversationChatProps {
  loading?: boolean;
  isChatDisabled?: boolean;
  lastMessageId?: string;
}

const ConversationChat = ({ loading, isChatDisabled, lastMessageId }: ConversationChatProps) => {
  const {
    conversationStore: {
      currentConversation,
      updateStreamedMessageContent,
      setPartialConversation,
      isLoadingConversation,
      regenerateResponse,
    },
    socketStore: { joinRoom, leaveRoom, subscribe, unSubscribeAllListenersFromSingleEvent, publish },
    userStore: { userData },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [isMessageBeingStreamed, setIsMessageBeingStreamed] = useState(false);
  const lastMessageIndex = lastMessageId
    ? currentConversation.chats.findIndex(item => item.id === lastMessageId)
    : currentConversation?.chats?.length - 1;

  const ListContainer = useMemo(() => {
    return styled(Box)`
      flex: 1;
      flex-direction: column;
      overflow: auto;
      height: auto;
      margin-top: 16px;
      &:first-child {
        margin-top: 0 !important;
      }
      pointer-events: ${isChatDisabled ? 'all' : 'unset'};
      & > * {
        pointer-events: ${isChatDisabled ? 'none' : 'unset'};
      }
    `;
  }, [isChatDisabled]);

  const [initialCursorPosition, setInitialCursorPosition] = useState(0);
  const [canRegenerateAnswer, setCanRegenerateAnswer] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const { chats, providerName, user } = currentConversation;

  let streamedMessageBuffer = '';

  useEffect(() => {
    const userRoom = `user-${userData.id}`;

    joinRoom(userRoom);
    subscribe(SocketEvents.partialNewConversationCreated, ({ conversation, message }) => {
      setPartialConversation(conversation, message);
      navigate(`${Paths.CHAT}/${conversation.id}`, { state: { dontRefresh: true } });
    });

    return () => {
      unSubscribeAllListenersFromSingleEvent(SocketEvents.partialNewConversationCreated);
      leaveRoom(userRoom);
    };
  }, [userData]);

  useEffect(() => {
    streamedMessageBuffer = localStorage.getItem('streamedMessageBuffer') || '';
    setInitialCursorPosition(streamedMessageBuffer.length);

    return () => {
      setIsMessageBeingStreamed(false);
    };
  }, [id]);

  useEffect(() => {
    const conversationRoom = `conversation-${currentConversation.id}`;

    joinRoom(conversationRoom);
    subscribe(SocketEvents.startedStream, () => {
      setIsMessageBeingStreamed(true);
      setInitialCursorPosition(0);
      localStorage.removeItem('streamedMessageBuffer');
      updateStreamedMessageContent(streamedMessageBuffer, true);
    });

    subscribe(SocketEvents.endedStream, () => {
      setIsMessageBeingStreamed(false);
      setInitialCursorPosition(0);
      localStorage.removeItem('streamedMessageBuffer');
      updateStreamedMessageContent(streamedMessageBuffer, false);
    });

    subscribe(SocketEvents.messageChunkSent, data => {
      if (!isMessageBeingStreamed) {
        setIsMessageBeingStreamed(true);
      }
      streamedMessageBuffer = data.message;
      localStorage.setItem('streamedMessageBuffer', streamedMessageBuffer);

      updateStreamedMessageContent(streamedMessageBuffer, true);
    });

    updateCanRegenerateState(currentConversation.chats);

    return () => {
      leaveRoom(conversationRoom);
      unSubscribeAllListenersFromSingleEvent(SocketEvents.startedStream);
      unSubscribeAllListenersFromSingleEvent(SocketEvents.endedStream);
      unSubscribeAllListenersFromSingleEvent(SocketEvents.messageChunkSent);
    };
  }, [currentConversation]);

  const updateCanRegenerateState = (chats: Chat[]) => {
    if (chats.length === 0) {
      setCanRegenerateAnswer(false);
      return;
    }

    const lastChat = chats[chats.length - 1];

    if (lastChat.loading) {
      setCanRegenerateAnswer(false);
      return;
    }

    setCanRegenerateAnswer(!Array.isArray(lastChat.files) || lastChat.files?.length === 0);
  };

  if (loading || isLoadingConversation) {
    return (
      <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingSpinner />
      </Box>
    );
  }

  if (!chats?.length) {
    return <ConversationEmptyState />;
  }

  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        margin: '16px 0',
        width: '100%',
        height: '100%',
        '&:first-child': {
          marginTop: '0 !important',
        },
      }}
    >
      <Virtuoso
        components={{
          // @ts-ignore
          List: ListContainer,
        }}
        data={chats}
        followOutput={'auto'}
        atBottomThreshold={100}
        initialTopMostItemIndex={chats.length - 1}
        itemContent={(index, item) => {
          if (lastMessageIndex >= index) {
            if (index === chats.length - 1) {
              return (
                <ChatMessage chat={item} chatUser={user?.id ? user : userData} cursorPosition={initialCursorPosition} />
              );
            }

            return <ChatMessage chat={item} chatUser={user?.id ? user : userData} />;
          }
        }}
      />

      {isMessageBeingStreamed && (
        <Button
          variant={'outlined'}
          sx={{
            marginTop: '8px',
            alignSelf: 'center',
            height: '32px !important',
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
            padding: '7px 12px !important',
          }}
          onClick={() => {
            publish(`cancelStreaming-${providerName}`, 'cancelStreaming', {
              roomId: `conversation-${id}`,
            });
          }}
        >
          <CropSquareIcon sx={{ color: GRAY_COLORS.GRAY_5, marginRight: '8px' }} />
          {i18n.t('conversation.stopGeneration.action')}
        </Button>
      )}
      {!isMessageBeingStreamed && canRegenerateAnswer && (
        <Button
          variant={'outlined'}
          sx={{
            marginTop: '8px',
            alignSelf: 'center',
            height: '32px !important',
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
            padding: '7px 12px !important',
          }}
          onClick={() => regenerateResponse(currentConversation.id)}
        >
          <LoopIcon sx={{ color: GRAY_COLORS.GRAY_5, marginRight: '8px', transform: 'rotate(90deg)' }} />
          Regenerate Response
        </Button>
      )}
    </Flex>
  );
};

export default observer(ConversationChat);
