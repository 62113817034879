import React from 'react';
import { Modal, Box, Typography, Button, Grid, TextField, MenuItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { ConfigurationField } from '../../../../../models/OrganizationModel';

interface AddFieldModalProps {
  open: boolean;
  onClose: () => void;
  onAddField: (fieldKey: string, fieldConfig: ConfigurationField) => void;
}

const AddFieldModal: React.FC<AddFieldModalProps> = ({ open, onClose, onAddField }) => {
  const [newFieldName, setNewFieldName] = React.useState('');
  const [newFieldType, setNewFieldType] = React.useState<'string' | 'number' | 'boolean'>('string');

  const handleAddField = () => {
    if (newFieldName && newFieldType) {
      onAddField(newFieldName, { type: newFieldType, value: null });
      setNewFieldName('');
      setNewFieldType('string');
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          borderRadius: '16px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add New Field
        </Typography>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <WarningIcon color="warning" />
          <Typography variant="subtitle1" color="textSecondary" marginLeft={1}>
            Adding new fields may impact the way the model behaves.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Field Name"
              value={newFieldName}
              onChange={e => setNewFieldName(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label="Field Type"
              value={newFieldType}
              onChange={e => setNewFieldType(e.target.value as 'string' | 'number' | 'boolean')}
              fullWidth
              variant="outlined"
            >
              {['string', 'number', 'boolean'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" marginTop={2}>
          <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleAddField}>
            Add
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddFieldModal;
