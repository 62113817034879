import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';

import { filterValues } from '../../../constants/adminPortal/auditLogs/filter';

import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import FlexRowCenter from '../../utils/flex/FlexRowCenter';
import SelectWithSearch from '../../select/SelectWithSearch';
import { useStore } from '../../../hooks/useStore';

const statuses = ['allowed', 'warning', 'blocked', 'anonymize'];

const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

interface FilterProps {
  selectedTeams: string[];
  setSelectedTeams: (a1: string[]) => void;
  selectedUsers: string[];
  setSelectedUsers: (a1: string[]) => void;
  selectedEvents: string[];
  setSelectedEvents: (a1: string[]) => void;
  selectedStatuses: string[];
  setSelectedStatuses: (a1: string[]) => void;
  startDate: Dayjs | null;
  setStartDate: (a1: Dayjs | null) => void;
  endDate: Dayjs | null;
  setEndDate: (a1: Dayjs | null) => void;
}
const FiltersSection = ({
  selectedTeams,
  setSelectedTeams,
  selectedUsers,
  setSelectedUsers,
  selectedEvents,
  setSelectedEvents,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedStatuses,
  setSelectedStatuses,
}: FilterProps) => {
  const {
    localizationStore: { i18next: i18n },
    teamStore: { teams },
    userStore: { users },
  } = useStore();
  return (
    <FlexRowSpaceBetween sx={{ padding: '0 24px 24px 24px' }}>
      <FlexRowCenter>
        <SelectWithSearch
          value={selectedTeams}
          placeholder={`${i18n.t('labels.team')}: ${i18n.t('labels.all')}`}
          multiple={true}
          onChange={(event: any) => setSelectedTeams(event.target.value)}
          options={teams.map(team => {
            return { name: team.name, value: team.name };
          })}
          menuSX={{ height: '32px', width: '150px', fontStyle: 'normal' }}
          backgroundOnActive={true}
          onClearFilters={() => setSelectedTeams([])}
        />
        <SelectWithSearch
          value={selectedUsers}
          placeholder={`${i18n.t('adminPortal.tabs.users.action')}: ${i18n.t('labels.all')}`}
          multiple={true}
          onChange={(event: any) => setSelectedUsers(event.target.value)}
          options={users.map(user => {
            return { name: user.fullName || user.email, value: user.fullName || user.email };
          })}
          menuSX={{ height: '32px', width: '150px', fontStyle: 'normal' }}
          backgroundOnActive={true}
          onClearFilters={() => setSelectedUsers([])}
        />
        <SelectWithSearch
          value={selectedEvents}
          placeholder={`${i18n.t('logsList.table.header.eventType')}: ${i18n.t('labels.all')}`}
          multiple={true}
          onChange={(event: any) => setSelectedEvents(event.target.value)}
          options={filterValues.map(filter => {
            return { name: filter.name, value: filter.name };
          })}
          menuSX={{ height: '32px', width: '150px', fontStyle: 'normal' }}
          backgroundOnActive={true}
          onClearFilters={() => setSelectedEvents([])}
        />
        <SelectWithSearch
          value={selectedStatuses}
          placeholder={`${i18n.t('common.status.message')}: ${i18n.t('labels.all')}`}
          multiple={true}
          onChange={(event: any) => setSelectedStatuses(event.target.value)}
          options={statuses.map(status => {
            return { name: capitalizeFirst(status), value: capitalizeFirst(status) };
          })}
          menuSX={{ height: '32px', width: '150px', fontStyle: 'normal' }}
          backgroundOnActive={true}
          onClearFilters={() => setSelectedStatuses([])}
        />
      </FlexRowCenter>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField', 'DateField']}>
          <FlexRowCenter>
            <DatePicker
              label={!startDate && `${i18n.t('labels.startDate')}: All time`}
              onChange={(newValue: Dayjs | null) => {
                setStartDate(newValue);
              }}
              value={startDate}
              format={'MM.DD.YYYY'}
              sx={{
                width: !startDate ? '195px' : '155px',
                '& fieldset': {
                  border: `none !important`,
                },
              }}
            />
            <DatePicker
              label={!endDate && `${i18n.t('labels.endDate')}: Present`}
              onChange={(newValue: Dayjs | null) => setEndDate(newValue)}
              value={endDate}
              format={'MM.DD.YYYY'}
              sx={{
                width: !endDate ? '185px' : '155px',
                '& fieldset': {
                  border: `none !important`,
                },
              }}
            />
          </FlexRowCenter>
        </DemoContainer>
      </LocalizationProvider>
    </FlexRowSpaceBetween>
  );
};

export default observer(FiltersSection);
