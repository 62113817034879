import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box } from '@mui/material';

import { useStore } from '../../hooks/useStore';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { downloadDocumentSecurely } from '../../utils/documentsS3';

const FileThumbnail = ({ fileKey }: { fileKey: string }) => {
  const {
    appState: { s3DocumentsApi },
    knowledgeBaseStore: { uploadInProgress },
  } = useStore();
  pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

  const [pdfFilePreview, setPdfFilePreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fileKey && !uploadInProgress) {
      (async () => {
        setIsLoading(true);
        const preSignedUrl = await s3DocumentsApi.generateDocumentsReadUrl(fileKey);

        const blob = await downloadDocumentSecurely(preSignedUrl.signedUrl);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64String = reader.result as string;
          const pdfBase64 = `data:application/pdf;base64,${base64String.slice(base64String.indexOf(',') + 1)}`;
          setPdfFilePreview(pdfBase64);
        };
        setIsLoading(false);
      })();
    }
  }, [fileKey, uploadInProgress]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {};

  if (isLoading) {
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  }
  return (
    <Document file={pdfFilePreview} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
    </Document>
  );
};

export default observer(FileThumbnail);
