import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Typography from '@mui/material/Typography';

import { COLOR_PRIMARY, COLOR_UTILS_TERTIARY, COLOR_WHITE, GRAY_COLORS } from '../../../../constants/colors';

import Flex from '../../../utils/flex/Flex';
import { Team } from '../../../../models/Team';
import { ReactNode } from 'react';
import { useStore } from '../../../../hooks/useStore';
import TeamUsersPictures from './TeamUsersPictures';

const CardContainer = styled(Stack)`
  padding: 16px;
  height: 166px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${COLOR_WHITE};
  border: 1px solid #e0e2e9;
`;

interface TeamInfoComponentProps {
  icon: ReactNode;
  text: string;
}

const TeamInfoComponent = ({ icon, text }: TeamInfoComponentProps) => (
  <Flex sx={{ alignItems: 'center' }}>
    <Flex
      sx={{
        background: COLOR_UTILS_TERTIARY,
        borderRadius: '50%',
        padding: '2px',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon}
    </Flex>
    <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_8, marginLeft: '6px' }}>
      {text}
    </Typography>
  </Flex>
);

interface TeamCardProps {
  team: Team;
  setTeamToEdit: (team: Team) => void;
  showEditTeamModal: () => void;
}

const TeamCard = ({ team, setTeamToEdit, showEditTeamModal }: TeamCardProps) => {
  const {
    teamStore: { setSelectedTeam, selectedTeam },
  } = useStore();
  const { name, users, totalChatViolations, totalChats, totalPrompts } = team;

  const handleClick = () => {
    setSelectedTeam(team);
  };

  return (
    <CardContainer
      onClick={handleClick}
      sx={{ backgroundColor: selectedTeam.id === team.id ? COLOR_UTILS_TERTIARY : COLOR_WHITE }}
    >
      <TeamUsersPictures users={users} />
      <Typography variant={'h5'} sx={{ fontSize: '18px', fontWeight: 600 }}>
        {name}
      </Typography>
      <Flex sx={{ width: '100%', gap: '24px', marginTop: 'auto' }}>
        <TeamInfoComponent
          icon={<LightbulbIcon sx={{ fill: COLOR_PRIMARY, width: '16px', height: '16px' }} />}
          text={totalPrompts?.toString() || '0'}
        />
        <TeamInfoComponent
          icon={<QuestionAnswerIcon sx={{ fill: COLOR_PRIMARY, width: '16px', height: '16px' }} />}
          text={totalChats?.toString() || '0'}
        />
        <TeamInfoComponent
          icon={<ReportProblemIcon sx={{ fill: COLOR_PRIMARY, width: '16px', height: '16px' }} />}
          text={totalChatViolations?.toString() || '0'}
        />
      </Flex>
    </CardContainer>
  );
};

export default TeamCard;
