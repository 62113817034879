import React, { useEffect, useState } from 'react';
import { Popover, List, ListItem } from '@mui/material';
import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import { CustomSearchBar } from '../../../components/CustomSearchBar/CustomSearchBar';
import { OrganizationModel } from '../../../models/OrganizationModel';

interface Props {
  anchorEl: HTMLElement | null | undefined;
  onClose: () => void;
}

// Model selection dropdown for playground screen
const ModelSelectionPopover: React.FC<Props> = ({ anchorEl, onClose }) => {
  const [displayOptions, setDisplayOptions] = useState<OrganizationModel[]>();
  const [searchValue, setSearchValue] = useState('');

  const {
    modelStore: { findAllOrganizationModelsAccessibleByUser, organizationModels },
  } = useStore();

  useEffect(() => {
    findAllOrganizationModelsAccessibleByUser();
  }, []);

  // To get all active organization models
  const enabledOrganizationModels = organizationModels.filter(model => model.isActive);

  useEffect(() => {
    if (!searchValue) {
      setDisplayOptions(enabledOrganizationModels);
    }
  }, [searchValue, enabledOrganizationModels]);

  // Use effect for search functionality
  useEffect(() => {
    if (searchValue) {
      const filteredOptions = enabledOrganizationModels?.filter(
        item => item.displayName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      ) as OrganizationModel[];
      setDisplayOptions(filteredOptions);
    }
  }, [searchValue]);

  const handleSelectOption = (model: OrganizationModel) => {
    onClose();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
        <CustomSearchBar
          onChange={setSearchValue}
          onCancelSearch={() => setSearchValue('')}
          placeholder={'Search'}
          sx={{ marginBottom: '24px', padding: '0px 8px', flex: 3 }}
        />
        {displayOptions?.map(model => (
          <ListItem
            key={model?.id}
            onClick={() => {
              handleSelectOption(model as OrganizationModel);
            }}
          >
            {model.displayName}
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default observer(ModelSelectionPopover);
