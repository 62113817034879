import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, IconButton, Slider, Stack, TextField } from '@mui/material';

import { COLOR_PRIMARY, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import { OrganizationModel } from '../../../models/OrganizationModel';
import { useStore } from '../../../hooks/useStore';
import { ConversationSettings } from '../../../models/Conversation';
import SupportedAiProviderLogo from '../../SupportedProviderLogo/SupportedAiProviderLogo';

type SelectModelDetailsProps = {
  updateModelState: (newConversationSettings: ConversationSettings) => void;
  options: OrganizationModel[];
  modelSettings: ConversationSettings;
  defaultModel?: OrganizationModel;
  isSecondaryModel?: boolean;
};

const SelectModelDetails = ({
  defaultModel,
  modelSettings,
  updateModelState,
  options,
  isSecondaryModel,
}: SelectModelDetailsProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: { setIsSecondaryModelSelected },
  } = useStore();

  useEffect(() => {
    if (defaultModel) {
      setModel(defaultModel);
    }
  }, [defaultModel]);

  const [accuracy, setAccuracy] = useState<number>(modelSettings.accuracy);
  const [length, setLength] = useState<number>(modelSettings.length);
  const [model, setModel] = useState<OrganizationModel>();

  const isTemperatureAndMaxTokensSlidersActive = !!model;

  return (
    <SettingsSectionContainer sx={{ border: `1px solid ${GRAY_COLORS.GRAY_2}`, borderRadius: '6px', margin: '16px 0' }}>
      {isSecondaryModel && (
        <IconButton
          onClick={() => {
            setIsSecondaryModelSelected(false);
          }}
          sx={{ position: 'absolute', right: '0px' }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <Stack sx={{ padding: '16px' }}>
        <Typography variant={'body2'} sx={{ fontWeight: 500, marginBottom: '8px' }}>
          {`${isSecondaryModel ? 'Secondary' : ''} ${i18n.t('home.newConversation.settings.model')}`}
        </Typography>
        <Autocomplete
          disableClearable={true}
          value={{ ...model } as OrganizationModel}
          getOptionLabel={(option: OrganizationModel) => `${option.providerName} - ${option.displayName}`}
          renderInput={params => {
            return (
              <AutoCompleteTextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SupportedAiProviderLogo
                      provider={model?.providerName || ''}
                      size={24}
                      style={{ marginRight: '16px' }}
                    />
                  ),
                }}
                placeholder={'Select'}
              />
            );
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ padding: '24px 12px' }}>
              <SupportedAiProviderLogo provider={option.providerName} size={24} style={{ marginRight: '16px' }} />
              {option.providerName} - {option.displayName}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={options}
          onChange={(event, value) => {
            setModel(value || undefined);
            updateModelState({
              ...modelSettings,
              model: value?.modelVersion,
              providerName: value?.providerName,
              modelDisplayName: value?.displayName,
            });
          }}
        />
      </Stack>
      <Stack
        style={
          isTemperatureAndMaxTokensSlidersActive
            ? { padding: '16px' }
            : { padding: '16px', opacity: 0.5, pointerEvents: 'none' }
        }
      >
        <Typography variant={'body2'} sx={{ fontWeight: 500, color: COLOR_TEXT_PRIMARY, marginBottom: '4px' }}>
          {i18n.t('home.newConversation.settings.accuracy')}
        </Typography>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '4px' }}>
          {i18n.t('home.newConversation.settings.accuracyDetails')}
        </Typography>
        <CustomSlider
          min={0}
          max={model?.config?.maxTemperature?.value || 1}
          step={0.1}
          onChange={(event, value) => {
            setAccuracy(value as number);
            updateModelState({ ...modelSettings, accuracy: value as number });
          }}
          value={accuracy}
          valueLabelDisplay={'on'}
        />
      </Stack>
      <Stack
        style={
          isTemperatureAndMaxTokensSlidersActive
            ? { padding: '16px' }
            : { padding: '16px', opacity: 0.5, pointerEvents: 'none' }
        }
      >
        <Typography variant={'body2'} sx={{ fontWeight: 500, color: COLOR_TEXT_PRIMARY, marginBottom: '4px' }}>
          {i18n.t('home.newConversation.settings.length')}
        </Typography>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '4px' }}>
          {i18n.t('home.newConversation.settings.lengthDetails')}
        </Typography>
        <CustomSlider
          min={1}
          max={model?.config?.maxTokens?.value || 2048}
          onChange={(event, value) => {
            setLength(value as number);
            updateModelState({ ...modelSettings, length: value as number });
          }}
          value={length}
          valueLabelDisplay={'on'}
        />
      </Stack>
    </SettingsSectionContainer>
  );
};

export default observer(SelectModelDetails);

const SettingsSectionContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 16px;
  position: relative;
`;

export const CustomSlider = styled(Slider)`
  height: 2px;

  .MuiSlider-track {
    border: none;
  }

  .MuiSlider-rail {
    background-color: ${GRAY_COLORS.GRAY_500};
  }

  .MuiSlider-thumb {
    background-color: ${COLOR_PRIMARY};
    width: 12px;
    height: 16px;
    border-radius: 2px;
  }

  .MuiSlider-valueLabel {
    background-color: transparent;
    top: 45px;
    color: ${GRAY_COLORS.GRAY_500};
  }
`;

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;
