import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import FlexRowStart from '../../utils/flex/FlexRowStart';
import { useStore } from '../../../hooks/useStore';
import FlexColumnStart from '../../utils/flex/FlexColumnStart';
import ItemsList from '../../itemsList/ItemsList';
import TextInput from '../../inputs/TextInput';
import HeaderWithTooltip from './HeaderWithTooltip';
import RadioButtonItem from './RadioButtonItem';
import { ComplianceModel, ComplianceRule } from '../../../models/ComplianceModel';
import { PrimarySwitch } from '../../Switch/PrimarySwitch';

const cellStyle = {
  maxWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  background: 'inherit',
  paddingTop: '14px',
  paddingBottom: '12px',
  width: '100%',
  border: 'unset',
};

const Title = styled.div`
  margin-right: 24px;
`;

const tableRowStyle = {
  cursor: 'pointer',
};

interface ExpandableRowProps {
  type: string;
  entity: ComplianceModel;
  setEntity: (entity: ComplianceModel) => void;
  index?: number;
}

const ExpandableRow = ({
  entity,
  setEntity,
  type,
  index
}: ExpandableRowProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState('');
  const [toggleAll, setToggleAll] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOnOffValue = () => {
    const isOn = entity?.rules?.find(item => item.isActive);
    const isOff = entity?.rules?.find(item => !item.isActive);
    setToggleAll(isOn && isOff ? false : !!isOn);
  };

  useEffect(() => {
    toggleOnOffValue();
  }, [entity?.rules]);

  const handleToggleAll = () => {
    setToggleAll(!toggleAll);

    const items = entity?.rules?.reduce((acc: Array<ComplianceRule>, item: ComplianceRule) => {
      acc.push({ ...item, isActive: !toggleAll });
      return acc;
    }, []);

    setEntity({ ...entity, rules: items });
  };
  const onChangeToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target;

    const items = entity?.rules?.map(item => {
      if (item.id === name) {
        return { ...item, isActive: checked };
      }
      return item;
    });

    setEntity({ ...entity, rules: items });
  };

  const handleChangeRadioValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const items = entity?.rules?.map(item => {
      if (item.id === name) {
        return { ...item, restrictionLevel: value };
      }
      return item;
    });

    setEntity({ ...entity, rules: items });
  };

  const onChangeKeyword = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;

    const items = entity?.rules?.reduce((acc: Array<ComplianceRule>, item: ComplianceRule) => {
      const entry = acc.find(i => i.id === name);
      if (entry) {
        entry.anonymizeText = value;
      }
      acc.push(item);
      return acc;
    }, []);

    setEntity({ ...entity, rules: items });
  };

  const handleTooltip = (name: string) => {
    setTimeout(() => {
      setOpenTooltip(name);
    }, 200);
  };

  const handleToggleExpand = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  const tableHeaderColumnNames = [
    {
      label: (
        <>
          <PrimarySwitch value={toggleAll} onChange={handleToggleAll} checked={toggleAll} />
          {i18n.t('adminPortal.compliance.header.toggleAll')}
        </>
      ),
      property: 'toggleAll',
      width: '40%',
    },
    {
      label: <HeaderWithTooltip name={'blocked'} handleTooltip={handleTooltip} openTooltip={openTooltip} i18n={i18n} />,
      property: 'blocked',
      width: '10%',
    },
    {
      label: <HeaderWithTooltip name={'warning'} handleTooltip={handleTooltip} openTooltip={openTooltip} i18n={i18n} />,

      property: 'warning',
      width: '10%',
    },
    {
      label: (
        <HeaderWithTooltip name={'anonymize'} handleTooltip={handleTooltip} openTooltip={openTooltip} i18n={i18n} />
      ),

      property: 'anonymize',
      width: '40%',
    },
  ];

  const tableData = entity?.rules?.filter(rule => rule.type === type).map(item => {
    return {
      toggleAll: (
        <FlexRowStart>
          <PrimarySwitch value={item.isActive} onChange={onChangeToggle} checked={item.isActive} name={item.id} />
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            {i18n.t(item.name)}
          </Typography>
        </FlexRowStart>
      ),
      blocked: item.isActive ? (
        <RadioButtonItem radioValue={'blocked'} handleChangeRadioValue={handleChangeRadioValue} item={item} />
      ) : (
        ''
      ),
      warning: item.isActive ? (
        <RadioButtonItem radioValue={'warning'} handleChangeRadioValue={handleChangeRadioValue} item={item} />
      ) : (
        ''
      ),
      anonymize: item.isActive ? (
        <FlexRowStart>
          <RadioButtonItem radioValue={'anonymize'} handleChangeRadioValue={handleChangeRadioValue} item={item} />
          {item.restrictionLevel === 'anonymize' && (
            <TextInput
              id={item.id}
              label={i18n.t('labels.defaultKeyword')}
              name={item.id}
              value={item.anonymizeText || '****'}
              onChange={onChangeKeyword}
              variant={'outlined'}
              type={'string'}
            />
          )}
        </FlexRowStart>
      ) : (
        ''
      ),
    };
  });

  return (
    <TableRow sx={[tableRowStyle, { height: '82px' }]} id={`Toggle-Row-${index}`}>
      <TableCell sx={cellStyle}>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 600, width: '100%', height: '100%' }}
          onClick={handleToggleExpand}
        >
          <FlexRowStart sx={{ marginTop: '14px' }}>
            <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
            <div>
              <Title>{i18n.t(entity.name)}</Title>
              <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px', color: GRAY_COLORS.GRAY_500 }}>
                {i18n.t(entity.description)}
              </Typography>
            </div>
          </FlexRowStart>
        </Typography>

        <Collapse in={open} timeout="auto" sx={{ borderLeft: 'none', padding: '0 20px 0 10px' }}>
          <Box style={{ overflow: 'auto' }}>
            <div ref={contentRef}>
              <FlexColumnStart>
                <ItemsList
                  height={'unset'}
                  headerCells={tableHeaderColumnNames}
                  tableData={tableData}
                  displaySearch={false}
                  sxHeader={{ background: `${COLOR_WHITE} !important`, textTransform: 'uppercase', textAlign: 'start' }}
                  sxCell={{ border: 'unset' }}
                />
              </FlexColumnStart>
            </div>
          </Box>
        </Collapse>
      </TableCell>
      <TableCell sx={cellStyle} style={{ paddingBottom: 0 }}></TableCell>
    </TableRow>
  );
};

export default observer(ExpandableRow);
