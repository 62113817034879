import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import ApartmentIcon from '@mui/icons-material/ApartmentOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Stack from '@mui/material/Stack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { observer } from 'mobx-react';

import { COLOR_WHITE, GRAY_COLORS } from '../constants/colors';
import { SIZES_SMALL } from '../constants/sizes';

import { useStore } from '../hooks/useStore';
import CopyRight from '../components/copyRight/CopyRight';
import RoleLabel from '../components/role/RoleLabel';
import Flex from '../components/utils/flex/Flex';
import EditProfileModal from '../components/profile/EditProfileModal';
import { DropdownOption } from '../models/DropdownOption';
import UploadProfilePictureComponent from '../components/uploadProfilePicture/UploadProfilePictureComponent';
import Button from '../components/buttons/Button';
import TeamUsersPictures from '../components/adminPortal/teams/teamCards/TeamUsersPictures';
import { IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterComponent from '../components/adminPortal/insights/FilterComponent';
import FlexRowSpaceBetween from '../components/utils/flex/FlexRowSpaceBetween';
import { Random, MersenneTwister19937 } from 'random-js';
import GeneralModal from '../components/modal/GeneralModal';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';


const PageContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 20px 20px;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;

  @media screen and (max-width: ${SIZES_SMALL}) {
    margin: 16px;
    width: 100%;
  }
`;

const CardsContainer = styled(Box)`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: ${SIZES_SMALL}) {
    flex-direction: column;
  }
`;

const Card = styled(Box)`
  background: ${COLOR_WHITE};
  padding: 24px 40px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.05),
    0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${GRAY_COLORS.GRAY_2};
  margin-top: 9px;
  margin-bottom: 8px;
`;

const UserPersonalDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${SIZES_SMALL}) {
    flex-direction: column !important;
  }
`;

interface UserTokenData {
  id: string;
  tokenName: string;
  userName: string;
  displayToken: string;
  expiryAt: string;
  createdAt: string;
}

const FILTER_OPTIONS = {
  ALL_TIME: 'allTime',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  THIS_YEAR: 'thisYear',
  FOREVER: 'forever',
};

const random = new Random(MersenneTwister19937.autoSeed());

const ProfilePage = () => {
  const initialExpiration = '30';
  const {
    localizationStore: { i18next: i18n },
    userStore: { userData, getUserInformation },
    teamStore: { teams, getTeamsByUserOrganization },
    authStore: { accessToken, refreshToken },
    tokenStore: { createToken, getTokensByUserOrganization, tokenList, removeToken },
  } = useStore();

  const calculateExpirationDate = (days:any) => {
    if (days === 'Forever') {
     const expirationDate = moment().add(2, 'years');
     return `Expires on ${expirationDate.format('MMM D YYYY')}`;
    } else {
      const expirationDate = moment().add(days, 'days');
      return `Expires on ${expirationDate.format('MMM D YYYY')}`;
    }
  };

  function getRandomString(length: number) {
    const prefix = 'avm_';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
    const randomString = Array.from({ length }, () => characters.charAt(random.integer(0, characters.length - 1))).join(
      ''
    );
    return prefix + randomString;
  }
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [teamOptions, setTeamOptions] = useState<DropdownOption[]>([]);
  const [currentTeams, setTeams] = useState<string[]>([]);
  const [tokenName, setTokenName] = useState<string>('');
  const [expiration, setExpiration] = useState<string>(initialExpiration);
  const [expirationDate, setExpirationDate] = useState(calculateExpirationDate(30));
  const [tokens, setTokens] = useState<UserTokenData[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('allTime');
  const [randomString, setRandomString] = useState(getRandomString(64));
  const [expiryDate, setExpiryDate] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tokenId, setTokenId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(tokens);
  const { addToast } = useToasts();


  function filterTokens(data: any[]): UserTokenData[] {
    return data.map(item => ({
      id: item.id,
      tokenName: item.tokenName,
      userName:item.userName,
      displayToken: item.displayToken,
      expiryAt: item.expiryAt,
      createdAt: item.createdAt,
    }));
  };

  const formatExpiryDate = (expiryAt:string) => {
    const date = moment(expiryAt);
    const formattedDate = date.format('MMM D, YYYY');
    return `Expires on ${formattedDate}`;
  };

const filterData = (tokenData: any[]) => {
  const now = moment().utc();
  let filtered: any[] = [];

  switch (selectedFilter) {
    case FILTER_OPTIONS.THIS_MONTH:
      filtered = tokenData.filter(item => moment(item.expiryAt).isSame(now, 'month'));
      break;
    case FILTER_OPTIONS.LAST_MONTH:
      filtered = tokenData.filter(item => moment(item.expiryAt).isSame(moment(now).subtract(1, 'month'), 'month'));
      break;
    case FILTER_OPTIONS.THIS_YEAR:
      filtered = tokenData.filter(item => moment(item.expiryAt).isSame(now, 'year'));
      break;
    case FILTER_OPTIONS.FOREVER:
      filtered = tokenData.filter(item => moment(item.expiryAt).isAfter(moment(now).add(1, 'year')));
      break;
    default:
      filtered = tokenData;
      break;
  }
  setFilteredData(filtered);
};


  useEffect(() => {
  calculateExpiration(expiration);

    (async () => {
      await getUserInformation();
      await getTeamsByUserOrganization();
      await getTokensByUserOrganization();
    })();
  }, []);

  useEffect(() => {
    let result = filterTokens(tokenList);
    setTokens(result);
  }, [tokenList]);

  useEffect(() => {
    filterData(tokens);
  }, [selectedFilter, tokens]);

  useEffect(() => {
    const teamsOptions: DropdownOption[] = teams?.map(team => {
      return { name: team.name, value: team.name, id: team.id };
    });

    setTeamOptions(teamsOptions);
  }, [teams]);

  useEffect(() => {
    const userTeams: string[] =
      userData?.teams?.map((item, index) => {
        return item.name;
      }) || [];
    setTeams(userTeams);
  }, [userData]);

  useEffect(() => {
    if (randomString === '') {
      generateNewString(getRandomString(64));
    }
  }, [randomString]);


  const onCloseModal = () => {
    setEditModalOpened(false);
  };

  const onOpenModal = () => {
    setEditModalOpened(true);
  };
  const handleTokenNameChange = (event: any) => {
    setTokenName(event.target.value);
  };

  const calculateExpiration = (value:string) => {
    const currentDate = moment();
    let newExpiration;

    if (value === '7') {
      newExpiration = currentDate.add(6, 'days');
    } else if (value === '30') {
      newExpiration = currentDate.add(30, 'days');
    } else if (value === '60') {
      newExpiration = currentDate.add(60, 'days');
    } else if (value === 'Forever') {
      newExpiration = currentDate.add(2, 'years');
    } else {
      newExpiration = currentDate;
    }
    const formattedDate = newExpiration.format('YYYY-MM-DD HH:mm:ss.SSS');
    setExpiryDate(formattedDate);
  };

  const handleExpirationChange = (event: any) => {
    const { value } = event.target;
    setExpiration(value);
    setExpirationDate(calculateExpirationDate(value));
    calculateExpiration(value.toString());
  };

const handleGenerateToken = async () => {
  const resetForm = () => {
    setTokenName('');
    setExpiration('30');
    setExpirationDate(calculateExpirationDate(30));
    setRandomString('');
    setExpiryDate('');
    setIsLoading(false);
  };

  const showSuccessMessage = () => {
    addToast(i18n.t('adminPortal.token.save.successful'), { appearance: 'success' });
  };

  const showErrorMessage = () => {
    addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
  };

  const createPayLoad = () => ({
    userId: userData?.id,
    refreshToken: refreshToken,
    tokenName: tokenName,
    displayToken: randomString,
    expiryAt: expiryDate,
  });

  try {
    setIsLoading(true);
    const payLoad = createPayLoad();
    await createToken(payLoad);
    showSuccessMessage();
  } catch (e) {
    showErrorMessage();
  } finally {
    resetForm();
  }
};


  const handleDeleteToken = async (id:string) => {
    setOpenDeleteModal(true);
    setTokenId(id);
  };

  const generateNewString = (newString: string) => {
    setRandomString(newString);
  };

  const isGenerateButtonDisabled = !tokenName || !expiration;

  return (
    <PageContainer>
      <CardsContainer>
        <Card sx={{ flex: 2, maxWidth: '500px' }}>
          <Flex>
            <UploadProfilePictureComponent user={userData} size={80} sx={{ marginRight: '20px' }} />
            <Stack sx={{ width: '100%' }}>
              <UserPersonalDetails>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    marginBottom: '2px',
                    marginRight: '12px',
                  }}
                >
                  {userData?.fullName}
                </Typography>
                <RoleLabel type={userData.role} />
              </UserPersonalDetails>

              <Typography variant="subtitle2" sx={{ fontWeight: 400, color: GRAY_COLORS.GRAY_8 }}>
                {userData?.position}
              </Typography>
              <HorizontalDivider />
              <Typography variant="subtitle2" sx={{ fontWeight: 400, color: GRAY_COLORS.GRAY_8 }}>
                {userData?.email}
              </Typography>

              <Flex sx={{ marginTop: '40px', gap: '8px' }}>
                <Button
                  variant={'outlined'}
                  startIcon={<EditOutlinedIcon sx={{ width: '16px', height: '16px' }} />}
                  sx={{
                    width: 'fit-content',
                    borderColor: `${GRAY_COLORS.GRAY_2} !important`,
                    color: `${GRAY_COLORS.GRAY_9} !important`,
                    padding: '7px 12px !important',
                    fontWeight: 500,
                  }}
                  onClick={onOpenModal}
                >
                  {i18n.t('profile.editProfile.action')}
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </Card>
        <Card
          sx={{
            padding: '24px 40px',
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            maxWidth: '500px',
          }}
        >
          <Typography variant={'h6'} sx={{ fontWeight: 700, marginBottom: '2px', marginRight: '12px' }}>
            {i18n.t('profile.details.message')}
          </Typography>
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_8, marginTop: '4px' }}>
            {i18n.t('profile.specifics.message')}
          </Typography>

          <Flex
            sx={{
              marginTop: '24px',
              alignItems: 'center',
              width: '100%',
              gap: '4px',
            }}
          >
            <ApartmentIcon fontSize={'small'} sx={{ color: GRAY_COLORS.GRAY_5 }} />
            <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
              {`${i18n.t('profile.organization.message')}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, marginLeft: 'auto' }}>
              {userData?.organization?.name}
            </Typography>
          </Flex>

          <Flex
            sx={{
              marginTop: '24px',
              alignItems: 'center',
              width: '100%',
              gap: '4px',
            }}
          >
            <PeopleOutlineIcon fontSize={'small'} sx={{ color: GRAY_COLORS.GRAY_5 }} />
            <Box sx={{ width: '100%' }}>
              <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
                {`${i18n.t('profile.teams.message')}`}
              </Typography>
            </Box>
          </Flex>
          <Stack sx={{ gap: '16px', marginTop: '16px', width: '100%' }}>
            {userData.teams?.map((item, index) => (
              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }} key={`user-team-${index}`}>
                <Typography key={item?.id} variant="subtitle2" sx={{ fontWeight: 400 }}>
                  {item?.name}
                </Typography>
                <TeamUsersPictures users={item.users} />
              </Flex>
            ))}
          </Stack>
        </Card>
        {/* // ! Token Generate Code. */}
        <Card
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: '500px', maxHeight: '270px', overflowX: 'hidden' }}
        >
          <Typography variant={'h6'} sx={{ fontWeight: 700, marginBottom: '2px', marginRight: '12px' }}>
            Token name
          </Typography>
          <TextField
            id="token-name"
            required
            // aria-readonly
            value={tokenName}
            onChange={handleTokenNameChange}
            helperText={i18n.t('adminPortal.token.text.message')}
          />
          <InputLabel id="demo-simple-select-standard-label">Expiration</InputLabel>
          <Select
            label="Expiration"
            value={expiration}
            onChange={handleExpirationChange}
            defaultValue={30}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
          >
            <MenuItem value={7}>7 days</MenuItem>
            <MenuItem value={30}>30 days</MenuItem>
            <MenuItem value={60}>60 days</MenuItem>
            <MenuItem value={'Forever'}>Forever</MenuItem>
          </Select>
          <Typography variant="subtitle2" sx={{ fontWeight: 400, color: GRAY_COLORS.GRAY_8 }}>
            The token will expire on {expirationDate}
          </Typography>
          <Button
            disabled={isGenerateButtonDisabled}
            sx={{
              padding: '9px 16px !important',
              height: '40px !important',
              width: 'fit-content',
              fontWeight: 400,
            }}
            onClick={handleGenerateToken}
          >
            Generate new token
          </Button>
        </Card>
        {/* // ! Token Code. */}
        {tokenList.length > 0 && (
          <Card
            sx={{
              padding: '0px 40px',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '500px',
              width: '100%',
              maxHeight: '270px',
              overflowX: 'hidden',
              position: 'relative',
            }}
          >
            <FlexRowSpaceBetween
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: COLOR_WHITE,
                paddingTop: '24px',
              }}
            >
              <Typography variant={'h6'} sx={{ fontWeight: 700, marginBottom: '2px' }}>
                Tokens
              </Typography>
              <FilterComponent
                id={'top-prompts-filter'}
                setFilter={setSelectedFilter}
                value={selectedFilter}
                filterType="days"
              />
            </FlexRowSpaceBetween>
            {filteredData.length > 0 ? (
              filteredData.map((token, index) => (
                <React.Fragment key={index}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 400, color: GRAY_COLORS.GRAY_8 }}>
                    {token?.tokenName}
                  </Typography>
                  <TextField
                    className="form-control color-bg-inset text-mono text-small"
                    id="new-access-token"
                    type="text"
                    placeholder="Access token"
                    aria-label="Access token"
                    disabled={true}
                    value={token?.displayToken}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Copy token"
                            tabIndex="0"
                            role="button"
                            onClick={() => {
                              navigator.clipboard.writeText(token.displayToken);
                            }}
                          >
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 400,
                      color: GRAY_COLORS.GRAY_8,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Created By {token.userName}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 400,
                      color: GRAY_COLORS.GRAY_8,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {formatExpiryDate(token?.expiryAt)}
                    <DeleteIcon
                      sx={{ fontSize: '8', cursor: 'pointer' }}
                      onClick={() => handleDeleteToken(token?.id)}
                    />
                  </Typography>
                  <HorizontalDivider />
                </React.Fragment>
              ))
            ) : (
              <Typography
                variant="subtitle1"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
              >
                {i18n.t('NoToken.message')}
              </Typography>
            )}
          </Card>
        )}
      </CardsContainer>

      <EditProfileModal
        isOpen={editModalOpened}
        onClose={onCloseModal}
        teamOptions={teamOptions}
        teams={currentTeams}
        setTeams={setTeams}
      />

      <CopyRight />
      {openDeleteModal && (
        <GeneralModal
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onSave={async () => {
            await removeToken(tokenId);
            setOpenDeleteModal(false);
            addToast(i18n.t('adminPortal.token.delete.successful'), { appearance: 'success' });
            setTokenId('');
          }}
          title={'Delete Token'}
          type={'delete'}
        >
          <Typography variant={'body2'}>{i18n.t('deleteToken.message')}</Typography>
        </GeneralModal>
      )}
    </PageContainer>
  );
};

export default observer(ProfilePage);
