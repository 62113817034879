import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';

import { COLOR_BORDER_PRIMARY, COLOR_TABLE_HEADER, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import SortArrows from '../../assets/icons/sort-arrows.svg';

import FlexRowCenter from '../utils/flex/FlexRowCenter';
import { getIconByName, MoreIcon, TypeIcon } from './utils';
import { useStore } from '../../hooks/useStore';
import { File } from '../../models/File';

const Container = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${COLOR_BORDER_PRIMARY};
  margin-top: 16px;
`;

const FileRow = styled(Box)`
  background: ${COLOR_WHITE};
  padding: 13px 16px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${GRAY_COLORS.GRAY_2};

  &:hover {
    background: #edf3fe;
  }
`;

const HeaderContainer = styled.div`
  padding: 12px 16px;
  background: ${COLOR_TABLE_HEADER};
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
`;

const ListView = ({
  onPreviewFile,
  handleClick,
}: {
  onPreviewFile: (file: File) => void;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>, selectedFile: File) => void;
}) => {
  const {
    knowledgeBaseStore: { knowledgeBaseFiles },
  } = useStore();

  return (
    <Container>
      <HeaderContainer>
        <Typography variant={'subtitle1'} sx={{ color: GRAY_COLORS.GRAY_500, fontSize: '12px', fontWeight: '600' }}>
          NAME
        </Typography>
        <img src={SortArrows} alt={'sort-files-icon'} />
      </HeaderContainer>
      {knowledgeBaseFiles?.map(item => (
        <FileRow key={`folder-${item.id}`} onClick={() => onPreviewFile(item)}>
          <FlexRowCenter>
            <TypeIcon src={getIconByName(item.name)} alt={item.name} />
            {item.name}
          </FlexRowCenter>
          <IconButton
            onClick={e => handleClick(e, item)}
            sx={{
              width: '20px',
              height: '20px',
              '&:hover': {
                backgroundColor: 'inherit',
              },
            }}
          >
            <MoreIcon sx={{ width: '20px', height: '20px' }} />
          </IconButton>
        </FileRow>
      ))}
    </Container>
  );
};

export default observer(ListView);
