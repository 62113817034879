import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import TableRow from '@mui/material/TableRow';

import { COLOR_BORDER_QUARTERNARY, COLOR_TABLE_HEADER } from '../constants/colors';

interface DragItemProps {
  index: number;
  id: string;
  type: string;
}

interface DragAndDropProps {
  index: number;
  children: React.ReactNode;
  moveElement?: (dragIndex: number, hoverIndex: number) => void;
  isTableRow?: boolean;
  onClick?: () => void;
  selectedRowIndex?: number | null;
  disabled?: boolean;
}

const DragAndDropWrapper = ({
  index,
  moveElement,
  children,
  isTableRow,
  selectedRowIndex,
  onClick,
  disabled = false,
}: DragAndDropProps) => {
  const ref = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop<DragItemProps, void, { handlerId: string | symbol | null }>({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItemProps, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveElement?.(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !disabled,
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  if (isTableRow) {
    return (
      <TableRow
        ref={disabled ? undefined : ref}
        onClick={() => onClick?.()}
        sx={{
          opacity,
          backgroundColor:
            selectedRowIndex === index ? COLOR_BORDER_QUARTERNARY : index % 2 ? COLOR_TABLE_HEADER : 'null',

          '&:hover': {
            backgroundColor: COLOR_BORDER_QUARTERNARY,
          },
        }}
      >
        {children}
      </TableRow>
    );
  }

  return (
    <Box ref={disabled ? undefined : ref} sx={{ opacity }} data-handler-id={handlerId}>
      {children}
    </Box>
  );
};

export default DragAndDropWrapper;
