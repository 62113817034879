import { ApiConfig } from './ApiConfig';
import { OrganizationModel } from '../models/OrganizationModel';

export class ModelApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async findAll(): Promise<OrganizationModel[]> {
    return await this.apiConfig.sendRequest('GET', '/organization-models');
  }

  async findAllAccessibleByUser(): Promise<OrganizationModel[]> {
    return await this.apiConfig.sendRequest('GET', '/organization-models/accessible');
  }

  async updateAll(models: Partial<OrganizationModel>[]) {
    return await this.apiConfig.sendRequest('PATCH', '/organization-models/all', { models });
  }

  async updateOne(model: Partial<OrganizationModel>) {
    return await this.apiConfig.sendRequest('PATCH', `/organization-models`, {
      id: model.id,
      isActive: model.isActive,
      isDefault: model.isDefault,
      isDefaultFileModel: model.isDefaultFileModel,
      displayName: model.displayName,
      description: model.description,
      config: model.config,
      logoPicture: model.logoPicture,
      logoPictureFileKey: model.logoPictureFileKey,
      logoPictureUrlExpiration: model.logoPictureUrlExpiration,
    });
  }

  async deleteOne(modelId: string) {
    return await this.apiConfig.sendRequest('DELETE', `/organization-models/${modelId}`);
  }

  async findSupported() {
    return await this.apiConfig.sendRequest('POST', '/organizations-models/supportedModels');
  }

  async findOne(id: string): Promise<OrganizationModel> {
    return await this.apiConfig.sendRequest('POST', `/organization-models/${id}`);
  }
}
