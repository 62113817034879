import React from 'react';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

interface badgeProps {
  number: number;
}
const CustomBadge = ({number} :badgeProps) => {
  return (
    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: '50%',
          bgcolor: '#F46161',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontSize: '1rem',
          padding: '8px',
        }}
      >
        {number}
      </Box>
    </Badge>
  );
};

export default CustomBadge;
