import React from 'react';
import { Modal, Stack, SxProps } from '@mui/material';

import { COLOR_WHITE } from '../../constants/colors';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sx?: SxProps;
  isMobile?: boolean;
  closeOnBackdropClick?: boolean;
}

const CustomModal = ({ isOpen, onClose, children, sx, isMobile, closeOnBackdropClick }: ModalProps) => {
  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason && reason === 'backdropClick' && !closeOnBackdropClick) return; // To close modal while click outside
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          background: COLOR_WHITE,
          padding: '32px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.25)',
          borderRadius: isMobile ? '0' : '16px',
          ...sx,
        }}
      >
        {children}
      </Stack>
    </Modal>
  );
};

export default CustomModal;
