import { ApiConfig } from './ApiConfig';
import { CreateKnowledgeBaseDto } from '../models/KnowledgeBaseModel';

export class KnowledgeBaseApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  async getAllKnowledgeBasesByOrganization() {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base`);
  }

  async getKnowledgeBaseByOrganization() {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/organization`);
  }

  async getKnowledgeBaseByTeam(teamId: string) {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/team/${teamId}`);
  }

  async createKnowledgeBase(body: CreateKnowledgeBaseDto) {
    return await this.apiConfig.sendRequest('POST', '/knowledge-base', body);
  }

  async deleteFile(fileId: string, knowledgeBaseId: string) {
    return await this.apiConfig.sendRequest('DELETE', `/knowledge-base/${knowledgeBaseId}`, { fileId });
  }

  async getById(id: string) {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/byId/${id}`);
  }

  async getByTeamId(id: string) {
    return await this.apiConfig.sendRequest('GET', `/knowledge-base/byTeamId/${id}`);
  }
}
