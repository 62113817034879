import React from 'react';
import { SxProps } from '@mui/material';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';

import { COLOR_PRIMARY, COLOR_QUATERNARY } from '../../constants/colors';
import { ItemsListView } from '../../constants/itemsListView';

import FlexRowCenter from '../utils/flex/FlexRowCenter';
import Flex from '../utils/flex/Flex';

interface ViewModeButtonsProps {
  currentSelection: string;
  changeValue: (newSelection: string) => void;
}

export const IconButtonWrapper = ({
  children,
  active,
  onClick,
  sx,
}: {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
  sx?: SxProps;
}) => (
  <FlexRowCenter
    sx={{
      height: '100%',
      width: '100%',
      padding: '8px',
      backgroundColor: active ? '#EBF5FF' : 'transparent',
      borderRadius: active ? '6px' : '0',
      cursor: 'pointer',
      color: active ? COLOR_PRIMARY : COLOR_QUATERNARY,
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </FlexRowCenter>
);

export const ViewModeButtons = ({ currentSelection, changeValue }: ViewModeButtonsProps) => (
  <Flex
    sx={{
      height: '40px',
    }}
  >
    <IconButtonWrapper
      active={currentSelection === ItemsListView.CARDS}
      onClick={() => changeValue(ItemsListView.CARDS)}
    >
      <ViewModuleOutlinedIcon />
    </IconButtonWrapper>
    <IconButtonWrapper
      active={currentSelection === ItemsListView.TABLE}
      onClick={() => changeValue(ItemsListView.TABLE)}
    >
      <ReorderOutlinedIcon />
    </IconButtonWrapper>
  </Flex>
);
