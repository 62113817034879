import React, { useState, useEffect, ChangeEvent } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormHelperText, SxProps, TextField, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { observer } from 'mobx-react';

import { COLOR_BUTTON_CONTAINED_ERROR, COLOR_UTILS_TERTIARY, GRAY_COLORS, COLOR_PRIMARY } from '../../constants/colors';

import { DropdownOption } from '../../models/DropdownOption';
import { useStore } from '../../hooks/useStore';

interface SelectProps {
  label?: string;
  value: any;
  onChange: (event: SelectChangeEvent) => void;
  onBlur?: () => void;
  onClearFilters: () => void;
  options: DropdownOption[];
  sx?: SxProps;
  menuSX?: SxProps;
  multiple?: boolean;
  placeholder?: string;
  backgroundOnActive?: boolean;
  error?: string;
  renderValue?: any;
  icon?: React.ElementType;
  startAdornment?: React.ReactNode;
}

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  className: 'disable-click-away',
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      border: 'none',
    },
  },
};

const SelectWithSearch = ({
  value,
  placeholder,
  onChange,
  label,
  options,
  multiple,
  error,
  sx,
  menuSX,
  icon,
  backgroundOnActive,
  onBlur,
  onClearFilters,
  startAdornment,
}: SelectProps) => {
  const labelId = `${label}-label`;

  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  const [unselectedOptions, setUnselectedOptions] = useState<DropdownOption[]>(options);
  const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOpened, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const unselectedOptions = options?.filter(option => value.indexOf(option.name) === -1);
    const selectedOptions = options?.filter(option => (value.indexOf(option.name) || 0) > -1);
    setUnselectedOptions(unselectedOptions);
    setSelectedOptions(selectedOptions);
  }, [value?.length, options]);

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const filteredUnselected = options?.filter(
      item => value.indexOf(item.name) === -1 && item?.name?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const filteredSelected = options?.filter(
      item =>
        (value.indexOf(item.name) || 0) > -1 && item?.name?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUnselectedOptions(filteredUnselected);
    setSelectedOptions(filteredSelected);
    setSearchValue(event.target.value);
  };

  const onSelectOpen = () => {
    setIsOpen(true);
  };
  const onSelectClose = () => {
    setIsOpen(false);
  };

  let currentColor = value?.length ? GRAY_COLORS.GRAY_9 : GRAY_COLORS.GRAY_5;
  let currentBackground = value?.length ? COLOR_UTILS_TERTIARY : GRAY_COLORS.GRAY_100;
  if (backgroundOnActive && isOpened) {
    currentColor = COLOR_PRIMARY;
    currentBackground = COLOR_UTILS_TERTIARY;
  } else if (backgroundOnActive) {
    currentBackground = 'transparent';
  }

  // @ts-ignore
  return (
    <FormControl sx={sx}>
      {label && (
        <InputLabel id={labelId} shrink={true} sx={{ backgroundColor: 'transparent', '&': { top: '0px' } }}>
          {label}
        </InputLabel>
      )}

      <Select
        SelectDisplayProps={{ className: 'disable-click-away' }}
        notched={true}
        label={label}
        labelId={labelId}
        onChange={onChange}
        multiple={multiple}
        displayEmpty={true}
        value={value}
        onOpen={onSelectOpen}
        onClose={onSelectClose}
        IconComponent={icon}
        onBlur={onBlur}
        renderValue={() => {
          if (multiple && Array.isArray(value)) {
            if (value.length === 0) {
              return placeholder || '';
            }

            return value.join(', ');
          }

          if (!value) {
            return placeholder || '';
          }

          return value;
        }}
        MenuProps={MenuProps}
        sx={{
          borderRadius: '8px',
          '& fieldset': {
            border: `1px solid ${currentBackground} !important`,
          },
          fontStyle: value?.length ? 'normal' : 'italic',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '22px',
          width: '180px',
          color: currentColor,
          background: currentBackground,
          ...menuSX,
        }}
      >
        <MenuItem
          onKeyDown={e => e.stopPropagation()}
          sx={{
            padding: 0,
            width: '100%',
          }}
        >
          <TextField
            value={searchValue}
            onChange={onInputChange}
            variant={'outlined'}
            label={'Search...'}
            sx={{
              width: '100%',
              fontSize: '12px',
              borderRadius: 0,
              '& fieldset': { borderColor: '#fff !important', width: '100%', padding: 0, borderRadius: 0 },
            }}
            InputProps={{
              endAdornment: <Search sx={{ color: GRAY_COLORS.GRAY_500, width: '16px', height: '16px' }} />,
            }}
          />
        </MenuItem>

        {selectedOptions?.length && (
          <li onClick={onClearFilters}>
            <Typography
              onClick={onClearFilters}
              variant={'subtitle2'}
              sx={{
                lineHeight: '40px',
                height: '40px',
                paddingLeft: '18px',
                '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
              }}
            >{`${i18n.t('labels.clearAll')}`}</Typography>
          </li>
        )}
        {selectedOptions?.map((option, index) => {
          return (
            <MenuItem
              key={`selected-checkbox-option-${index}`}
              value={option.value}
              sx={{ height: '40px', paddingLeft: '8px' }}
              className={'disable-click-away'}
            >
              <Checkbox
                checked={(value.indexOf(option.name) || 0) > -1}
                sx={{ '& svg': { fill: 'rgba(108, 43, 217, 1)' } }}
              />
              <ListItemText primary={option.name} />
            </MenuItem>
          );
        })}

        {selectedOptions?.length && (
          <Typography variant={'subtitle2'} sx={{ lineHeight: '40px', height: '40px', paddingLeft: '18px' }}>{`${i18n.t(
            'labels.all'
          )}`}</Typography>
        )}
        {unselectedOptions.map((option, index) => {
          return (
            <MenuItem
              key={`unselected-checkbox-option-${index}`}
              value={option.value}
              sx={{ height: '40px', paddingLeft: '8px' }}
              className={'disable-click-away'}
            >
              <Checkbox checked={(value.indexOf(option.name) || 0) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText sx={{ marginLeft: 0, color: COLOR_BUTTON_CONTAINED_ERROR }}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default observer(SelectWithSearch);
