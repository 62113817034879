import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { GRAY_COLORS } from '../../../../../constants/colors';

import Flex from '../../../../utils/flex/Flex';
import { OrganizationModel } from '../../../../../models/OrganizationModel';
import ModelLogoPicture from '../../../../tableRows/components/ModelLogoPicture';

interface TeamModelRowProps {
  teamModel: OrganizationModel;
}

// component to show models list in teams page
const TeamModelRow = ({ teamModel }: TeamModelRowProps) => {
  const { displayName, providerName } = teamModel;

  return (
    <Flex sx={{ width: '100%', gap: '16px', marginTop: '24px' }}>
      <ModelLogoPicture model={teamModel} />
      <Stack>
        <Typography variant={'body2'}>{displayName}</Typography>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_500 }}>
          {providerName}
        </Typography>
      </Stack>
    </Flex>
  );
};

export default TeamModelRow;
