import { User } from './User';
import { Prompt } from './Prompt';
import { OrganizationModel } from './OrganizationModel';
import { KnowledgeBaseModel } from './KnowledgeBaseModel';

export interface Team {
  id: string;
  name: string;
  totalMembers?: number;
  totalPrompts?: number;
  totalChatViolations?: number;
  totalChats?: number;
  users: User[];
  conversations: Prompt[];
  createdBy?: string;
  createdAt: Date;
  organizationModels?: OrganizationModel[];
  knowledgeBases?: KnowledgeBaseModel[];
}

export const INITIAL_TEAM: Team = {
  id: '',
  name: '',
  totalMembers: 0,
  totalPrompts: 0,
  totalChatViolations: 0,
  totalChats: 0,
  users: [],
  conversations: [],
  createdAt: new Date(),
  organizationModels: [],
};

export interface CreateTeamUserDto {
  id: string;
}

export interface CreateTeamOrganizationModelDto {
  id: string;
}

export interface CreateTeamKnowledgeBaseModelDto {
  id: string;
}

export interface CreateTeamDto {
  name: string;
  users: CreateTeamUserDto[];
  organizationModels: CreateTeamOrganizationModelDto[];
  knowledgeBases: CreateTeamKnowledgeBaseModelDto[];
}
