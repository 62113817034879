import { observer } from 'mobx-react';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import { AuditLog } from '../../../models/AuditLog';
import Flex from '../../utils/flex/Flex';
import ConversationChat from '../../../pages/ConversationPage/components/ConversationChat/ConversationChat';
import OpenAIDetailsSection from '../../../pages/ConversationPage/components/OpenAIDetailsSection';

interface ViewResponseModalProps {
  id: string | null;
  closeModal: () => void;
}

const getInfoDescription = (currentAuditLog: AuditLog) => {
  if (currentAuditLog?.action === 'hallucination') {
    return `User ${currentAuditLog.userName} reacted with ${currentAuditLog.action} on a response on ${moment
      .tz(currentAuditLog?.createdAt, 'EST')
      .format('MMM DD, YYYY')}`;
  }
  return `User ${currentAuditLog.userName} ${currentAuditLog.action}d a response on ${moment
    .tz(currentAuditLog?.createdAt, 'EST')
    .format('MMM DD, YYYY')}`;
};

const FeedbackDetailPage = ({ id, closeModal }: ViewResponseModalProps) => {
  const {
    conversationStore: {
      getConversationById,
      setEmptyConversation,
      conversationSettingsState,
      currentConversation,
      secondaryConversationSettingsState,
      isSecondaryModelSelected,
    },
    auditLogsStore: { currentAuditLog },
  } = useStore();
  const { model, accuracy, length } = conversationSettingsState;
  const { chats } = currentConversation;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      setLoading(true);
      await getConversationById(id);
      setLoading(false);
    })();

    return () => {
      setEmptyConversation();
    };
  }, [id]);

  useEffect(() => {
    return () => {
      setEmptyConversation();
    };
  }, []);

  return (
    <Stack sx={{ padding: '24px', display: 'flex', height: '100%' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          backgroundColor: COLOR_WHITE,
          height: '100%',
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700, textTransform: 'capitalize' }}>
            {`${currentAuditLog.entityType} ${
              currentAuditLog.action === 'hallucination' ? currentAuditLog.action : `${currentAuditLog.action}d`
            }`}
          </Typography>
          <IconButton size={'small'} onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>

        <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
          {getInfoDescription(currentAuditLog)}
        </Typography>
        <Box sx={{ maxWidth: id ? '80%' : '100%', display: 'flex', alignItems: 'center', marginTop: '16px' }}>
          <OpenAIDetailsSection
            model={model}
            accuracy={accuracy}
            length={length}
            lightBackground
            renderButton={!id && !chats?.length && !isSecondaryModelSelected}
          />

          {isSecondaryModelSelected && (
            <>
              <Typography variant={'subtitle2'} sx={{ margin: '0 8px' }}>
                or
              </Typography>
              <OpenAIDetailsSection
                model={secondaryConversationSettingsState.model}
                accuracy={secondaryConversationSettingsState.accuracy}
                length={secondaryConversationSettingsState.length}
                lightBackground
                renderButton={true}
              />
            </>
          )}
        </Box>
        <ConversationChat loading={loading} lastMessageId={currentAuditLog?.additionalInformation?.messageId} />
      </Flex>
    </Stack>
  );
};

export default observer(FeedbackDetailPage);
