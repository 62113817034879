import CloseIcon from '@mui/icons-material/Close';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { COLOR_MANDATORY, COLOR_PRIMARY, COLOR_TEXT_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import { Team } from '../../models/Team';
import { getDocumentsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import Button from '../buttons/Button';
import AddPromptModalAutocomplete from '../modal/AddPromptModal/components/AddPromptModalAutocomplete';
import { StyledContainer } from '../modal/CropImageModal';
import CustomModal from '../modal/CustomModal';
import Flex from '../utils/flex/Flex';
import FlexColumnStart from '../utils/flex/FlexColumnStart';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  setFiles: (arr: any[]) => void;
}

const MyInput = styled.input`
  width: 110px;
  height: 40px;
  right: 33px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const MyLabel = styled.label`
  cursor: pointer;
`;

const ErrorsContainer = styled.div`
  background: rgba(200, 30, 30, 0.15);
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 16px;
`;

const optionStyle = {
  borderRadius: '6px',
  border: '1px solid #E0E2E9',
  padding: '2px 12px 2px 0',
};
const UploadFileModal = ({ isOpen, onClose, setFiles }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { createKnowledgeBase, setIsUploadInProgress, setUploadProgress, cancelFileUpload },
    userStore: { userData },
    fileStore: { createFile },
    appState: { s3DocumentsApi, fileApi },
    teamStore: { teams, getTeamsByUserOrganization },
  } = useStore();
  const inputRef = useRef<HTMLInputElement>(null);

  const [groupOption, setGroupOption] = useState('organization');
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (teams.length === 0) {
        await getTeamsByUserOrganization();
      }
    })();
  }, []);

  const onUploadDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const fileToUpload = files && files.length > 0 && files[0];
    setIsUploadInProgress(true);

    if (fileToUpload) {
      try {
        setFiles([fileToUpload]);
        onClose();
        const fileKey = getDocumentsKey(userData.id, fileToUpload.name);

        const createdFileEntry = await createFile({ key: fileKey, name: fileToUpload.name });
        if (groupOption === 'organization') {
          await createKnowledgeBase({
            name: 'Organization',
            isOrganization: true,
            files: [{ id: createdFileEntry.id, name: createdFileEntry.name }],
          });
        } else if (groupOption === 'team' && selectedTeams.length) {
          await Promise.all(
            selectedTeams.map(async (team: Team) => {
              await createKnowledgeBase({
                name: team.name,
                teamId: team.id,
                isOrganization: false,
                files: [{ id: createdFileEntry.id, name: createdFileEntry.name }],
              });
            })
          );
        }

        setUploadProgress(0);

        if (process.env.REACT_APP_USE_DOCUMENT_ENCRYPTION === 'true') {
          const status = await s3DocumentsApi.uploadDocuments(fileToUpload, createdFileEntry.id, {
            setProgress: setUploadProgress,
          });

          if (status === 201) {
            setIsUploadInProgress(false);
            // fileApi.startProcessing(kowledgeBase.id, createdFileEntry.id);
          }

          return;
        }

        const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(fileKey, fileToUpload.type);
        const status = await uploadDocumentSecurely(preSignedPutUrl, fileToUpload, { setProgress: setUploadProgress });

        if (status === 200) {
          setIsUploadInProgress(false);
          // fileApi.startProcessing(kowledgeBase.id, createdFileEntry.id);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Upload canceled');
          await cancelFileUpload();
        } else {
          console.error('Error during upload:', error);
        }
      }
    }
  };

  const handleChangeRadioValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGroupOption(value);
    if (value === 'team' && selectedTeams.length === 0) {
      setError('Please select at least one team');
    } else {
      setError('');
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ width: '500px', padding: '24px' }}>
      <StyledContainer>
        <FlexRowSpaceBetween sx={{ width: '100%' }}>
          <Typography variant={'subtitle1'} fontWeight={'700'}>
            {i18n.t('knowledgeBase.uploadDestination.title')}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexRowSpaceBetween>

        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_700 }}>
          {i18n.t('knowledgeBase.uploadDestination.message')}
        </Typography>

        <Box sx={{ margin: '24px 0', width: '100%' }}>
          <Typography variant={'body2'} fontWeight={'600'}>{`${i18n.t(
            'sidebarMenu.knowledgeBase.action'
          )}`}</Typography>

          <RadioGroup
            onChange={handleChangeRadioValue}
            aria-labelledby={'groupOption'}
            name={'groupOption'}
            key={'groupOption'}
            sx={{ marginLeft: '10px', marginTop: '8px' }}
            value={groupOption}
          >
            <Flex sx={{ gap: '8px' }}>
              <FormControlLabel
                value={'organization'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'Organization'}
                checked={groupOption === 'organization'}
                sx={optionStyle}
              />
              <FormControlLabel
                value={'team'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'Team(s)'}
                checked={groupOption === 'team'}
                sx={optionStyle}
              />
            </Flex>
          </RadioGroup>

          {groupOption === 'team' && (
            <FlexColumnStart sx={{ marginTop: '16px', gap: '12px' }}>
              <Typography variant={'body2'} fontWeight={'600'}>
                Team(s)
              </Typography>
              <AddPromptModalAutocomplete
                key={'teams-autocomplete'}
                label={i18n.t('labels.teams')}
                options={teams}
                value={selectedTeams}
                setValue={value => {
                  setSelectedTeams(value);
                  setError('');
                }}
              />
            </FlexColumnStart>
          )}
          {error && (
            <ErrorsContainer>
              <Typography variant="subtitle2" sx={{ color: COLOR_MANDATORY }}>
                {error}
              </Typography>
            </ErrorsContainer>
          )}
        </Box>

        <Flex sx={{ justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
          <Button
            onClick={onClose}
            variant={'outlined'}
            sx={{
              border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
              color: `${COLOR_TEXT_PRIMARY} !important`,
              width: 'fit-content',
              padding: '9px 16px !important',
            }}
          >
            {i18n.t('common.cancel.action')}
          </Button>
          <>
            <MyLabel htmlFor={error ? '' : 'upload-knowledge-base-document'}>
              <Flex
                sx={{
                  alignItems: 'center',
                  gap: '8px',
                  background: COLOR_PRIMARY,
                  padding: '10px 16px',
                  borderRadius: '6px',
                }}
              >
                <Typography variant={'body2'} color={COLOR_WHITE} fontWeight={'600'} whiteSpace={'nowrap'}>
                  {i18n.t('common.upload.action')}
                </Typography>
              </Flex>
            </MyLabel>
            <MyInput
              type="file"
              ref={error ? null : inputRef}
              id={'upload-knowledge-base-document'}
              name={'upload-knowledge-base-document'}
              onChange={onUploadDocument}
              onClick={(event: any) => {
                event.target.value = null;
              }}
              accept={
                'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .md, .epub, ' +
                '.csv, application/JSON, text/plain, application/vnd.ms-excel, ' +
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }
            />
          </>
        </Flex>
      </StyledContainer>
    </CustomModal>
  );
};

export default observer(UploadFileModal);
