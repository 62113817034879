import Close from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GroupsIcon from '@mui/icons-material/Groups';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { i18n } from 'i18next';
import * as moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';

import {
  COLOR_LINK,
  COLOR_PRIMARY,
  COLOR_UTILS_TERTIARY,
  COLOR_WHITE,
  GRAY_COLORS,
} from '../../../../constants/colors';

import { useStore } from '../../../../hooks/useStore';
import { EMPTY_USER, User } from '../../../../models/User';
import Button from '../../../buttons/Button';
import WarningModal from '../../../modal/WarningModal';
import Flex from '../../../utils/flex/Flex';
import FlexRowStart from '../../../utils/flex/FlexRowStart';
import IconByAction from '../../auditLogs/IconByAction';
import CreateTeamModal from '../CreateTeamModal';
import TeamStatisticsItem from './teamStatistics/TeamStatisticsItem';
import TeamUserRow from './teamUserRow/TeamUserRow';
import { Paths } from '../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import TeamModelRow from './teamModelRow/TeamModelRow';
import { Team } from '../../../../models/Team';
import { LocalStorageKeys } from '../../../../constants/localStorageKeys';

interface TeamMemberProps {
  i18n: i18n;
  users: User[];
  setSelectedUser?: (a: User) => void;
  setIsRemoveUserFromTeamModalOpen?: (a: boolean) => void;
  PageName?: string;
}

const TeamMembers = ({ i18n, users, setSelectedUser, setIsRemoveUserFromTeamModalOpen, PageName }: TeamMemberProps) => {
  const {
    teamStore: { selectedTeam },
  } = useStore();
  const navigate = useNavigate();

  let onRemoveClick: any = undefined;
  if (setSelectedUser && setIsRemoveUserFromTeamModalOpen) {
    onRemoveClick = (user: User) => {
      setSelectedUser?.(user);
      setIsRemoveUserFromTeamModalOpen?.(true);
    };
  }

  // To navigate to governance tab
  const onGovernanceNavigate = (team: Team) => {
    localStorage.setItem(LocalStorageKeys.governanceTeamName, team.name);
  };

  const navigateToGovernance = () => {
    localStorage.setItem('selectedItem', `${i18n.t('adminPortal.tabs.governance.action')}`);
    navigate(`${Paths.ADMIN_PORTAL}/governance`);
  };

  // edit models tooltip in governance tab
  const editTooltipContent = (
    <div
      className={'tooltip-text'}
      onClick={() => {
        onGovernanceNavigate && onGovernanceNavigate(selectedTeam);
      }}
    >
      {i18n.t('teamsList.details.tooltip')} <br />
      <a onClick={navigateToGovernance}>{i18n.t('adminPortal.tabs.governance.action')}</a>
    </div>
  );

  return (
    <>
      <Box>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '16px' }}>
          {i18n.t('teamList.details.members')}
        </Typography>
        <Box sx={{ maxHeight: '150px', overflowY: 'auto', marginTop: '1rem' }}>
          {users?.map(user => (
            <TeamUserRow key={`team-user-${user.id}`} teamUser={user} onRemoveClick={onRemoveClick} />
          ))}
        </Box>
      </Box>
      {PageName === PagesEnum.Teams && (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '32px', justifyContent: 'space-between' }}>
            <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500 }}>
              {i18n.t('teamList.details.models')}
            </Typography>
            <BootstrapTooltip title={editTooltipContent}>
              <IconContainer>
                <EditOutlinedIcon sx={{ fill: COLOR_PRIMARY }} />
              </IconContainer>
            </BootstrapTooltip>
          </Box>
          <Box sx={{ maxHeight: '150px', overflowY: 'auto', marginTop: '1rem' }}>
            {selectedTeam.organizationModels?.map(model => (
              <TeamModelRow key={`team-user-${model.id}`} teamModel={model} />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  '&': {
    cursor: 'pointer',
  },
  '& a': {
    color: COLOR_LINK,
  },
  '& .tooltip-text': {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '16px',
    fontFamily: 'Poppins, serif',
    color: COLOR_WHITE,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '8px',
  },
}));

enum PagesEnum {
  Teams = 'teams',
  Governance = 'governance',
}

const TeamDetailsCard = ({
  onTeamAuditLogNavigate,
  PageName,
}: {
  onTeamAuditLogNavigate?: Function;
  PageName?: string;
}) => {
  const {
    teamStore: { selectedTeam, clearSelectedTeam, updateTeam, getTeamById, removeTeam },
    localizationStore: { i18next: i18n },
    auditLogsStore: { currentAuditLog },
  } = useStore();
  const {
    createdAt,
    name,
    createdBy,
    users,
    id,
    totalChatViolations,
    totalChats,
    totalPrompts,
    organizationModels,
    totalMembers,
  } = selectedTeam;

  const [selectedUser, setSelectedUser] = useState(EMPTY_USER);
  const [isRemoveUserFromTeamModalOpen, setIsRemoveUserFromTeamModalOpen] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (currentAuditLog?.entityId) {
        await getTeamById(currentAuditLog.entityId);
      }
    })();
  }, [currentAuditLog?.entityId]);

  const handleRemoveUser = async () => {
    try {
      // to remove member from team
      await updateTeam(id, {
        users: users.filter(user => user.id !== selectedUser.id).map(user => ({ id: user.id })),
        organizationModels: organizationModels?.map(model => ({
          id: model.id,
        })),
      });
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setIsRemoveUserFromTeamModalOpen(false);
    }
  };

  const handleRemoveTeam = async () => {
    try {
      await removeTeam(id);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setIsDeleteTeamModalOpen(false);
    }
  };
  const navigateToAuditLog = () => {
    localStorage.setItem('selectedItem', `${i18n.t('adminPortal.tabs.logs.action')}`);
    navigate(`${Paths.ADMIN_PORTAL}/audit-log`);
  };

  if (currentAuditLog?.entityId) {
    return (
      <Stack sx={{ padding: '24px' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {name}
          </Typography>
          <IconButton size={'small'} onClick={clearSelectedTeam}>
            <Close />
          </IconButton>
        </Box>
        {currentAuditLog?.action && (
          <FlexRowStart sx={{ gap: '12px', marginBottom: '24px' }}>
            <IconByAction action={currentAuditLog?.action} />
            <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
              {`This chat was ${currentAuditLog?.action}d by ${currentAuditLog.userName} on ${moment
                .tz(currentAuditLog?.createdAt, 'EST')
                .format('MMM DD, YYYY')}`}
            </Typography>
          </FlexRowStart>
        )}
        <TeamMembers i18n={i18n} users={users} PageName={PageName} />
      </Stack>
    );
  }

  const tooltipContent = (
    <div
      className={'tooltip-text'}
      onClick={() => {
        onTeamAuditLogNavigate && onTeamAuditLogNavigate(selectedTeam);
      }}
    >
      {i18n.t('teamsList.details.tooltip')} <br />
      <a onClick={navigateToAuditLog}>{i18n.t('adminPortal.tabs.logs.action')}</a>
    </div>
  );

  return (
    <Stack sx={{ padding: '24px' }}>
      <Flex sx={{ width: '100%', gap: '16px' }}>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
          {i18n.t('teamList.details.creator')}: {createdBy}
        </Typography>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
          {i18n.t('teamList.details.date')}: {new Date(createdAt).toDateString()}
        </Typography>
        <IconButton onClick={clearSelectedTeam} size={'small'} sx={{ marginLeft: 'auto' }}>
          <Close fontSize={'small'} />
        </IconButton>
      </Flex>
      <Typography variant={'h5'} sx={{ fontSize: '18px', fontWeight: 600, marginTop: '16px' }}>
        {name}
      </Typography>
      <Flex sx={{ gap: '16px', marginTop: '8px' }}>
        {PageName === PagesEnum.Teams && (
          <Button
            variant={'outlined'}
            startIcon={<DeleteOutlineIcon />}
            onClick={() => setIsDeleteTeamModalOpen(true)}
            sx={{
              width: 'fit-content',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${GRAY_COLORS.GRAY_9} !important`,
            }}
            id="Delete-Team"
          >
            {i18n.t('teamsList.button.delete')}
          </Button>
        )}
        <Button
          variant={'outlined'}
          startIcon={<EditOutlinedIcon />}
          sx={{
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
          }}
          id="Edit-Team"
          onClick={() => setIsEditTeamModalOpen(true)}
        >
          {PageName === PagesEnum.Teams ? i18n.t('teamsList.button.edit') : i18n.t('teamsList.card.manage')}
        </Button>
      </Flex>
      <Flex sx={{ alignItems: 'center', marginTop: '40px' }}>
        <div>
          <TeamStatisticsItem
            Icon={LightbulbIcon}
            title={i18n.t('teamList.details.prompts')}
            amount={totalPrompts || 0}
          />
        </div>
        <BootstrapTooltip title={tooltipContent}>
          <div>
            <TeamStatisticsItem
              Icon={QuestionAnswerIcon}
              title={i18n.t('teamList.details.chats')}
              amount={totalChats || 0}
            />
          </div>
        </BootstrapTooltip>
        {PageName === PagesEnum.Teams ? (
          <div>
            <TeamStatisticsItem
              Icon={GroupsIcon}
              title={i18n.t('teamList.details.totalMembers')}
              amount={totalMembers || 0}
            />
          </div>
        ) : (
          <BootstrapTooltip title={tooltipContent}>
            <div>
              <TeamStatisticsItem
                Icon={ReportProblemIcon}
                title={i18n.t('teamList.details.violations')}
                amount={totalChatViolations || 0}
              />
            </div>
          </BootstrapTooltip>
        )}
      </Flex>
      <TeamMembers
        i18n={i18n}
        users={users}
        setSelectedUser={setSelectedUser}
        setIsRemoveUserFromTeamModalOpen={setIsRemoveUserFromTeamModalOpen}
        PageName={PageName}
      />
      <WarningModal
        isOpen={isRemoveUserFromTeamModalOpen}
        onClose={() => setIsRemoveUserFromTeamModalOpen(false)}
        heading={i18n.t('removeTeamMember.heading')}
        description={i18n.t('removeTeamMember.message', { fullName: selectedUser.fullName })}
        confirmButton={
          <Button
            onClick={handleRemoveUser}
            variant={'contained'}
            sx={{ width: 'fit-content', height: '40px !important' }}
          >
            {i18n.t('common.remove.action')}
          </Button>
        }
        icon={<NotificationImportantIcon sx={{ fill: GRAY_COLORS.GRAY_5 }} />}
      />
      <WarningModal
        isOpen={isDeleteTeamModalOpen}
        onClose={() => setIsDeleteTeamModalOpen(false)}
        heading={i18n.t('deleteTeam.heading')}
        description={i18n.t('deleteTeam.message', { fullName: selectedTeam.name })}
        confirmButton={
          <Button
            onClick={handleRemoveTeam}
            variant={'contained'}
            sx={{ width: 'fit-content', height: '40px !important' }}
            id="Delete-Btn"
          >
            {i18n.t('common.delete.action')}
          </Button>
        }
        icon={<DeleteOutlineIcon sx={{ fill: GRAY_COLORS.GRAY_5 }} />}
      />
      <CreateTeamModal
        isOpen={isEditTeamModalOpen}
        onClose={() => setIsEditTeamModalOpen(false)}
        isEdit
        team={selectedTeam}
        PageName={PageName}
      />
    </Stack>
  );
};

export default TeamDetailsCard;

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${COLOR_UTILS_TERTIARY};
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
