import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';

import GeneralModal from '../../modal/GeneralModal';
import { useStore } from '../../../hooks/useStore';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}
const DeleteModelModal = ({ isOpen, onClose, onSave }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={async () => {
        await onSave();
        onClose();
      }}
      title={i18n.t('adminPortal.models.delete.modal.title')}
      type={'delete'}
    >
      <Typography variant={'body2'}>{i18n.t('adminPortal.models.delete.modal.message')}</Typography>
    </GeneralModal>
  );
};

export default observer(DeleteModelModal);
