import { FileApi } from '../api/FileApi';
import { CreateFileDto, EMPTY_FILE, File } from '../models/File';
import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

export class FileStore {
  fileApi: FileApi;
  file: File = EMPTY_FILE;

  constructor(fileApi: FileApi) {
    this.fileApi = fileApi;

    makeAutoObservable(this);
    autoBind(this);
  }

  async createFile(createFileDto: CreateFileDto) {
    const file = await this.fileApi.create(createFileDto);

    console.log('file ------------------->> ', file);

    return file;
  }

  async getFileById(id: string) {
    const file = await this.fileApi.getById(id);

    runInAction(() => {
      this.file = file;
    });

    return file;
  }
}
