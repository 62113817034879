import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { COLOR_PRIMARY, COLOR_UTILS_TERTIARY, GRAY_COLORS } from '../../../../../constants/colors';

import Flex from '../../../../utils/flex/Flex';

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${COLOR_UTILS_TERTIARY};
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

interface TeamStatisticsItemProps {
  Icon: typeof SvgIcon;
  title: string;
  amount: number;
}

const TeamStatisticsItem = ({ Icon, title, amount }: TeamStatisticsItemProps) => {
  return (
    <Flex sx={{ padding: '8px' }}>
      <IconContainer>
        <Icon sx={{ fill: COLOR_PRIMARY }} />
      </IconContainer>
      <Stack sx={{ marginLeft: '16px' }}>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_500 }}>
          {title}
        </Typography>
        <Typography variant={'body1'} sx={{ fontWeight: 700 }}>
          {amount}
        </Typography>
      </Stack>
    </Flex>
  );
};

export default TeamStatisticsItem;
