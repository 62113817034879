import React from 'react';

import { Roles } from '../constants/userRoles';

import { AccessWrapper, AuthenticationWrapper, LoginWrapper } from './routesRestrictions';
import NavigationContainer from '../components/navigation/NavigationContainer';
import AdminPortalPage from '../pages/AdminPortalPage/AdminPortalPage';
import NavigationFilePreviewContainer from '../components/navigation/NavigationFilePreviewContainer';

export const authenticationAndSidebarWrapper = (Component: () => JSX.Element) => {
  return (
    <AuthenticationWrapper>
      <NavigationContainer>
        <Component />
      </NavigationContainer>
    </AuthenticationWrapper>
  );
};

export const authenticationAndSidebarFilePreviewWrapper = (Component: () => JSX.Element) => {
  return (
    <AuthenticationWrapper>
      <NavigationFilePreviewContainer>
        <Component />
      </NavigationFilePreviewContainer>
    </AuthenticationWrapper>
  );
};

export const loginWrapper = (Component: () => JSX.Element) => {
  return (
    <LoginWrapper>
      <Component />
    </LoginWrapper>
  );
};

export const RestrictedAdminsPage = () => {
  const roles = [Roles.admin, Roles.organizationAdmin];

  return (
    <AccessWrapper roles={roles}>
      {/* <AdminPortalPage /> */}
    </AccessWrapper>
  );
};


export const RestrictedAdminsPageComponent = (Component: () => JSX.Element) => {
  const roles = [Roles.admin, Roles.organizationAdmin];

  return (
    <AccessWrapper roles={roles}>
      <NavigationContainer>
        <Component />
      </NavigationContainer>
    </AccessWrapper>
  );
};
