import React from 'react';
import styled from 'styled-components';

const Tracker = styled.div<{ barColor: string }>`
  width: 100%;
  height: 16px;
  border-radius: 6px;
  background-color: ${props => props.barColor || '#4D4D4D'};
  margin: 0 16px;
  position: relative;
`;

const ProgressInTracker = styled.div<{ precentage?: number; progressColor?: string; firstColor?: boolean }>`
  height: 100%;
  width: ${props => props.precentage}%;
  background-color: ${props => props.progressColor || '#fea800'};
  border-radius: 2px;
  position: absolute;
  z-index: ${props => (props.firstColor ? '2' : '1')};
`;

interface ProgressProps {
  precentage: number;
  barColor: string;
  progressColor: string;
  progressColor2?: string;
  percentage2?: number;
}

const ProgressBar = ({ precentage, barColor, progressColor, percentage2, progressColor2 }: ProgressProps) => (
  <Tracker barColor={barColor}>
    <ProgressInTracker precentage={precentage} progressColor={progressColor} firstColor />
    {percentage2 && <ProgressInTracker precentage={percentage2} progressColor={progressColor2} />}
  </Tracker>
);

export default ProgressBar;
