import { gapi } from 'gapi-script';
import { googleDriveConfig } from '../../../config/addvaluemachine/config';

const updateSigninStatus = (isSignedIn: string) => {
  if (isSignedIn) {
    listFiles();
  } else {
    // prompt user to sign in
    handleAuthClick();
  }
};

const handleAuthClick = () => {
  gapi.auth2.getAuthInstance().signIn();
};

const listFiles = (searchTerm = null) => {
  gapi.client.drive.files
    .list({
      pageSize: 10,
      fields: 'nextPageToken, files(id, name, mimeType, modifiedTime, webContentLink)',
      q: searchTerm,
    })
    .then(function async(response: any) {
      const res = JSON.parse(response.body);
      console.log('res.files', res.files);
    });
};
const initClient = () => {
  // setIsLoadingGoogleDriveApi(true);
  gapi.client
    .init({
      apiKey: googleDriveConfig.developerKey,
      clientId: googleDriveConfig.clientId,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
    })
    .then(
      function () {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

        // Handle the initial sign-in state.
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      },
      function (error: any) {
        console.log('error', error);
      }
    );
};

export const handleGoogleDriveClientLoad = () => {
  gapi.load('client:auth2', initClient);
};
