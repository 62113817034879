import { Close, FolderOutlined, People } from '@mui/icons-material';
import { Autocomplete, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Joi from 'joi';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';
import { useStore } from '../../../hooks/useStore';
import { Team } from '../../../models/Team';
import { User } from '../../../models/User';

import Button from '../../buttons/Button';
import Divider from '../../Divider/Divider';
import TextInput from '../../inputs/TextInput';
import CustomModal from '../../modal/CustomModal';
import UserAutocomplete from '../../userAutocomplete/UserAutocomplete';
import Flex from '../../utils/flex/Flex';
import TeamUserRow from './teamCards/teamUserRow/TeamUserRow';
import { OrganizationModel } from '../../../models/OrganizationModel';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ModelLogoPicture from '../../tableRows/components/ModelLogoPicture';
import { KnowledgeBaseModel } from '../../../models/KnowledgeBaseModel';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  team?: Team;
  PageName?: string;
}

interface FormProps {
  name: string;
}
enum PagesEnum {
  Teams = 'teams',
  Governance = 'governance',
}

const CreateTeamModal = ({ isOpen, onClose, isEdit, team, PageName }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    teamStore: { createTeam, updateTeam },
    userStore: { users },
    modelStore: { organizationModels },
    knowledgeBaseStore: { knowledgeBases },
  } = useStore();

  const [formData, setFormData] = useState({
    name: isEdit ? team?.name || '' : '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
  });
  const [isDirtyState, setIsDirtyState] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setFormData({ name: '' });
      setListOfUsers([]);
      setListOfSelectedModels([]);
      return;
    }

    setFormData({ name: team?.name || '' });
    setListOfUsers(team?.users || []);
    setListOfSelectedModels(team?.organizationModels || []);
    setListOfSelectedKnowledgeBases(team?.knowledgeBases || []);

    return () => {
      setLoading(false);
      setIsDirtyState(false);
      setFormErrors({
        name: '',
      });
    };
  }, [team?.name, team?.users, isOpen]);

  const [listOfUsers, setListOfUsers] = useState<any[]>(isEdit ? team?.users || [] : []);
  const [listOfSelectedModels, setListOfSelectedModels] = useState<OrganizationModel[]>([]);
  const [listOfSelectedKnowledgeBases, setListOfSelectedKnowledgeBases] = useState<KnowledgeBaseModel[]>([]);

  const getSchema = (i18n: any) => {
    return Joi.object().keys({
      name: Joi.string().required().label(i18n.t('labels.name')),
    });
  };

  const generalValidation = (input: FormProps, name?: string): string | undefined | null | { [key: string]: any } => {
    const results = getSchema(i18n).validate(input, { abortEarly: false });

    if (results.error && name) {
      const error = results.error.details.find(obj => obj.path[0] === name);
      return error?.message;
    }

    if (results.error && !name) {
      let errorsObj: { [key: string]: any } | null = null;
      results.error.details.forEach(detail => {
        errorsObj = {
          ...(errorsObj || {}),
          [detail.path[0]]: detail.message,
        };
      });

      return errorsObj;
    }

    return null;
  };

  const onSave = async () => {
    const errors = generalValidation(formData);
    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }

    setLoading(true);

    let result;
    if (isEdit) {
      result = await updateTeam(team?.id, {
        name: formData.name,
        users: listOfUsers.map(user => ({
          id: user.id,
        })),
        organizationModels: listOfSelectedModels.map(model => ({
          id: model.id,
        })),
        knowledgeBases: listOfSelectedKnowledgeBases.map(knowledgeBase => ({
          id: knowledgeBase.id,
        })),
      });
    } else {
      result = await createTeam({
        name: formData.name,
        users: listOfUsers.map(user => ({
          id: user.id,
        })),
        organizationModels: listOfSelectedModels.map(model => ({
          id: model.id,
        })),
        knowledgeBases: listOfSelectedKnowledgeBases.map(knowledgeBase => ({
          id: knowledgeBase.id,
        })),
      });
    }

    setLoading(false);

    if (result?.errors) {
      setFormErrors({
        ...formErrors,
        ...result.errors,
      });

      return;
    }

    setIsDirtyState(false);
    onClose();
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsDirtyState(true);
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
  };

  const handleRemoveUser = (user: User) => {
    setIsDirtyState(true);
    setListOfUsers(prevState => prevState.filter(userItem => userItem.id !== user.id));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{
        width: '600px',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: '32px',
        maxHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <Stack width={'100%'} justifyContent={'flex-start'} gap={'4px'}>
        <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
            {PageName === PagesEnum.Governance
              ? i18n.t('labels.ManageTeam')
              : isEdit
                ? i18n.t('teamsList.button.edit')
                : i18n.t('teamsList.button.add')}
          </Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close fontSize={'small'} />
          </IconButton>
        </Flex>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_8 }}>
          {PageName === PagesEnum.Governance
            ? `You are now managing the "${team?.name} team."`
            : isEdit
              ? `You are now editing the "${team?.name} team."`
              : i18n.t('createTeam.create.infoMessage')}
        </Typography>
      </Stack>

      <Divider sx={{ marginTop: '24px', backgroundColor: GRAY_COLORS.GRAY_2 }} />

      <Flex sx={{ alignItems: 'center', gap: '8px', marginTop: '24px' }}>
        <FolderOutlined sx={{ fill: GRAY_COLORS.GRAY_5 }} />
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9 }}>
          {PageName === PagesEnum.Governance ? team?.name : i18n.t('teamsList.table.header.name')}
        </Typography>
      </Flex>
      {PageName !== PagesEnum.Governance && (
        <TextInput
          id="name"
          name={'name'}
          value={formData.name}
          error={i18n.t(formErrors.name)}
          onChange={onChange}
          variant={'outlined'}
          type={'fullName'}
          testId={'team-name'}
          sx={{ marginTop: '12px' }}
        />
      )}
      {/* // ! Models */}
      {PageName === PagesEnum.Governance && (
        <StyledAutocomplete
          disableClearable={true}
          multiple={true}
          sx={{ maxHeight: '150px', overflowY: 'auto' }} // for scrolling issue
          value={listOfSelectedModels}
          limitTags={1}
          id="Select-Model"
          getOptionLabel={(option: any) => `${option.providerName} - ${option.displayName}`}
          renderOption={(props, option: any) => (
            <li {...props} style={{ padding: '24px 12px' }}>
              <ModelLogoPicture model={option as OrganizationModel} />
              <Typography sx={{ marginLeft: '16px' }}>
                {option.providerName} - {option.displayName}
              </Typography>
            </li>
          )}
          renderInput={({ inputProps, ...rest }) => {
            return (
              <AutoCompleteTextField
                {...rest}
                inputProps={{ ...inputProps }}
                placeholder={i18n.t('createTeam.selectModels.placeholder')}
              />
            );
          }}
          options={organizationModels.filter(model => !!model.isActive)}
          onChange={(event, value) => {
            setListOfSelectedModels((value as OrganizationModel[]) || []);
            setIsDirtyState(true);
          }}
        />
      )}
      {/* // ! Knowledge base */}
      {PageName === PagesEnum.Governance && (
        <StyledAutocomplete
          disableClearable={true}
          multiple={true}
          value={listOfSelectedKnowledgeBases}
          sx={{ maxHeight: '150px', overflowY: 'auto' }} // for scrolling issue
          limitTags={1}
          id="Select-Model"
          getOptionLabel={(option: any) => `${option.name}`}
          renderOption={(props, option: any) => (
            <li {...props} style={{ padding: '24px 12px' }}>
              <Typography sx={{ marginLeft: '16px' }}>{option.name}</Typography>
            </li>
          )}
          renderInput={({ inputProps, ...rest }) => {
            return (
              <AutoCompleteTextField
                {...rest}
                inputProps={{ ...inputProps }}
                placeholder={'Select the Knowledge Base'}
              />
            );
          }}
          options={knowledgeBases.filter(knowledgeBase => !knowledgeBase.isOrganization)}
          onChange={(event, value) => {
            setListOfSelectedKnowledgeBases((value as KnowledgeBaseModel[]) || []);
            setIsDirtyState(true);
          }}
        />
      )}

      {/* check added for searchbar to appear only in temas page */}
      <Box sx={{ display: PageName === PagesEnum.Governance ? 'none' : 'block' }}>
        <Flex sx={{ alignItems: 'center', gap: '8px', marginTop: '24px' }}>
          <People sx={{ fill: GRAY_COLORS.GRAY_5 }} />
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9 }}>
            {i18n.t('teamList.details.members')}
          </Typography>
        </Flex>

        <Box sx={{ marginTop: '12px', width: '100%', marginBottom: '12px' }}>
          <UserAutocomplete
            options={users}
            onChange={value => {
              setIsDirtyState(true);
              setListOfUsers(value);
            }}
            value={listOfUsers}
            isDisable={PageName === PagesEnum.Governance}
            placeholder={PageName === PagesEnum.Governance ? 'Search user' : ''}
          />
        </Box>
      </Box>

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto', maxHeight: '150px', marginTop: '8px' }}>
        {listOfUsers.map(user => (
          <TeamUserRow
            key={`team-user-${user.id}`}
            teamUser={user}
            onRemoveClick={PageName === PagesEnum.Teams ? handleRemoveUser : undefined}
          />
        ))}
      </Box>

      <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} marginTop={'24px'} width={'100%'}>
        <Button
          onClick={onClose}
          variant={'outlined'}
          sx={{
            width: 'fit-content',
            padding: '9px 16px',
            height: '40px',
            marginRight: '12px',
            color: COLOR_PRIMARY,
            borderColor: COLOR_PRIMARY,
          }}
          id="Cancel-Btn"
        >
          {i18n.t('common.cancel.action')}
        </Button>
        <Button
          onClick={onSave}
          sx={{ width: 'fit-content', padding: '9px 16px', height: '40px' }}
          disabled={isEdit && !isDirtyState}
          loading={loading}
          id="Create-Btn"
        >
          {isEdit ? i18n.t('common.saveChanges.action') : i18n.t('createTeam.create.action')}
        </Button>
      </Stack>
    </CustomModal>
  );
};

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;

const StyledAutocomplete = styled(Autocomplete)`
  margin-top: 24px;
  width: 100%;
`;

export default observer(CreateTeamModal);
