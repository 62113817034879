import { io } from 'socket.io-client';
import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { SocketEvents } from '../constants/events/SocketEvents';
import {
  getApiEndpointFromHost,
  getTenantFromHost,
  isTenantDomainSet
} from '../utils/tenantUtils';

const REACT_APP_API_RT_URL = process.env.REACT_APP_API_RT_URL;

export class SocketStore {
  socket: any;
  rooms: string[];

  constructor() {
    makeAutoObservable(this);
    autoBind(this);

    this.rooms = [];

    const accessToken = localStorage.getItem(LocalStorageKeys.accessToken);

    if (accessToken) {
      try {
        this.connect(accessToken);
      } catch (e) {
        console.log('error connecting ------------------->> ', e);
      }
    }
  }

  connect(token: string) {
    const tenant = getTenantFromHost();
    const apiEndpoint = REACT_APP_API_RT_URL ?? getApiEndpointFromHost(true);

    if (isTenantDomainSet()) {
      localStorage.setItem(LocalStorageKeys.tenant, tenant);
    }
    this.socket = io(apiEndpoint, { auth: { token }, withCredentials: true });

    this.socket.on('connect', () => {
      console.log('Socket connected', this.socket.connected);
    });
  }

  joinRoom(room: string) {
    this.socket.emit(SocketEvents.joinRoom, room);

    runInAction(() => {
      this.rooms = [...this.rooms, room];
    });
  }

  leaveRoom(room: string) {
    this.socket.emit(SocketEvents.leave, room);

    runInAction(() => {
      this.rooms = this.rooms.filter(existingRoom => existingRoom !== room);
    });
  }

  subscribe(event: string, callback: (response?: any) => void) {
    this.socket.on(event, callback);
  }

  unSubscribeSingleListener(event: string, callback: (response?: any) => void) {
    this.socket.off(event, callback);
  }

  unSubscribeAllListenersFromSingleEvent(event: string) {
    this.socket.removeAllListeners(event);
  }

  unSubscribeFromAllEvents() {
    this.socket.removeAllListeners();
  }

  publish(subscribeMessage: string, event: string, data: any) {
    const response = this.socket.emit(subscribeMessage, { event: event, eventData: data });

    if (response && response.disconnected) {
      const lsToken = localStorage.getItem('token');
      if (lsToken) {
        try {
          const token = JSON.parse(lsToken).access_token;
          this.connect(token);
        } catch (e) {
          console.log(`error publishing ${event} ------------------->> `, e);
        }
      }
    }
  }
}
