import { default as Button, ButtonProps } from '@mui/material/Button';

interface TextButtonProps extends ButtonProps {}

const TextButton = (props: TextButtonProps) => {
  const { children, sx } = props;
  return (
    <Button
      variant={'text'}
      sx={{
        fontFamily: 'Poppins !important',
        lineHeight: '22px',
        fontSize: '14px',
        fontWeight: '600',
        height: '36px !important',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default TextButton;
