import { Team } from '../models/Team';

export const SortTeamsFormulas = {
  fromAtoZ: (teams: Team[]) => teams.slice().sort((team1, team2) => team1.name.localeCompare(team2.name)),
  fromZtoA: (teams: Team[]) => teams.slice().sort((team1, team2) => team2.name.localeCompare(team1.name)),
  leastToMostActive: (teams: Team[]) =>
    teams?.sort((a, b) => (a.totalChats || 0) + (a.totalPrompts || 0) - ((b.totalChats || 0) + (b.totalPrompts || 0))),
  mostToLeastActive: (teams: Team[]) =>
    teams?.sort((a, b) => (b.totalChats || 0) + (b.totalPrompts || 0) - ((a.totalChats || 0) + (a.totalPrompts || 0))),
};
