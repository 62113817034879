import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { COLOR_PRIMARY, COLOR_UTILS_TERTIARY } from '../../../../constants/colors';
import { useStore } from '../../../../hooks/useStore';

const CardContainer = styled(Stack)`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
  height: 166px;
  border-radius: 8px;
  background: ${COLOR_UTILS_TERTIARY};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

interface CreateTeamCardProps {
  onClick: () => void;
}

const CreateTeamCard = ({ onClick }: CreateTeamCardProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <CardContainer onClick={onClick} id='Create-Card'>
      <AddIcon sx={{ fill: COLOR_PRIMARY }} />
      <Typography variant={'body2'} sx={{ fontSize: '12px', color: COLOR_PRIMARY, marginTop: '16px' }}>
        {i18n.t('teamsList.card.add')}
      </Typography>
    </CardContainer>
  );
};

export default CreateTeamCard;
