import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import { useStore } from '../../../../../hooks/useStore';
import CustomModal from '../../../../../components/modal/CustomModal';

interface ShowImageComponentProps {
  imageKey: string;
  isBase64?: boolean;
}

// This component is to show images in audit logs
const ShowImageComponent = ({ imageKey, isBase64 }: ShowImageComponentProps) => {
  const {
    appState: { s3DocumentsApi },
  } = useStore();

  const [url, setUrl] = useState('');
  const [base64String, setBase64String] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    (async () => {
      const readUrl = await s3DocumentsApi.generateDocumentsReadUrl(imageKey);

      if (isBase64) {
        const res = await (await fetch(readUrl.signedUrl)).text();
        setBase64String(`data:image/jpeg;base64,${res}`);

        return;
      }

      setUrl(readUrl.signedUrl);
    })();
  }, [imageKey]);

  return (
    <>
      <Box
        sx={{ cursor: 'pointer', marginTop: '16px', '&:hover': { opacity: 0.7 }, width: 'fit-content' }}
        onClick={() => setShowImageModal(true)}
      >
        <img src={isBase64 ? base64String : url} style={{ maxWidth: 500 }} alt={'chat-plot'} />
      </Box>
      <CustomModal isOpen={showImageModal} onClose={() => setShowImageModal(false)} closeOnBackdropClick>
        <img src={isBase64 ? base64String : url} alt={'chat-plot'} />
      </CustomModal>
    </>
  );
};

export default ShowImageComponent;
