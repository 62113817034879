import React from 'react';
import { observer } from 'mobx-react';

import { Headline3 } from '../../components/typography/Headlines';
import FlexColumnStart from '../../components/utils/flex/FlexColumnStart';
import AppTable from '../../components/table/AppTable';
import ExpandableRow from '../../components/adminPortal/compliance/ExpandableRow';
import { useStore } from '../../hooks/useStore';
import { ComplianceModel } from '../../models/ComplianceModel';
import Flex from '../../components/utils/flex/Flex';

interface CompliancePageCardProps {
  type: string;
  saveButtonDisabled: boolean,
  setSaveButtonDisabled:  React.Dispatch<React.SetStateAction<boolean>>,
  onSave: () => void,
}
const CompliancePageCard = ({ saveButtonDisabled, setSaveButtonDisabled, type }: CompliancePageCardProps) => {
  const {
    localizationStore: { i18next: i18n },
    complianceStore: { compliances },
  } = useStore();

  const updateCompliancesRules = (entity: ComplianceModel) => {
    const newCompliances = compliances;
    const updatedIndex = newCompliances.findIndex(item => item.id === entity.id);
    newCompliances[updatedIndex] = entity;

    saveButtonDisabled && setSaveButtonDisabled(false);
  };

  return <>
    <Flex sx={{ marginBottom: '24px' }}>
      <Headline3>{i18n.t(`adminPortal.compliance.${type}.title`)}</Headline3>
    </Flex>

    <FlexColumnStart>
      {compliances?.map((compliance, index) => (
        <AppTable
          key={`compliance-${index}`}
          style={{
            height: 'calc(100% - 56px)',
            overflow: 'scroll',
            minHeight: 0,
            marginBottom: '16px',
            borderRadius: '8px',
          }}
        >
          <ExpandableRow
            type={type}
            entity={compliance}
            setEntity={entity => {
              updateCompliancesRules(entity);
            }}
            index={index}
          />
        </AppTable>
      ))}
    </FlexColumnStart>
  </>;
};

export default observer(CompliancePageCard);

