import React from 'react';
import { MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

import { filterValues, filterDayValues } from '../../../constants/adminPortal/insights/filter';

const FilterComponent = ({
  id,
  setFilter,
  value,
  filterType,
}: {
  id: string;
  setFilter: (arg: any) => void;
  value: string;
  filterType?: string;
}) => {
  const onChange = (event: any) => {
    setFilter(event.target.value);
  };

  return (
    <Select labelId={id} id={id} value={value} onChange={onChange}>
      {filterType === 'days'
        ? filterDayValues?.map((filter, index) => (
            <MenuItem value={filter.value} key={`filter-${index}`}>
              <Typography variant={'subtitle2'}>{filter.name}</Typography>
            </MenuItem>
          ))
        : filterValues?.map((filter, index) => (
            <MenuItem value={filter.value} key={`filter-${index}`}>
              <Typography variant={'subtitle2'}>{filter.name}</Typography>
            </MenuItem>
          ))}
    </Select>
  );
};

export default FilterComponent;
