import { Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import UserAvatar from '../../../UserAvatar/UserAvatar';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AddPromptModalAutocompleteProps {
  options: any[];
  value: any[];
  setValue: (value: any) => void;
  label?: string;
  placeHolder?: string;
  error?: string;
}

const AddPromptModalAutocomplete = ({
  options,
  value,
  error,
  setValue,
  label,
  placeHolder,
}: AddPromptModalAutocompleteProps) => {
  return (
    <Autocomplete
      multiple
      size={'small'}
      id={`${label}-autocomplete`}
      options={options}
      limitTags={1}
      filterSelectedOptions
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.name || option.fullName || option.email}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {(option.fullName || option.email) && <UserAvatar user={option} size={20} sx={{ marginRight: '8px' }} />}
          {option.name || option.fullName || option.email}
        </li>
      )}
      style={{ marginBottom: '20px' }}
      renderInput={({ inputProps, ...rest }) => {
        return (
          <AutoCompleteTextField
            {...rest}
            inputProps={{ ...inputProps }}
            placeholder={placeHolder}
            error={!!error}
            helperText={error}
          />
        );
      }}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, value) => setValue(value)}
    />
  );
};

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;

export default AddPromptModalAutocomplete;
