import { i18n } from 'i18next';
import * as moment from 'moment';
import 'moment-timezone';

import { AuditLog } from '../../../models/AuditLog';

interface MethodProps {
  i18n: i18n;
  items: AuditLog[];
}

const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getExcelData = ({ i18n, items }: MethodProps) => {
  return items?.map(tableData => {
    let entityType = tableData?.entityType || '';
    if (entityType && tableData?.entityType === 'conversation') {
      entityType = 'Deleted Chat';
    }

    return {
      [i18n.t('logsList.table.header.user')]: tableData.userName || '',
      [i18n.t('logsList.table.header.eventTimeAndDate')]:
        (tableData?.createdAt && moment.tz(tableData?.createdAt, 'EST').format('MM.DD.YYYY \n hh:mm A')) || '',
      [i18n.t('logsList.table.header.eventType')]:
        (entityType &&
          (tableData.action ? capitalizeFirst(`${entityType} ${tableData.action}d`) : capitalizeFirst(entityType))) ||
        '',
      [i18n.t('logsList.table.header.status')]: tableData.status || '',
      [i18n.t('logsList.table.header.model')]: tableData.additionalInformation?.chat?.model || '',
      [i18n.t('logsList.table.header.message')]: tableData.additionalInformation?.chat?.message || '',
      [i18n.t('logsList.table.header.responseMessage')]: tableData.additionalInformation?.chat?.responseMessage || '',
      [i18n.t('logsList.table.header.conversation')]: tableData.additionalInformation?.chat?.id || '',
    };
  });
};
