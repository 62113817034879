import { observer } from 'mobx-react-lite';
import { COLOR_WHITE } from '../../constants/colors';

// This page is made for showing cooming soon where feature in not built or is under process
const CommingSoonPage = () => {
  return (
    <div
      style={{
        height: '78vh',
        background: COLOR_WHITE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <h1>Coming Soon...</h1>
      <h2>Stay tuned for further update!!</h2>
    </div>
  );
};

export default observer(CommingSoonPage);
