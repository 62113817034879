import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { KnowledgeBaseApi } from '../api/KnowledgeBaseApi';
import { CreateKnowledgeBaseDto, EMPTY_KNOWLEDGE_MODEL, KnowledgeBaseModel } from '../models/KnowledgeBaseModel';
import { File } from '../models/File';
import { FileApi } from '../api/FileApi';

export class KnowledgeBaseStore {
  knowledgeBaseApi: KnowledgeBaseApi;
  fileApi: FileApi;
  knowledgeBases: KnowledgeBaseModel[] = [EMPTY_KNOWLEDGE_MODEL];
  uploadInProgress: boolean = false;
  uploadProgress: number = 0;
  showFilePreview: boolean = false;
  selectedFile?: File;
  currentKnowledgeBase: KnowledgeBaseModel = EMPTY_KNOWLEDGE_MODEL;
  knowledgeBaseFiles: File[] = [];
  activeFileTab: number = 0;

  constructor(knowledgeBaseApi: KnowledgeBaseApi, fileApi: FileApi) {
    this.knowledgeBaseApi = knowledgeBaseApi;
    this.fileApi = fileApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedFile(file: File) {
    runInAction(() => {
      this.selectedFile = file;
    });
  }

  setActiveFileTab(tab: number) {
    runInAction(() => {
      this.activeFileTab = tab;
    });
  }

  setCurrentKnowledgeBase(kb: KnowledgeBaseModel) {
    runInAction(() => {
      this.currentKnowledgeBase = kb;
    });
  }

  async getAllKnowledgeBasesByOrganization() {
    const knowledgeBases = await this.knowledgeBaseApi.getAllKnowledgeBasesByOrganization();
    if (knowledgeBases?.length) {
      knowledgeBases?.unshift(
        knowledgeBases.splice(
          knowledgeBases.findIndex((e: KnowledgeBaseModel) => e.name === 'Organization'),
          1
        )[0]
      );
    }
    runInAction(() => {
      this.knowledgeBases = knowledgeBases?.length ? knowledgeBases : [EMPTY_KNOWLEDGE_MODEL];
    });
  }

  async getKnowledgeBaseByOrganization() {
    const knowledgeBases = await this.knowledgeBaseApi.getKnowledgeBaseByOrganization();
    runInAction(() => {
      this.knowledgeBaseFiles = knowledgeBases?.length ? knowledgeBases[0].files : [];
    });
  }

  async getKnowledgeBaseByTeam(teamId: string) {
    const knowledgeBases = await this.knowledgeBaseApi.getKnowledgeBaseByTeam(teamId);

    runInAction(() => {
      this.knowledgeBaseFiles = knowledgeBases?.length ? knowledgeBases[0].files : [];
    });
  }
  async createKnowledgeBase(body: CreateKnowledgeBaseDto): Promise<KnowledgeBaseModel> {
    const knowledgeBase = await this.knowledgeBaseApi.createKnowledgeBase(body);
    runInAction(() => {
      this.knowledgeBases = knowledgeBase;
    });
    await this.getKnowledgeBaseByOrganization();
    return knowledgeBase;
  }

  setIsUploadInProgress(value: boolean) {
    runInAction(() => {
      this.uploadInProgress = value;
    });
  }

  setUploadProgress(value: number) {
    runInAction(() => {
      this.uploadInProgress = true;
      this.uploadProgress = value;
    });
  }

  async cancelFileUpload() {}

  async updateFileName(fileId: string, name: string) {
    if (this.selectedFile) {
      const file: File = { ...this.selectedFile, name };
      this.setSelectedFile(file);
    }

    await this.fileApi.updateFileName(fileId, name);
  }

  async deleteFile(fileId: string, knowledgeBaseId: string) {
    await this.knowledgeBaseApi.deleteFile(fileId, knowledgeBaseId);
  }
  async getById(id: string) {
    const kb = await this.knowledgeBaseApi.getById(id);
    this.setCurrentKnowledgeBase(kb);
    return kb;
  }

  async getByTeamId(teamId: string) {
    const kb = await this.knowledgeBaseApi.getByTeamId(teamId);
    this.setCurrentKnowledgeBase(kb);
    return kb;
  }
}
