import { SettingItems } from '../models/WorkSpace';
import { ApiConfig } from './ApiConfig';

export class WorkSpaceApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: any) {
    this.apiConfig = apiConfig;
  }
  getWorkSpaceSetting() {
    return this.apiConfig.sendRequest('GET', '/workflow-settings');
  }
  createWorkSpace(settingDto: Array<SettingItems>) {
    return this.apiConfig.sendRequest('POST', '/workflow-settings/updateSetting', settingDto);
  }
}
