import { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Tab,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { useStore } from '../../hooks/useStore';
import { COLOR_BLUE_FONT, GRAY_COLORS } from '../../constants/colors';
import CustomModal from './CustomModal';

interface ShowModelEvaluationResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  outputData: object[];
  currentJobData: any;
}

const useStyles = makeStyles({
  headingDetails: {
    fontWeight: 600,
    fontSize: '1rem',
    color: COLOR_BLUE_FONT,
  },
  tableHead: {
    padding: '12px',
    border: `1px solid ${GRAY_COLORS.GRAY_12}`,
    color: COLOR_BLUE_FONT,
    maxWidth: '20%',
  },
  tableData: {
    padding: '12px',
    border: `1px solid ${GRAY_COLORS.GRAY_12}`,
  },
});

const ShowModelEvaluationResultModal = ({
  isOpen,
  onClose,
  outputData,
  currentJobData,
}: ShowModelEvaluationResultModalProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  // List of supported models
  const modelDropDownValues = [
    {
      id: 1,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/meta.llama2-13b-chat-v1',
      name: 'Llama 2 Chat 13B',
    },
    {
      id: 2,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/meta.llama2-70b-chat-v1',
      name: 'Llama 2 Chat 70B',
    },
    {
      id: 3,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/cohere.command-text-v14',
      name: 'Command',
    },
    {
      id: 4,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/cohere.command-light-text-v14',
      name: 'Command Light',
    },
    {
      id: 5,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/anthropic.claude-instant-v1',
      name: 'Claude Instant',
    },
    {
      id: 6,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/anthropic.claude-v2:1',
      name: 'Claude v2.1',
    },
    {
      id: 7,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/anthropic.claude-v2',
      name: 'Claude v2',
    },
    {
      id: 8,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/ai21.j2-mid-v1',
      name: 'Jurassic-2 Mid',
    },
    {
      id: 9,
      value: 'arn:aws:bedrock:us-east-1::foundation-model/ai21.j2-ultra-v1',
      name: 'Jurassic-2 Ultra',
    },
  ];

  const classes = useStyles();

  const [value, setValue] = useState('1');

  // For tab change
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} sx={{ width: '80%' }} closeOnBackdropClick={true}>
      <div style={{ overflowX: 'auto', height: '60vh', padding: '0 0.5rem' }}>
        <Box marginBottom="1rem" display="flex">
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {i18n.t('adminPortal.modelevaluation.result.heading')}
          </Typography>
          <IconButton onClick={onClose} size={'medium'} sx={{ marginLeft: 'auto' }}>
            <Close fontSize={'medium'} />
          </IconButton>
        </Box>
        <div
          style={{
            background: GRAY_COLORS.GRAY_11,
            fontWeight: '500',
            fontSize: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '8px',
            color: COLOR_BLUE_FONT,
            letterSpacing: '0.5px',
            border: '1px solid rgb(221, 221, 221)',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '33.33%' }}>
              <p className={classes.headingDetails}>{i18n.t('adminPortal.modelevaluation.result.jobname')}:</p>
              <p>{currentJobData.jobName}</p>
            </div>
            <div style={{ width: '33.33%' }}>
              <p className={classes.headingDetails}>{i18n.t('adminPortal.modelevaluation.result.creationTime')}:</p>
              <p>{currentJobData.creationTime}</p>
            </div>
            <div style={{ width: '33.33%' }}>
              <p className={classes.headingDetails}>{i18n.t('adminPortal.modelevaluation.result.evaluationType')} :</p>
              <p>{currentJobData.evaluationTaskTypes[0]}</p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '33.33%' }}>
              <p className={classes.headingDetails}>{i18n.t('adminPortal.modelevaluation.result.metric')} :</p>
              <p>
                {currentJobData.metrics?.map((metric: any, index: number) => (
                  <span>
                    {metric.metricName} {index === currentJobData.metrics.length - 1 ? '' : ', '}
                  </span>
                ))}
              </p>
            </div>
            <div style={{ width: '33.33%' }}>
              <p className={classes.headingDetails}>{i18n.t('adminPortal.modelevaluation.result.dataSet')} :</p>
              <p>
                {currentJobData.metrics?.map((metric: any, index: number) => (
                  <span>
                    {metric.dataSet} {index === currentJobData.metrics.length - 1 ? '' : ', '}
                  </span>
                ))}
              </p>
            </div>
            <div style={{ width: '33.33%' }}>
              <p className={classes.headingDetails}>{i18n.t('adminPortal.modelevaluation.result.model')} :</p>
              <p>
                {modelDropDownValues.filter(model => model.value === currentJobData.modelIdentifiers?.[0])[0]?.name}
              </p>
            </div>
          </div>
        </div>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '12px' }}>
              <TabList onChange={handleChange} aria-label="evaluation result tabs">
                {outputData.map((item: any, index) => {
                  return <Tab label={item[0].automatedEvaluationResult.scores[0].metricName} value={`${index + 1}`} />;
                })}
              </TabList>
            </Box>
            {outputData.map((item: any, index) => {
              return (
                <TabPanel value={`${index + 1}`}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            {i18n.t('adminPortal.modelevaluation.result.table.prompt')}
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            {i18n.t('adminPortal.modelevaluation.result.table.modelResponse')}
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            {i18n.t('adminPortal.modelevaluation.result.table.score')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item?.map((data: any) => {
                          return (
                            <TableRow key={index + 1} sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                              <TableCell className={classes.tableHead}>{data.inputRecord.prompt}</TableCell>
                              <TableCell
                                style={{
                                  padding: '12px',
                                  border: `1px solid ${GRAY_COLORS.GRAY_12}`,
                                  lineHeight: '1.3',
                                  textAlign: 'justify',
                                }}
                              >
                                <span style={{ display: 'inline-block', marginRight: index === 0 ? 8 : 0 }}>
                                  {data.modelResponses[0].response}
                                </span>
                              </TableCell>
                              <TableCell className={classes.tableHead}>
                                {data.automatedEvaluationResult.scores[0].result}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              );
            })}
          </TabContext>
        </Box>
      </div>
    </CustomModal>
  );
};

export default ShowModelEvaluationResultModal;
