const commonFilters = [
  {
    value: 'allTime',
    name: 'All time',
  },
  {
    value: 'thisMonth',
    name: 'This month',
  },
  {
    value: 'lastMonth',
    name: 'Last month',
  },
  {
    value: 'thisYear',
    name: 'This year',
  },
];

export const filterValues = [
  ...commonFilters,
  {
    value: 'lastYear',
    name: 'Last year',
  },
];

export const filterDayValues = [
  ...commonFilters,
  {
    value: 'forever',
    name: 'For Ever',
  },
];
