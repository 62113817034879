import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Joi from 'joi';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import { COLOR_MANDATORY, COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';
import { Roles } from '../../../constants/userRoles';

import Button from '../../buttons/Button';
import CustomModal from '../../modal/CustomModal';
import { useStore } from '../../../hooks/useStore';
import TextInput from '../../inputs/TextInput';
import { UserFromGetModel } from '../../../models/UserModel';
import Flex from '../../utils/flex/Flex';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import Divider from '../../Divider/Divider';
import SelectRoleAndTeams from './SelectRoleAndTeams';
import { LoadingSpinner } from '../../spinner/LoadingSpinner';
import InviteUserRow from './InviteUserRow';
import { Team } from '../../../models/Team';
import ImportCSV from './ImportCSV';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  item?: UserFromGetModel;
}

interface FormProps {
  email: string;
  role: string;
  teams: string[];
}

const ErrorsContainer = styled.div`
  background: rgba(200, 30, 30, 0.15);
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 16px;
`;

const ListContainer = styled(Box)`
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InviteUserModal = ({ isOpen, onClose, item }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { inviteUsersToOrganization, userData },
    teamStore: { teams, getTeamsByUserOrganization },
  } = useStore();
  const { addToast } = useToasts();

  const teamFilters = teams;

  const [formErrors, setFormErrors] = useState({
    email: '',
    role: '',
    teams: '',
  });
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>(Roles.member);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [usersList, setUsersList] = useState<FormProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [teamsErrors, setTeamsErrors] = useState<string[]>([]);

  useEffect(() => {
    setFormErrors({
      email: '',
      role: '',
      teams: '',
    });
    setEmail('');
    setRole(Roles.member);
    setUsersList([]);
    setSelectedTeams([]);
    setSaveErrors([]);
    setTeamsErrors([]);
    (async () => {
      await getTeamsByUserOrganization();
    })();
  }, [isOpen]);

  const getSchema = (i18n: any) => {
    return Joi.object().keys({
      role: Joi.string().required().label(i18n.t('labels.role')),
      teams: Joi.array().required().label(i18n.t('labels.teams')),
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: false })
        .messages({
          'string.email': 'errors.invalidEmailFormat.message',
          'string.empty': 'errors.emailRequired.message',
        })
        .label(i18n.t('labels_email')),
    });
  };

  const generalValidation = (input: FormProps, name?: string): string | undefined | null | { [key: string]: any } => {
    const results = getSchema(i18n).validate(input, { abortEarly: false });

    if (results.error && name) {
      const error = results.error.details.find(obj => obj.path[0] === name);
      return error?.message;
    }

    if (results.error && !name) {
      let errorsObj: { [key: string]: any } | null = null;
      results.error.details.forEach(detail => {
        errorsObj = {
          ...(errorsObj || {}),
          [detail.path[0]]: detail.message,
        };
      });

      return errorsObj;
    }

    return null;
  };

  const onSave = async () => {
    if (!!email) {
      validateAndAddUser();
    }
    try {
      const users: any = usersList?.map(user => {
        const teamsToAdd = teams
          ?.filter((team: Team) => user?.teams?.includes(team.name))
          .map(item => ({
            id: item?.id,
          }));
        // @ts-ignore
        user.teams = teamsToAdd;
        return user;
      });
      const response = await inviteUsersToOrganization({ users, organizationId: userData?.organization?.id });
      if (response?.length) {
        setSaveErrors([...saveErrors, ...teamsErrors, ...response?.map((item: any) => item.message)]);
        setUsersList([]);
        if (users?.length - response?.length > 0) {
          addToast(`${users?.length - response?.length} users successfully added`, { appearance: 'success' });
        }
      } else {
        onClose();
        addToast(`Users successfully added`, { appearance: 'success' });
      }
    } catch (error) {
      // @ts-ignore
      if (error?.response?.data?.error) {
        setFormErrors({
          ...formErrors,
          // @ts-ignore
          email: error?.response?.data?.message?.toString(),
        });
      }
    }
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    setEmail(value);
    setSaveErrors([]);
  };

  const validateAndAddUser = () => {
    setLoading(true);
    const errors = generalValidation({ email, role, teams: selectedTeams });
    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      setLoading(false);
      return;
    }
    if (usersList.find(item => item.email === email)) {
      setFormErrors({
        ...formErrors,
        email: 'User already added',
      });
      setLoading(false);
      return;
    }
    usersList?.push({ email, role, teams: selectedTeams });

    setUsersList(usersList);
    setEmail('');
    setFormErrors({
      email: '',
      role: '',
      teams: '',
    });
    setLoading(false);
  };

  const onChangeRole = (value: string) => {
    setRole(value);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{
        width: '600px',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: '32px',
        maxHeight: '80vh',
        overflow: 'hidden',
      }}
    >
      <Stack width={'100%'} justifyContent={'flex-start'} gap={'4px'}>
        <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
            {i18n.t('usersModal.title.invite')}
          </Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close fontSize={'small'} />
          </IconButton>
        </Flex>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_8 }}>
          {i18n.t('usersModal.subtitle.invite')}
        </Typography>
      </Stack>

      <Divider sx={{ marginTop: '24px', backgroundColor: GRAY_COLORS.GRAY_2 }} />

      <FlexRowSpaceBetween sx={{ width: '100%', marginTop: '24px' }}>
        <Flex sx={{ alignItems: 'center', gap: '8px' }}>
          <GroupAddIcon sx={{ fill: GRAY_COLORS.GRAY_5 }} />
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9 }}>
            {i18n.t('usersModal.title.invite')}
          </Typography>
        </Flex>

        <ImportCSV
          setLoading={setLoading}
          setSaveErrors={setSaveErrors}
          saveErrors={saveErrors}
          setTeamsErrors={setTeamsErrors}
          setUsersList={setUsersList}
          usersList={usersList}
        />
      </FlexRowSpaceBetween>

      <TextInput
        id="email"
        name={'email'}
        label={i18n.t('labels.inviteEmail')}
        value={email}
        error={formErrors.email?.startsWith('errors.') ? i18n.t(formErrors.email) : formErrors.email}
        onChange={onChange}
        variant={'outlined'}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            validateAndAddUser();
          }
        }}
        type={'email'}
        testId={'invite-user-email'}
        sx={{ marginTop: '26px', height: '50px' }}
        disabled={!!item?.id}
        customEndAdornment={
          <SelectRoleAndTeams
            onChangeRole={onChangeRole}
            selectedRole={role}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            teamFilters={teamFilters}
            error={formErrors.teams}
          />
        }
      />

      <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_5, marginTop: '8px' }}>
        {i18n.t('usersModal.description.invite')}
      </Typography>

      {loading ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : (
        <ListContainer sx={{ maxHeight: '200px', overflow: 'scroll' }}>
          {saveErrors?.length ? (
            <ErrorsContainer>
              {saveErrors?.map((item, key) => (
                <Typography variant="subtitle2" sx={{ color: COLOR_MANDATORY }}>
                  • {item}
                </Typography>
              ))}
            </ErrorsContainer>
          ) : (
            <InviteUserRow usersList={usersList} setUsersList={setUsersList} teamFilters={teamFilters} />
          )}
        </ListContainer>
      )}

      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginTop={'24px'}
        marginRight={0}
        marginLeft={'auto'}
      >
        <Button
          onClick={onClose}
          variant={'outlined'}
          sx={{
            padding: '9px 16px',
            height: '40px',
            marginRight: '12px',
            color: COLOR_PRIMARY,
            borderColor: COLOR_PRIMARY,
          }}
        >
          {i18n.t('common.cancel.action')}
        </Button>
        <Button
          onClick={onSave}
          sx={{ padding: '9px 16px', height: '40px', whiteSpace: 'nowrap' }}
          disabled={!!saveErrors?.length}
        >
          {i18n.t('common.sendInvites.action')}
        </Button>
      </Stack>
    </CustomModal>
  );
};

export default observer(InviteUserModal);
