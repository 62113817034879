import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import configure from '../../config';
import defaultLogo from '../../config/addvaluemachine/logo.svg';

interface LogoProps {
  size?: number;
}

const Logo = ({ size }: LogoProps) => {
  const [logo, setLogo] = useState(defaultLogo);

  useEffect(() => {
    (async () => {
      const config = await configure();
      setLogo(config.logo);
    })();
  }, []);

  let chatPortalLink = '';
  const originUrl = window.location.origin;
  if (originUrl.includes('admin.')) {
    chatPortalLink = originUrl.replace('admin.', 'chat.');
  }

  return (
    <img
      height={size || 30}
      src={logo}
      alt={'AvmLogo'}
      onClick={() => {
        window.location.href = chatPortalLink;
      }}
    />
  );
};

export default Logo;
