import { observer } from 'mobx-react';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';

import Flex from '../../utils/flex/Flex';
import { useStore } from '../../../hooks/useStore';
import FlexRowStart from '../../utils/flex/FlexRowStart';
import IconByAction from './IconByAction';
import ViewModeSimpleRow from '../../viewMode/ViewModeSimpleRow';

interface ViewPromptModalProps {
  id: string | null;
  closeModal: () => void;
}

// This page is for audit logs
const ModelEvaluationDetailPage = ({ id, closeModal }: ViewPromptModalProps) => {
  const {
    localizationStore: { locale, i18next: i18n },
    auditLogsStore: { currentAuditLog },
    modelStore: {},
  } = useStore();

  moment.locale(locale);

  return (
    <Stack sx={{ padding: '24px', display: 'flex', height: '100%', overflowX: 'auto' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          backgroundColor: COLOR_WHITE,
          gap: '16px',
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {i18n.t('adminPortal.modelevaluationDetailsPage.heading')}
          </Typography>
          <IconButton size={'small'} onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
        {currentAuditLog?.action && (
          <FlexRowStart sx={{ gap: '12px' }}>
            <IconByAction action={currentAuditLog?.action} />
            <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
              {`This Model evaluation was ${i18n.t(currentAuditLog?.action)}d by ${i18n.t(
                currentAuditLog.userName
              )} on ${moment.tz(currentAuditLog?.createdAt, 'EST').format('MMM DD, YYYY')}`}
            </Typography>
          </FlexRowStart>
        )}
        <ViewModeSimpleRow title="Job name" text={i18n.t(currentAuditLog.additionalInformation.jobName) || 'Default'} />
        <ViewModeSimpleRow
          title="Model"
          text={
            i18n.t(
              currentAuditLog.additionalInformation.modelId.substring(
                currentAuditLog.additionalInformation.modelId.lastIndexOf('/') + 1
              )
            ) || 'Default'
          }
        />
        <ViewModeSimpleRow
          title="Task type"
          text={i18n.t(currentAuditLog.additionalInformation.taskType) || 'Default'}
        />
        <ViewModeSimpleRow
          title="Data set"
          text={
            i18n.t(currentAuditLog?.additionalInformation?.metrics?.map((metric: any) => `${metric.dataSet}, `)) ||
            'Default'
          }
        />
        <ViewModeSimpleRow
          title="Metric name"
          text={
            i18n.t(currentAuditLog?.additionalInformation?.metrics?.map((metric: any) => `${metric.metricName}, `)) ||
            'Default'
          }
        />
        <ViewModeSimpleRow
          title="Max_length"
          text={i18n.t(currentAuditLog.additionalInformation.max_length) || 'Default'}
        />
        <ViewModeSimpleRow
          title="Temperature"
          text={i18n.t(currentAuditLog.additionalInformation.temperature) || 'Default'}
        />
        <ViewModeSimpleRow title="Top_p" text={i18n.t(currentAuditLog.additionalInformation.top_p) || 'Default'} />
      </Flex>
    </Stack>
  );
};

export default observer(ModelEvaluationDetailPage);
