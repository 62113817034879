import { EMPTY_USER, User } from './User';
import { fileStatus } from '../constants/fileStatus';
import { PiiEntityType } from '@aws-sdk/client-comprehend';

export interface RedactionMapping {
  type: PiiEntityType;
  originalValue: string;
  redactedValue: string;
  offsets: { beginOffset: number; endOffset: number }[];
}

export interface File {
  id: string;
  name: string;
  key: string;
  content?: any;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
  redactionMapping?: RedactionMapping[];
  skipRedaction: boolean;
}

export const EMPTY_FILE: File = {
  createdAt: new Date(),
  id: '',
  key: '',
  name: '',
  status: fileStatus.PENDING,
  updatedAt: new Date(),
  user: EMPTY_USER,
  skipRedaction: false,
};

export interface CreateFileDto {
  name: string;
  key: string;
  skipRedaction?: boolean;
}
