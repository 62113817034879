import React, { useEffect } from 'react';

const KEY_CODE = {
  pageDown: 'PageDown',
  pageUp: 'PageUp',
};

interface RefProp {
  ref: React.RefObject<any>;
  selector?: never;
}

interface SelectorProp {
  selector: string;
  ref?: never;
}

type UseScrollWithKeyboardProps = RefProp | SelectorProp;

/**
 * Autofocuses the provided list element and allows user to scroll with page up & page down buttons
 *
 * @param props
 * @param props.ref - List element ref
 * @param props.selector - List element query selector
 */
const useScrollWithKeyboard = (props: UseScrollWithKeyboardProps) => {
  const { ref } = props as RefProp;
  const { selector } = props as SelectorProp;

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const { code } = event;

      const isPageUpOrDown = code === KEY_CODE.pageUp || code === KEY_CODE.pageDown;
      if (!isPageUpOrDown) {
        return;
      }

      const listElement = ref?.current || document.querySelector(selector);
      if (!listElement) {
        return;
      }

      if (listElement.tabIndex < 0) {
        listElement.setAttribute('tabIndex', '0');
      }

      listElement.focus();
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [ref?.current, selector]);
};

export default useScrollWithKeyboard;
