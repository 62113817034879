import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';

import { SIZES_BIG, SIZES_MEDIUM } from '../../constants/sizes';
import { COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';

import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import FlexRowCenter from '../utils/flex/FlexRowCenter';
import { getIconByName, MoreIcon, TypeIcon } from './utils';
import { useStore } from '../../hooks/useStore';
import FileThumbnail from './FileThumbnail';
import { File } from '../../models/File';
import { getTextOverflowEllipsisStyles } from '../typography/utils';

const CardsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 0 4px 0 4px;
  margin-top: 16px;

  @media screen and (max-width: ${SIZES_BIG}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: ${SIZES_MEDIUM}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FileCard = styled(Box)`
  background: ${COLOR_WHITE};
  border-radius: 8px;
  border: 1px solid ${GRAY_COLORS.GRAY_300};
  padding: 16px;
  height: 229px;

  &:hover {
    background: #edf3fe;
  }
`;

const ThumbnailContainer = styled.div`
  width: 100%;
  background: ${COLOR_WHITE};
  margin-top: 16px;

  canvas {
    width: 282px !important;
    height: 157px !important;
  }
`;

const CardsView = ({
  handleClick,
  onPreviewFile,
}: {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>, selectedFile: File) => void;
  onPreviewFile: (file: File) => void;
}) => {
  const {
    knowledgeBaseStore: { knowledgeBaseFiles },
  } = useStore();

  return (
    <CardsContainer>
      {knowledgeBaseFiles?.map(item => (
        <FileCard key={`folder-${item.id}`} onClick={() => onPreviewFile(item)}>
          <FlexRowSpaceBetween>
            <FlexRowCenter>
              <TypeIcon src={getIconByName(item.name)} alt={item.name} />
              <Typography sx={{ maxWidth: '250px', ...getTextOverflowEllipsisStyles(1) }}>{item.name}</Typography>
            </FlexRowCenter>
            <IconButton
              onClick={e => handleClick(e, item)}
              sx={{
                width: '20px',
                height: '20px',
                '&:hover': {
                  backgroundColor: 'inherit',
                },
              }}
            >
              <MoreIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </FlexRowSpaceBetween>
          <ThumbnailContainer>
            <FileThumbnail fileKey={item.key} />
          </ThumbnailContainer>
        </FileCard>
      ))}
    </CardsContainer>
  );
};

export default observer(CardsView);
