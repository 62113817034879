import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Typography } from '@mui/material';
import { TopPrompt } from '../../../models/Prompt';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import { COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import Flex from '../../utils/flex/Flex';
import TeamTag from '../../teamTag/TeamTag';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import UserAvatar from '../../UserAvatar/UserAvatar';
import FlexColumnSpaceBetween from '../../utils/flex/FlexColumnSpaceBetween';
import FlexRowStart from '../../utils/flex/FlexRowStart';

const Count = styled.div`
  margin-right: 8px;
`;

const Title = styled.div`
  margin-right: 24px;
`;

const OptionsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const cellStyle = {
  maxWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  background: 'inherit',
  paddingTop: '14px',
  paddingBottom: '12px',
};

const Teams = ({ prompt }: { prompt: TopPrompt }) => (
  <FlexRowStart sx={{ flexWrap: 'wrap' }}>
    {prompt?.teams?.map((item, index) => (
      <TeamTag team={item.name} key={`team-${index}`} withBorders sx={{ borderRadius: '4px', marginRight: '8px' }} />
    ))}
  </FlexRowStart>
);
const PromptRow = ({ prompt, index }: { prompt: TopPrompt; index: number }) => {
  const [open, setOpen] = useState(false);

  const handleToggleButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  const contentRef = useRef<HTMLDivElement>(null);

  const tableRowStyle = {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(249, 250, 251, 1)',
    },
  };

  return (
    <TableRow sx={[tableRowStyle, { height: '82px' }]} onClick={handleToggleButtonClick}>
      <TableCell sx={[cellStyle]} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
          <Flex sx={{ marginTop: '14px' }}>
            <Count>{`#${index + 1}`}</Count>
            <Title>{prompt.title}</Title>
            <Teams prompt={prompt} />
          </Flex>
        </Typography>
        <Typography
          variant="subtitle2"
          color={!open ? GRAY_COLORS.GRAY_500 : COLOR_TEXT_PRIMARY}
          sx={
            !open
              ? {
                  marginLeft: '28px',
                  marginTop: '10px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }
              : {
                  marginLeft: '28px',
                  marginTop: '10px',
                }
          }
        >
          {prompt.content}
        </Typography>

        <Collapse in={open} timeout="auto" sx={{ borderLeft: 'none', padding: '0 20px 0 10px' }}>
          <Box style={{ overflow: 'auto' }}>
            <div ref={contentRef}>
              <FlexRowSpaceBetween sx={{ margin: '20px 0 13px 0' }}>
                <FlexRowSpaceBetween>
                  <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                    <UserAvatar user={prompt.user} size={32} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: COLOR_TEXT_PRIMARY,
                        }}
                      >
                        {prompt.user.fullName}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: GRAY_COLORS.GRAY_500,
                        }}
                      >
                        {prompt.user.position}
                      </Typography>
                    </Box>
                  </Box>
                </FlexRowSpaceBetween>
              </FlexRowSpaceBetween>
            </div>
          </Box>
        </Collapse>
      </TableCell>
      <TableCell sx={[cellStyle, { height: '60px' }]} style={{ paddingBottom: 0 }}>
        <FlexColumnSpaceBetween sx={{ height: '100%' }}>
          <div>{prompt.allTimeUseCounter}</div>
          <OptionsButtonContainer>
            <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
          </OptionsButtonContainer>
        </FlexColumnSpaceBetween>
      </TableCell>
    </TableRow>
  );
};

export default PromptRow;
