import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';

import exportImg from '../../assets/icons/exportImg.svg';
import importImg from '../../assets/icons/importImg.svg';

import { SIZES_NUMBER_SMALL_MEDIUM, SIZES_NUMBER_TINY_SMALL } from '../../constants/sizes';

import { TableHeaderModel } from '../../models/TableModel';
import { ItemTableModel } from '../../models/ItemProps';
import { useStore } from '../../hooks/useStore';
import ItemsTableListRow from './ItemsTableListRow';
import useScrollWithKeyboard from '../../hooks/useScrollWithKeyboard';
import { COLOR_TEXT_BASE_PRIMARY } from '../../constants/colors';
import FlexColumnCenter from '../utils/flex/FlexColumnCenter';

interface ItemsTableListProps {
  header: TableHeaderModel[];
  items: ItemTableModel[];
  hasMultipleSelection?: boolean;
  onRowClick?: (item: ItemTableModel) => void;
  leftAlign?: boolean;
  gateway?: boolean;
  allowDragAndDrop?: boolean;
  moveAttachment?: (dragIndex: number, hoverIndex: number) => void;
  hasMore?: boolean;
  loadMore?: () => void;
  loading?: boolean;
  allowBackgroundColor?: boolean;
  isImports?: boolean;
  isExport?: boolean;
  selectedItem?: any;
  sxHeader?: SxProps;
  sxCell?: SxProps;
}

const ItemsTableList = ({
  header,
  items,
  hasMultipleSelection,
  onRowClick,
  leftAlign,
  gateway,
  allowDragAndDrop = false,
  moveAttachment,
  hasMore,
  loadMore,
  loading,
  allowBackgroundColor = false,
  isImports = false,
  isExport = false,
  sxHeader,
  sxCell,
  selectedItem,
}: ItemsTableListProps) => {
  const {
    localizationStore: { i18next: i18n },
    uiStore: { selectedIndexesTable },
  } = useStore();
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(gateway ? 0 : null);
  const [distanceBottom, setDistanceBottom] = useState(0);

  const isTablet = useMediaQuery({ maxWidth: SIZES_NUMBER_SMALL_MEDIUM, minWidth: SIZES_NUMBER_TINY_SMALL });

  const tableEl = useRef<any>();

  useScrollWithKeyboard({ ref: tableEl });

  useEffect(() => {
    setSelectedIndexes(selectedIndexesTable);
  }, [selectedIndexesTable]);

  const scrollListener = useCallback(() => {
    if (!tableEl.current) return;

    const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;

    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.1));
    }

    if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      loadMore?.();
    }
  }, [hasMore, loadMore, distanceBottom, loading]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;

    if (!tableRef) return;

    tableRef.addEventListener('scroll', scrollListener);

    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  const onRowCheckClick = (index: number) => {
    let newSelectedIndexes;
    if (selectedIndexes.includes(index)) {
      newSelectedIndexes = selectedIndexes.filter(selectedIndex => selectedIndex !== index);
    } else {
      newSelectedIndexes = [...selectedIndexes, index];
    }

    setSelectedIndexes(newSelectedIndexes);
  };

  const isRowSelected = (index: number) => selectedIndexes.includes(index);

  const handleSelectAll = () => {
    let newSelectedIndexes: number[];

    if (selectedIndexes.length < items.length) {
      // @ts-ignore
      newSelectedIndexes = [...Array(items.length).keys()];
    } else {
      newSelectedIndexes = [];
    }
  };

  const onItemRowClick = (item: ItemTableModel, rowIndex: number) => {
    if (onRowClick) {
      onRowClick(item);
      setSelectedRowIndex(rowIndex);
    }
  };

  const image = isExport ? exportImg : importImg;
  const emptyList = isExport ? i18n.t('imports.emptyExportTable.message') : i18n.t('exports.emptyImportTable.message');

  const emptyListText = isExport ? i18n.t('imports.creatNewExport.message') : i18n.t('exports.creatNewImport.message');

  if (!items.length) {
    return (
      <>
        {isExport || isImports ? (
          <FlexColumnCenter sx={{ marginTop: '40px' }}>
            <img src={image} alt="export" />
            <Typography>{emptyList}</Typography>
            <Typography fontSize={'14px'} color={COLOR_TEXT_BASE_PRIMARY}>
              {emptyListText}
            </Typography>
          </FlexColumnCenter>
        ) : (
          <Stack justifyContent={'center'} p={3} width={'100%'} flexDirection={'row'}>
            <Typography variant={'subtitle1'}>{i18n.t('common.sorry.noData.message')}</Typography>
          </Stack>
        )}
      </>
    );
  }

  return (
    <TableContainer sx={{ height: 'calc(100% - 200px)' }} ref={tableEl}>
      <Table stickyHeader>
        <TableHead sx={sxHeader}>
          <TableRow>
            {hasMultipleSelection && (
              <TableCell padding="checkbox" onClick={e => {}}>
                <Checkbox
                  color="primary"
                  indeterminate={selectedIndexes.length > 0 && selectedIndexes.length < items.length}
                  checked={selectedIndexes.length > 0 && selectedIndexes.length === items.length}
                  onClick={handleSelectAll}
                />
              </TableCell>
            )}
            {header.map((headerItem, index) => (
              <TableCell
                key={`header-item-${index}`}
                sx={{ width: headerItem.width, textTransform: 'initial', ...sxHeader }}
                align={index === header.length - 1 && !leftAlign ? 'right' : 'left'}
              >
                {headerItem.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, rowIndex) => (
            <ItemsTableListRow
              key={`table-row-${rowIndex}`}
              rowIndex={rowIndex}
              onClick={() => onItemRowClick(item, rowIndex)}
              selectedRowIndex={selectedRowIndex}
              hasMultipleSelection={hasMultipleSelection}
              onRowCheckClick={onRowCheckClick}
              isRowSelected={(selectedItem?.entityId || selectedItem?.id) && selectedRowIndex === rowIndex}
              header={header}
              leftAlign={leftAlign}
              item={item}
              allowDragAndDrop={allowDragAndDrop}
              moveAttachment={moveAttachment}
              index={rowIndex}
              allowBackgroundColor={allowBackgroundColor}
              sxCell={sxCell}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default observer(ItemsTableList);
