import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { ComplianceRule } from '../../../models/ComplianceModel';

const RadioButtonItem = ({
  handleChangeRadioValue,
  item,
  radioValue,
}: {
  handleChangeRadioValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  item: ComplianceRule;
  radioValue: string;
}) => {
  let checked = item.restrictionLevel === radioValue;
  if (radioValue === 'blocked' && !item.restrictionLevel) {
    checked = true;
  }
  return (
    <RadioGroup
      onChange={handleChangeRadioValue}
      aria-labelledby={item.id}
      name={item.id}
      key={item.id}
      sx={{ marginLeft: '30px' }}
    >
      <FormControlLabel
        value={radioValue}
        control={<Radio checkedIcon={<TripOriginIcon />} />}
        label={null}
        checked={checked}
      />
    </RadioGroup>
  );
};

export default RadioButtonItem;
