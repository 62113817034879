import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { observer } from 'mobx-react-lite';

import { COLOR_PRIMARY, COLOR_TEXT_PRIMARY, COLOR_WHITE } from '../../constants/colors';

import Flex from '../utils/flex/Flex';
import { useStore } from '../../hooks/useStore';
import { useNavigate, useParams } from 'react-router';
import { Paths } from '../../constants/routes';

const FolderCard = styled(Box)<{
  selected?: boolean;
}>`
  background: ${COLOR_WHITE};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 40px;
  padding: 12px;
  display: flex;
  align-items: center;
  flex: 0 0 312px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${props => (props.selected ? COLOR_PRIMARY : COLOR_TEXT_PRIMARY)};

  &:hover {
    color: ${COLOR_PRIMARY};
    cursor: pointer;
    padding: 16px;
  }
`;

const FoldersSection = () => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { knowledgeBases, setCurrentKnowledgeBase, getByTeamId },
  } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      if (id) {
        await getByTeamId(id);
      } else if (knowledgeBases?.length) {
        setCurrentKnowledgeBase(knowledgeBases[0]);
      }
    })();
  }, [id]);

  return (
    <>
      <Typography variant={'subtitle2'} sx={{ fontSize: '18px', fontWeight: 700, margin: '16px 0 20px 0' }}>{`${i18n.t(
        'knowledgeBase.folders.message'
      )}`}</Typography>

      <Flex sx={{ flexWrap: 'wrap' }}>
        {knowledgeBases?.map(item => (
          <FolderCard
            key={`folder-${item.name}`}
            onClick={() => {
              navigate(item.teamId ? `${Paths.KNOWLEDGE_BASE}/team/${item.teamId}` : Paths.KNOWLEDGE_BASE);
            }}
            selected={id ? id === item.teamId : item.name === 'Organization'}
          >
            <>
              <FolderOpenOutlinedIcon sx={{ marginRight: '11px' }} />
              {item.name}
            </>
          </FolderCard>
        ))}
      </Flex>
    </>
  );
};

export default observer(FoldersSection);
