import React, { useState } from 'react';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import Box from '@mui/material/Box';

import { useStore } from '../../hooks/useStore';
import UploadingToast from './UploadingToast';
import Button from '../buttons/Button';
import UploadFileModal from './UploadFileModal';

const UploadFile = () => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { uploadInProgress },
  } = useStore();
  const [files, setFiles] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);

  return (
    <Box>
      <Button
        onClick={() => setShowModal(true)}
        startIcon={<UploadOutlinedIcon />}
        sx={{
          padding: '9px 16px !important',
          height: '40px !important',
          width: 'fit-content',
          fontWeight: 400,
        }}
      >
        {i18n.t('knowledgeBase.button.uploadFile')}
      </Button>

      <UploadFileModal isOpen={showModal} onClose={() => setShowModal(false)} setFiles={setFiles} />
      {uploadInProgress && <UploadingToast files={files} />}
    </Box>
  );
};

export default UploadFile;
