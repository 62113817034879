import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useParams } from 'react-router';
import styled from 'styled-components';
import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import FileDetails from '../../../components/knowledgeBase/FileDetails';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import TabPanel from '../../../components/tabs/TabPanel';
import Flex from '../../../components/utils/flex/Flex';
import FlexRowEnd from '../../../components/utils/flex/FlexRowEnd';

import { COLOR_WHITE } from '../../../constants/colors';
import { SIZES_SMALL } from '../../../constants/sizes';

import { useStore } from '../../../hooks/useStore';
import { downloadDocumentSecurely } from '../../../utils/documentsS3';
import { getFileExtensionFromName } from '../../../utils/getFileExtensionFromName';
import { parseRedactionMapping, redactFileContent } from '../../../utils/redactText';
import { fileContentToRender } from '../components/Files/FilePreview';

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: scroll;
  padding: 24px;
  box-shadow:
    0 2px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const KnowledgeBaseFilePreview = () => {
  const {
    knowledgeBaseStore: { selectedFile, setSelectedFile, activeFileTab },
    fileStore: { getFileById },
    appState: { s3DocumentsApi },
    conversationStore: { getConversationsByFileId },
  } = useStore();
  const { fileId } = useParams();
  pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

  const [loading, setLoading] = useState(false);
  const [fileExtension, setFileExtension] = useState('');
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileContent, setFileContent] = useState<string[]>([]);
  const [pdfFilePreview, setPdfFilePreview] = useState('');

  useEffect(() => {
    if (fileId) {
      (async () => {
        await getConversationsByFileId(fileId);
        if (!selectedFile) {
          const foundFile = await getFileById(fileId);
          setSelectedFile(foundFile);
        }
      })();
    }
  }, [fileId]);

  const downloadFile = async (fileKey: string) => {
    const preSignedUrl = await s3DocumentsApi.generateDocumentsReadUrl(fileKey);

    const blob = await downloadDocumentSecurely(preSignedUrl.signedUrl);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const pdfBase64 = `data:application/pdf;base64,${base64String.slice(base64String.indexOf(',') + 1)}`;
      setPdfFilePreview(pdfBase64);
    };
  };

  useEffect(() => {
    if (!fileId) {
      return;
    }

    (async () => {
      setNumberOfPages(null);
      setPageNumber(1);
      setPdfFilePreview('');

      try {
        setLoading(true);
        const foundFile = await getFileById(fileId);

        if (foundFile.content) {
          const redactionMapping = parseRedactionMapping(foundFile.redactionMapping);
          const redactedContent = redactFileContent(foundFile.content, redactionMapping || []);
          const contentToDisplay = activeFileTab === 0 ? foundFile.content : redactedContent;

          let pageContentContainsLineContent = false;
          const fileExt = getFileExtensionFromName(foundFile.name);
          setFileExtension(fileExt);

          let pageContentArray = [];
          //for xls and xlsx files we use the text as html property to display the content
          if (['xls', 'xlsx', 'csv'].includes(fileExt)) {
            pageContentArray = JSON.parse(contentToDisplay).map((fileData: any) => fileData.metadata.text_as_html);
          } else {
            // for the rest of the files we use page content
            pageContentArray = pageContentArray = JSON.parse(contentToDisplay).map((fileData: any) => {
              if (
                fileExt === 'json' ||
                (fileData.metadata.filetype && fileData.metadata.filetype === 'text/markdown')
              ) {
                pageContentContainsLineContent = true;
              }

              return fileData.pageContent;
            });
          }

          const contentArray = pageContentContainsLineContent ? [pageContentArray.join('\n')] : pageContentArray;

          setFileContent(contentArray);
          setNumberOfPages(contentArray.length);
        }

        const fileExt = getFileExtensionFromName(foundFile.name);

        if (fileExt === 'pdf') {
          await downloadFile(foundFile.key);
        }
      } catch (e) {
        console.log('e ------------------->> ', e);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      setFileContent([]);
    };
  }, [fileId, activeFileTab]);

  if (loading) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <FullHeightScreenWrapper
      sx={{
        backgroundColor: 'transparent',
        height: 'calc(100vh - 56px)',
        flexDirection: 'row',
        padding: ' 0',
      }}
    >
      <FlexRowEnd>
        <Flex sx={{ padding: '40px 100px', height: '100%', flex: 1 }}>
          <PageContainer>
            <TabPanel value={activeFileTab} sx={{ flex: 1 }} index={activeFileTab}>
              {fileContentToRender(
                activeFileTab,
                setNumberOfPages,
                pageNumber,
                fileExtension,
                fileContent,
                pdfFilePreview,
                selectedFile
              )}
            </TabPanel>

            {!['csv', 'xls', 'xlsx'].includes(fileExtension) && (
              <Flex
                sx={{
                  backgroundColor: COLOR_WHITE,
                  width: '100%',
                  justifyContent: 'center',
                  padding: '16px 0',
                  position: 'sticky',
                  bottom: '-25px',
                  marginTop: 'auto',
                }}
              >
                <Pagination
                  count={numberOfPages || 1}
                  page={pageNumber}
                  onChange={(event: React.ChangeEvent<unknown>, value: number) => setPageNumber(value)}
                  size="small"
                />
              </Flex>
            )}
          </PageContainer>
        </Flex>
        <FileDetails numberOfPages={numberOfPages} />
      </FlexRowEnd>
    </FullHeightScreenWrapper>
  );
};

export default observer(KnowledgeBaseFilePreview);
