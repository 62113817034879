import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { SvgIconTypeMap, SxProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography from '@mui/material/Typography';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { COLOR_PRIMARY, GRAY_COLORS, COLOR_DIVIDER, COLOR_MANDATORY } from '../../../constants/colors';
import { SIZES_NUMBER_TINY_SMALL, SIZES_NUMBER_SMALL_MEDIUM } from '../../../constants/sizes';

import { useStore } from '../../../hooks/useStore';
import GenericCard from '../../cards/GenericCard';
import { Headline1, Headline3 } from '../../typography/Headlines';
import Flex from '../../utils/flex/Flex';
import FlexColumnStart from '../../utils/flex/FlexColumnStart';
import { useMediaQuery } from 'react-responsive';
import FilterComponent from './FilterComponent';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import FlexRowStart from '../../utils/flex/FlexRowStart';

interface ItemProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title: string;
  number?: number;
  newNumber?: number;
  additionalText?: string;
  id: string;
}

const IconContainer = styled.div`
  color: ${COLOR_PRIMARY};
  background: #f0f5fd;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const SummaryItem = ({
  item: { Icon, title, number, newNumber, additionalText },
  sx,
}: {
  item: ItemProps;
  sx: SxProps;
}) => (
  <Flex sx={{ flex: '50%', padding: '24px', ...sx }}>
    <IconContainer>
      <Icon sx={{ fontSize: '24px' }} />
    </IconContainer>
    <FlexColumnStart>
      <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_500} sx={{ whiteSpace: 'nowrap' }}>
        {title}
      </Typography>
      <FlexRowStart>
        {additionalText ? (
          <Typography
            variant={'subtitle2'}
            color={GRAY_COLORS.GRAY_500}
            sx={{ whiteSpace: 'nowrap', fontSize: '12px', marginTop: '8px' }}
          >
            {additionalText}
          </Typography>
        ) : (
          <Headline1>{Number(number)}</Headline1>
        )}
        {!additionalText && (
          <Typography
            variant={'subtitle2'}
            color={title !== 'Violations' ? '#0E9F6E' : COLOR_MANDATORY}
            sx={{ marginLeft: '4px' }}
          >{`+${Number(newNumber)} new`}</Typography>
        )}
      </FlexRowStart>
    </FlexColumnStart>
  </Flex>
);

const items = [
  {
    Icon: PersonOutlineOutlinedIcon,
    title: 'Active Users',
    number: 18,
    newNumber: 12,
    id: 'users',
  },
  {
    Icon: LiveHelpOutlinedIcon,
    title: 'Questions Asked',
    number: 240,
    newNumber: 2,
    id: 'questions',
  },
  {
    Icon: TextSnippetOutlinedIcon,
    title: 'Documents Added',
    number: 0,
    newNumber: 2,
    additionalText: '(Coming soon)',
    id: 'documents',
  },
  {
    Icon: WarningAmberOutlinedIcon,
    title: 'Violations',
    number: 15,
    newNumber: 3,
    id: 'violations',
  },
];

const SummaryCard = () => {
  const {
    localizationStore: { i18next: i18n },
    insightsStore: { getSummary, summary },
  } = useStore();
  const isMobile = useMediaQuery({ maxWidth: SIZES_NUMBER_TINY_SMALL });
  const isTablet = useMediaQuery({ maxWidth: SIZES_NUMBER_SMALL_MEDIUM, minWidth: SIZES_NUMBER_TINY_SMALL });
  const isDesktop = !isMobile && !isTablet;

  const [selectedFilter, setSelectedFilter] = useState<string>('allTime');

  useEffect(() => {
    (async () => {
      await getSummary(selectedFilter);
    })();
  }, [selectedFilter]);

  return (
    <GenericCard
      disableHover
      sx={{
        marginBottom: '24px',
      }}
    >
      <FlexRowSpaceBetween>
        <Headline3 margin={'0 0 16px 0'}>{i18n.t('adminPortal.insights.summary.subtitle')}</Headline3>
        <FilterComponent id={'top-prompts-filter'} setFilter={setSelectedFilter} value={selectedFilter} />
      </FlexRowSpaceBetween>

      <Flex sx={{ flexWrap: 'wrap' }}>
        {items?.map((item: ItemProps, index) => (
          <SummaryItem
            item={{
              ...item,
              // @ts-ignore
              number: summary && item?.id && summary[item?.id] && summary[item.id].total,
              // @ts-ignore
              newNumber: summary && item?.id && summary[item?.id] && summary[item.id].new,
            }}
            key={`summary-item-${index}`}
            sx={{
              borderRight: isDesktop && (index === 0 || index === 2) ? `1px solid ${COLOR_DIVIDER}` : 'unset',
              borderBottom: isDesktop && (index === 0 || index === 1) ? `1px solid ${COLOR_DIVIDER}` : 'unset',
            }}
          />
        ))}
      </Flex>
    </GenericCard>
  );
};

export default observer(SummaryCard);
