import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';

import { COLOR_PRIMARY, COLOR_UTILS_TERTIARY } from '../../../constants/colors';
import { Actions } from '../../../constants/Actions';

import FlexRowCenter from '../../utils/flex/FlexRowCenter';

const getIcon = (action: string) => {
  switch (action) {
    case Actions.create:
      return {
        backgroundColor: COLOR_UTILS_TERTIARY,
        icon: <InfoIcon sx={{ color: COLOR_PRIMARY, width: '16px', height: '16px' }} />,
      };
    case Actions.delete:
      return {
        backgroundColor: 'rgba(200, 30, 30, 0.15)',
        icon: <RemoveCircleIcon sx={{ color: 'rgba(200, 30, 30, 1)', width: '16px', height: '16px' }} />,
      };
    case Actions.share:
      return {
        backgroundColor: COLOR_UTILS_TERTIARY,
        icon: <ShareIcon sx={{ color: COLOR_PRIMARY, width: '16px', height: '16px' }} />,
      };
    case Actions.update:
      return {
        backgroundColor: COLOR_UTILS_TERTIARY,
        icon: <EditIcon sx={{ color: COLOR_PRIMARY, width: '16px', height: '16px' }} />,
      };
    default:
      return {
        backgroundColor: '',
        icon: null,
      };
  }
};
const IconByAction = ({ action }: { action: string }) => {
  const { backgroundColor, icon } = getIcon(action);

  return (
    <FlexRowCenter sx={{ backgroundColor, width: '32px', height: '28px', borderRadius: '4px', padding: '8px' }}>
      {icon}
    </FlexRowCenter>
  );
};

export default IconByAction;
