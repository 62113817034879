export const LocalStorageKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  expirationTime: 'expirationTime',
  signInProvider: 'signInProvider',
  userData: 'userData',
  userRole: 'userRole',
  Language: 'lang',
  tenant: 'tenant',
  auditLogTeamName: 'auditLogTeamName',
  googleDriveAccessToken: 'googleDriveAccessToken',
  governanceTeamName: 'governanceTeamName',
};
