import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { ItemsListView } from '../../../constants/itemsListView';
import { COLOR_WHITE, GRAY_COLORS } from '../../../constants/colors';
import { Roles } from '../../../constants/userRoles';
import { Paths } from '../../../constants/routes';
import { SIZES_SMALL } from '../../../constants/sizes';

import FullHeightScreenWrapper from '../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import { Headline1 } from '../../../components/typography/Headlines';
import { useStore } from '../../../hooks/useStore';
import TextInput from '../../../components/inputs/TextInput';
import { ViewModeButtons } from '../../../components/buttons/ViewModeButtons';
import FlexRowSpaceBetween from '../../../components/utils/flex/FlexRowSpaceBetween';
import FileMenu from '../../../components/knowledgeBase/FileMenu';
import RenameModal from '../../../components/knowledgeBase/RenameModal';
import DeleteModal from '../../../components/knowledgeBase/DeleteModal';
import CardsView from '../../../components/knowledgeBase/CardsView';
import ListView from '../../../components/knowledgeBase/ListView';
import UploadFile from '../../../components/knowledgeBase/UploadFile';
import FoldersSection from '../../../components/knowledgeBase/FoldersSection';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { File } from '../../../models/File';
import { ConversationTypes } from '../../../constants/conversationTypes';

const inputStyle = {
  width: '100%',
  marginRight: '20px',
  '.MuiInputBase-root': {
    height: '40px',
  },
  '.MuiOutlinedInput-input': {
    padding: '10px 14px',
    width: '100%',
  },
};

const PageContainer = styled(Box)`
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
  background: ${COLOR_WHITE};
  border-radius: 8px;
  overflow: scroll;
  padding: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const KnowledgeBasePage = () => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { userData },
    knowledgeBaseStore: {
      getKnowledgeBaseByOrganization,
      knowledgeBases,
      showFilePreview,
      setSelectedFile,
      selectedFile,
      getKnowledgeBaseByTeam,
      getAllKnowledgeBasesByOrganization,
      uploadInProgress,
      updateFileName,
      deleteFile,
      currentKnowledgeBase,
    },
    conversationStore: { createConversation, conversationSettingsState },
  } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchValue, setSearchValue] = useState<string>('');
  const [currentSelection, setCurrentSelection] = useState(ItemsListView.CARDS);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const open = Boolean(anchorEl);

  useEffect(() => {
    (async () => {
      await getAllKnowledgeBasesByOrganization();
      await getKnowledgeBaseByOrganization();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!uploadInProgress) {
      (async () => {
        await getAllKnowledgeBasesByOrganization();
        if (id) {
          await getKnowledgeBaseByTeam(id);
        } else {
          await getKnowledgeBaseByOrganization();
        }
        setLoading(false);
      })();
    }
  }, [id, uploadInProgress, loading]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, file: File) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedFile(file);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
  };

  const changeCurrentSelection = (newSelection: string) => {
    setCurrentSelection(newSelection);
  };

  const onPreviewFile = (file: File) => {
    setSelectedFile(file);
    navigate(`${Paths.KNOWLEDGE_BASE}/${file.id}`);
  };

  const onSaveUpdate = async (fileId: string, name: string) => {
    setLoading(true);
    await updateFileName(fileId, name);
    setLoading(false);
    setAnchorEl(null);
  };

  const onDeleteFile = async () => {
    if (selectedFile && currentKnowledgeBase) {
      setLoading(true);
      await deleteFile(selectedFile.id, currentKnowledgeBase.id);
      setLoading(false);
      setAnchorEl(null);
    }
  };

  const startNewConversation = async () => {
    if (selectedFile) {
      const conversation = await createConversation({
        type: ConversationTypes.Chat,
        model: 'gpt-4-file',
        temperature: conversationSettingsState.accuracy,
        maxTokens: conversationSettingsState.length,
        fileId: selectedFile?.id,
        chat: {
          files: [{ id: selectedFile?.id, name: selectedFile?.name }],
        },
      });

      if (conversation) {
        navigate(`${Paths.CHAT}/${conversation.id}`, { state: { dontRefresh: true } });
      }
    }
  };

  return (
    <FullHeightScreenWrapper
      sx={{ backgroundColor: 'transparent', height: 'calc(100vh - 56px)', flexDirection: 'row' }}
    >
      <PageContainer sx={{ width: showFilePreview ? '60%' : '100%' }}>
        {userData.role !== Roles.member ? (
          <FlexRowSpaceBetween>
            <Headline1>{`${i18n.t('sidebarMenu.knowledgeBase.action')}`}</Headline1>
            <UploadFile />
          </FlexRowSpaceBetween>
        ) : (
          <Headline1>{`${i18n.t('sidebarMenu.knowledgeBase.action')}`}</Headline1>
        )}

        <FlexRowSpaceBetween sx={{ marginTop: '24px' }}>
          <TextInput
            id='search-input'
            value={searchValue}
            onChange={onInputChange}
            placeholder={'Search'}
            name={'searchFilter'}
            variant={'outlined'}
            isSearchField
            sx={{ ...inputStyle }}
          />
          <ViewModeButtons currentSelection={currentSelection} changeValue={changeCurrentSelection} />
        </FlexRowSpaceBetween>

        {loading ? (
          <Box>
            <LoadingSpinner />
          </Box>
        ) : (
          <>
            {knowledgeBases?.length ? <FoldersSection /> : null}

            {currentSelection === ItemsListView.CARDS ? (
              <CardsView handleClick={handleClick} onPreviewFile={onPreviewFile} />
            ) : (
              <ListView handleClick={handleClick} onPreviewFile={onPreviewFile} />
            )}
          </>
        )}

        <FileMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          options={[
            {
              name: i18n.t('knowledgeBase.menu.startNewChat'),
              icon: <AddIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
              onClick: startNewConversation,
            },
            {
              name: i18n.t('knowledgeBase.menu.rename'),
              icon: <EditIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
              onClick: () => setShowRenameModal(true),
            },
            {
              name: i18n.t('knowledgeBase.menu.delete'),
              icon: <DeleteIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
              onClick: () => setShowDeleteModal(true),
              itemSx: { borderTop: `1px solid ${GRAY_COLORS.GRAY_2}` },
            },
          ]}
        />
      </PageContainer>

      <RenameModal isOpen={showRenameModal} onClose={() => setShowRenameModal(false)} onSave={onSaveUpdate} />
      <DeleteModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} onSave={onDeleteFile} />
    </FullHeightScreenWrapper>
  );
};

export default observer(KnowledgeBasePage);
