import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import * as moment from 'moment';
import styled from 'styled-components';

import { GRAY_COLORS } from '../../../constants/colors';
import { Roles } from '../../../constants/userRoles';

import Flex from '../../utils/flex/Flex';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import { useStore } from '../../../hooks/useStore';
import UserAvatar from '../../UserAvatar/UserAvatar';
import FlexRowCenter from '../../utils/flex/FlexRowCenter';
import FlexRowEnd from '../../utils/flex/FlexRowEnd';
import ViewModeSimpleRow from '../../viewMode/ViewModeSimpleRow';
import { Team } from '../../../models/Team';
import FlexColumnStart from '../../utils/flex/FlexColumnStart';
import RolesDropdown from '../../role/RolesDropdown';
import ActionButton from './ActionButton';
import TeamStatisticsItem from '../teams/teamCards/teamStatistics/TeamStatisticsItem';
import RoleLabel from '../../role/RoleLabel';
import IconByAction from '../auditLogs/IconByAction';
import FlexRowStart from '../../utils/flex/FlexRowStart';

const UserPersonalDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

interface TeamRowProps {
  team: Team;
  onRemoveClick: (team: Team) => void;
}

const TeamRow = ({ team, onRemoveClick }: TeamRowProps) => {
  return (
    <FlexRowEnd sx={{ width: '100%', marginBottom: '12px' }}>
      <Typography variant={'subtitle2'} fontWeight={400}>
        {team.name}
      </Typography>
      <IconButton onClick={() => onRemoveClick(team)}>
        <RemoveCircleOutlineOutlinedIcon sx={{ width: '14px', height: '14px' }} />
      </IconButton>
    </FlexRowEnd>
  );
};

interface DetailsProps {
  onCloseDetailPanel: () => void;
  userId: string | null;
  onChangeRole?: (arg1: string, arg2: any) => void;
  openActionModal?: (arg1: any) => void;
  onDeleteTeam?: (arg1: Team) => void;
}

const UserDetailPanel = ({ onCloseDetailPanel, userId, onChangeRole, openActionModal, onDeleteTeam }: DetailsProps) => {
  const {
    localizationStore: { i18next: i18n },
    userStore: { getUserDataById, selectedUser },
    auditLogsStore: { currentAuditLog },
  } = useStore();

  useEffect(() => {
    if (userId) {
      (async () => {
        await getUserDataById(userId);
      })();
    }
  }, [userId]);

  const onRemoveTeam = (team: Team) => {
    onDeleteTeam?.(team);
  };

  return (
    <Stack sx={{ padding: '24px' }}>
      {currentAuditLog?.id ? (
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {selectedUser?.fullName}
          </Typography>
          <IconButton size={'small'} onClick={onCloseDetailPanel}>
            <Close />
          </IconButton>
        </Box>
      ) : (
        <IconButton onClick={onCloseDetailPanel} size={'small'} sx={{ marginLeft: 'auto' }}>
          <Close fontSize={'small'} />
        </IconButton>
      )}

      <FlexRowSpaceBetween sx={currentAuditLog?.id ? { width: '100%', marginBottom: '24px' } : { width: '100%' }}>
        <FlexRowCenter sx={{ gap: ' 12px' }}>
          <UserAvatar user={selectedUser} size={currentAuditLog?.id ? 24 : 40} avatar={selectedUser?.profilePicture} />
          <UserPersonalDetails>
            {!currentAuditLog?.id && (
              <Typography variant="h6" sx={{ fontWeight: 700, marginRight: '12px', fontSize: '18px' }}>
                {selectedUser?.fullName}
              </Typography>
            )}
            <Typography variant="subtitle2" color={GRAY_COLORS.GRAY_5} sx={{ fontWeight: 400 }}>
              {selectedUser?.position}
            </Typography>
          </UserPersonalDetails>
        </FlexRowCenter>
        {openActionModal && (
          <ActionButton
            isPending={selectedUser?.isPendingInvite || false}
            archived={selectedUser?.archived || false}
            onClick={() => openActionModal(selectedUser)}
            applyBorders
          />
        )}
      </FlexRowSpaceBetween>
      {openActionModal ? (
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', marginTop: '40px' }}>
          <TeamStatisticsItem
            Icon={LightbulbIcon}
            title={i18n.t('teamList.details.prompts')}
            amount={selectedUser?.summaryDetails?.totalPrompts || 0}
          />
          <TeamStatisticsItem
            Icon={QuestionAnswerIcon}
            title={i18n.t('teamList.details.chats')}
            amount={selectedUser?.summaryDetails?.totalChats || 0}
          />
          <TeamStatisticsItem
            Icon={ReportProblemIcon}
            title={i18n.t('teamList.details.violations')}
            amount={selectedUser?.summaryDetails?.totalViolations || 0}
          />
        </Flex>
      ) : (
        <>
          {currentAuditLog?.action && (
            <FlexRowStart sx={{ gap: '12px', marginBottom: '24px' }}>
              <IconByAction action={currentAuditLog?.action} />
              <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
                {`This user was ${currentAuditLog?.action}d by ${currentAuditLog.userName} on ${moment
                  .tz(currentAuditLog?.createdAt, 'EST')
                  .format('MMM DD, YYYY')}`}
              </Typography>
            </FlexRowStart>
          )}
        </>
      )}
      <ViewModeSimpleRow
        title={i18n.t('usersDetails.role.label')}
        textNode={
          onChangeRole ? (
            <RolesDropdown
              value={selectedUser.role}
              onChange={value => onChangeRole(value, selectedUser)}
              disabled={[Roles.admin]}
            />
          ) : (
            <RoleLabel type={selectedUser.role} />
          )
        }
      />
      <ViewModeSimpleRow title={i18n.t('usersDetails.email.label')} text={selectedUser?.email} />
      <ViewModeSimpleRow
        title={i18n.t('usersDetails.lastActive.label')}
        text={selectedUser?.lastActive && moment.tz(selectedUser?.lastActive, 'EST').format('MM.DD.YYYY \n hh:mm A')}
      />
      <ViewModeSimpleRow title={i18n.t('usersDetails.addedBy.label')} text={selectedUser?.addedBy} />
      <ViewModeSimpleRow
        title={i18n.t('usersDetails.teams.label')}
        noBorder
        text={(currentAuditLog?.id && selectedUser.teams?.map(team => team.name).join(', ')) || ''}
        textNode={
          onDeleteTeam && (
            <FlexColumnStart>
              {selectedUser?.teams?.map((team, index) => (
                <TeamRow key={`user-details-team-${index}`} team={team} onRemoveClick={() => onRemoveTeam(team)} />
              ))}
            </FlexColumnStart>
          )
        }
      />
    </Stack>
  );
};

export default observer(UserDetailPanel);
