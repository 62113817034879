import Stack from '@mui/material/Stack';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import { Prompt } from '../../../models/Prompt';
import { getTextOverflowEllipsisStyles } from '../../../components/typography/utils';

export const PromptItem = styled(Box)`
  width: 70%;
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(239, 241, 247, 0.38);

  &:hover {
    background-color: rgba(239, 241, 247, 0.58);
  }
`;

const ConversationEmptyState = () => {
  const {
    promptsStore: { conversationEmptyStatePrompts },
    conversationStore: { setMessageInput, setSelectedPromptId },
  } = useStore();

  const handleClickPrompt = (prompt: Prompt) => {
    setMessageInput(prompt.content);
    setSelectedPromptId(prompt.id);
  };

  return (
    <Stack sx={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
      <LightbulbOutlinedIcon sx={{ fill: GRAY_COLORS.GRAY_700 }} />
      <Typography variant={'body1'} sx={{ color: GRAY_COLORS.GRAY_700 }}>
        Useful Prompts
      </Typography>
      {Array.isArray(conversationEmptyStatePrompts) &&
        conversationEmptyStatePrompts.map(prompt => (
          <PromptItem onClick={() => handleClickPrompt(prompt)} key={`empty-state-prompt-${prompt.id}`}>
            <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_700, ...getTextOverflowEllipsisStyles(2) }}>
              {`"${prompt.content}"`}
            </Typography>
          </PromptItem>
        ))}
    </Stack>
  );
};

export default ConversationEmptyState;
