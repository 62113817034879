import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { IconButton, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';

import { COLOR_BLACK, COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { useStore } from '../../hooks/useStore';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import FlexColumnStart from '../utils/flex/FlexColumnStart';
import FlexRowStart from '../utils/flex/FlexRowStart';
import Flex from '../utils/flex/Flex';
import { getIconByName, TypeIcon } from './utils';
import CheckCircle from '../../assets/icons/check_circle_green.svg';
import CloseCircle from '../../assets/icons/close.svg';

import { LoadingSpinner } from '../spinner/LoadingSpinner';

const Container = styled.div`
  display: flex;
  width: 358px;
  padding: 16px 0px 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: ${COLOR_WHITE};
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 24px;
  bottom: 24px;
`;

export const Divider = styled(Box)`
  height: 1px;
  width: 100%;
  background: ${GRAY_COLORS.GRAY_2};
  margin: 16px 0px 12px 0px;
`;
const UploadingHeader = ({
  i18n,
  isExpanded,
  setIsExpanded,
}: {
  i18n: any;
  isExpanded: boolean;
  setIsExpanded: (arg: boolean) => void;
}) => (
  <FlexRowSpaceBetween
    sx={{ padding: '0 16px', width: '100%', cursor: 'pointer' }}
    onClick={() => setIsExpanded(!isExpanded)}
  >
    <Typography variant="subtitle2" sx={{ color: COLOR_BLACK }}>
      {i18n.t('knowledgeBase.uploading.title')}
    </Typography>
    {isExpanded ? (
      <KeyboardArrowUpIcon sx={{ color: GRAY_COLORS.GRAY_5 }} />
    ) : (
      <KeyboardArrowDownIcon sx={{ color: GRAY_COLORS.GRAY_5 }} />
    )}
  </FlexRowSpaceBetween>
);

const getStateIcon = (type: string) => {
  switch (type) {
    case 'done':
      return <img src={CheckCircle} alt={'check-circle-icon'} />;
    case 'loading':
      return <LoadingSpinner width={'20px'} contained={true} />;
    case 'hover-loading':
      return (
        <IconButton
          sx={{ cursor: 'pointer', background: '#F3F4F6', borderRadius: '50%', width: '22px', height: '22px' }}
        >
          <img src={CloseCircle} alt={'CloseCircle'} />
        </IconButton>
      );
    default:
      return;
  }
};

const UploadingToast = ({ files }: { files: any[] }) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { uploadInProgress },
  } = useStore();

  const [isExpanded, setIsExpanded] = useState(true);
  const [isItemHovered, setIsHovered] = useState(-1);

  return (
    <Container>
      {!isExpanded ? (
        <UploadingHeader i18n={i18n} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      ) : (
        <FlexColumnStart sx={{ width: '100%' }}>
          <UploadingHeader i18n={i18n} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />

          <Flex sx={{ flexDirection: 'column', width: '100%', gap: '16px', marginTop: '16px', padding: '0 16px' }}>
            {files?.map((item, index) => (
              <Box onMouseEnter={() => setIsHovered(index)} onMouseLeave={() => setIsHovered(-1)}>
                <FlexRowSpaceBetween>
                  <FlexRowStart>
                    <TypeIcon src={getIconByName(item.name)} alt={item.name} />
                    <Typography variant="body2" sx={{ color: COLOR_BLACK }}>
                      {item.name}
                    </Typography>
                  </FlexRowStart>

                  {getStateIcon(
                    uploadInProgress && isItemHovered === index
                      ? 'hover-loading'
                      : uploadInProgress
                      ? 'loading'
                      : 'done'
                  )}
                </FlexRowSpaceBetween>
              </Box>
            ))}
          </Flex>

          <Divider />
          <FlexRowSpaceBetween sx={{ padding: '0 16px ', width: '100%', cursor: 'pointer' }}>
            <Typography variant="body2" sx={{ color: GRAY_COLORS.GRAY_500 }}>
              1/1 Files
            </Typography>
            <Typography variant="subtitle2" sx={{ color: COLOR_PRIMARY }}>
              {i18n.t('common.cancel.action')}
            </Typography>
          </FlexRowSpaceBetween>
        </FlexColumnStart>
      )}
    </Container>
  );
};

export default observer(UploadingToast);
