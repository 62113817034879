import { ApiConfig } from './ApiConfig';
import { CreateModelEvaluationDto } from '../models/ModelEvaluation';

export class ModelEvaluationApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  create(modelEvaluation: CreateModelEvaluationDto): Promise<CreateModelEvaluationDto> {
    return this.apiConfig.sendRequest('POST', '/model-evaluation', modelEvaluation);
  }

  listAll() {
    return this.apiConfig.sendRequest('GET', '/model-evaluation');
  }

  evaluationJobOutput(jobData: any) {
    return this.apiConfig.sendRequest('POST', '/model-evaluation/output-file', jobData);
  }
}
