import { makeAutoObservable } from 'mobx';
import { UserApi } from '../api/UserApi';
import { ApiConfig } from '../api/ApiConfig';
import { OrganizationApi } from '../api/OrganizationApi';
import { PromptsApi } from '../api/PromptsApi';
import { ConversationApi } from '../api/ConversationApi';
import { TeamApi } from '../api/TeamApi';
import { AuditLogsApi } from '../api/AuditLogsApi';
import { InsightsApi } from '../api/InsightsApi';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { ComplianceApi } from '../api/ComplianceApi';
import { getApiEndpointFromHost, getTenantFromHost, isTenantDomainSet } from '../utils/tenantUtils';
import { ChatApi } from '../api/ChatApi';
import { ModelApi } from '../api/ModelApi';
import { S3DocumentsApi } from '../api/S3DocumentsApi';
import { FileApi } from '../api/FileApi';
import { IntegrationApi } from '../api/IntegrationApi';
import { KnowledgeBaseApi } from '../api/KnowledgeBaseApi';
import { GoogleDriveApi } from '../api/GoogleDriveApi';
import { ModelEvaluationApi } from '../api/ModelEvaluationApi';
import { TokenApi } from '../api/TokenApi';
import { WorkSpaceApi } from '../api/WorkSpaceApi';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export class AppState {
  apiConfig: ApiConfig;
  userApi: UserApi;
  organizationApi: OrganizationApi;
  modelApi: ModelApi;
  promptsApi: PromptsApi;
  conversationApi: ConversationApi;
  teamApi: TeamApi;
  auditLogsApi: AuditLogsApi;
  insightsApi: InsightsApi;
  complianceApi: ComplianceApi;
  chatApi: ChatApi;
  s3DocumentsApi: S3DocumentsApi;
  googleDriveApi: GoogleDriveApi;
  fileApi: FileApi;
  integrationsApi: IntegrationApi;
  knowledgeBaseApi: KnowledgeBaseApi;
  modelEvaluationApi: ModelEvaluationApi;
  tokenApi: TokenApi;
  WorkSpaceApi: WorkSpaceApi;

  constructor() {
    const tenant = getTenantFromHost();
    const apiEndpoint = REACT_APP_API_URL ?? getApiEndpointFromHost();

    if (isTenantDomainSet()) {
      localStorage.setItem(LocalStorageKeys.tenant, tenant);
    }

    this.apiConfig = new ApiConfig(apiEndpoint, '1.0');
    this.userApi = new UserApi(this.apiConfig);
    this.organizationApi = new OrganizationApi(this.apiConfig);
    this.modelApi = new ModelApi(this.apiConfig);
    this.promptsApi = new PromptsApi(this.apiConfig);
    this.conversationApi = new ConversationApi(this.apiConfig);
    this.teamApi = new TeamApi(this.apiConfig);
    this.auditLogsApi = new AuditLogsApi(this.apiConfig);
    this.insightsApi = new InsightsApi(this.apiConfig);
    this.complianceApi = new ComplianceApi(this.apiConfig);
    this.chatApi = new ChatApi(this.apiConfig);
    this.s3DocumentsApi = new S3DocumentsApi(this.apiConfig);
    this.googleDriveApi = new GoogleDriveApi(this.apiConfig);
    this.fileApi = new FileApi(this.apiConfig);
    this.integrationsApi = new IntegrationApi(this.apiConfig);
    this.knowledgeBaseApi = new KnowledgeBaseApi(this.apiConfig);
    this.modelEvaluationApi = new ModelEvaluationApi(this.apiConfig);
    this.tokenApi = new TokenApi(this.apiConfig);
    this.WorkSpaceApi = new WorkSpaceApi(this.apiConfig);
    makeAutoObservable(this);
  }
}
