import React from 'react';

import { SupportedAIProvidersEnum } from '../../constants/supportedAIProvidersEnum';
import amazonIcon from '../../assets/images/supportedAiProviders/amazonIcon.svg';
import ai21LabsIcon from '../../assets/images/supportedAiProviders/ai21LabsIcon.svg';
import anthropicIcon from '../../assets/images/supportedAiProviders/anthropicIcon.svg';
import azureIcon from '../../assets/images/supportedAiProviders/azureIcon.svg';
import openAiIcon from '../../assets/images/supportedAiProviders/openAiIcon.svg';
import cohereIcon from '../../assets/images/supportedAiProviders/cohereIcon.webp';
import renaissanceIcon from '../../assets/images/supportedAiProviders/renaissanceLogo.svg';
import stabilityAiIcon from '../../assets/images/supportedAiProviders/stabilityAiIcon.png';
import SageMakerIcon from '../../assets/images/supportedAiProviders/sageMakerIcon.svg';
import MetaIcon from '../../assets/images/supportedAiProviders/metaIcon.svg';
import AVMLogoBlackIcon from '../../assets/images/supportedAiProviders/AVMLogoBlackIcon.svg';
import brotherhoodIcon from '../../assets/images/supportedAiProviders/brotherhood.png';

interface SupportedAiProviderLogoProps {
  provider: string;
  size: number;
  style?: React.CSSProperties;
}

const getImageUrl = (provider: string) => {
  switch (provider) {
    case SupportedAIProvidersEnum.ai21Labs:
      return ai21LabsIcon;
    case SupportedAIProvidersEnum.amazon:
      return amazonIcon;
    case SupportedAIProvidersEnum.anthropic:
      return anthropicIcon;
    case SupportedAIProvidersEnum.azure:
      return azureIcon;
    case SupportedAIProvidersEnum.cohere:
      return cohereIcon;
    case SupportedAIProvidersEnum.renaissance:
      return renaissanceIcon;
    case SupportedAIProvidersEnum.open_ai:
      return openAiIcon;
    case SupportedAIProvidersEnum.meta:
      return MetaIcon;
    case SupportedAIProvidersEnum.stabilityAi:
      return stabilityAiIcon;
    case SupportedAIProvidersEnum.sageMaker:
      return SageMakerIcon;
    case SupportedAIProvidersEnum.brotherhood:
      return brotherhoodIcon;
    default:
      return AVMLogoBlackIcon;
  }
};

const SupportedAiProviderLogo = ({ provider, size, style }: SupportedAiProviderLogoProps) => (
  <img
    src={getImageUrl(provider)}
    style={{ height: `${size}px`, width: `${size}px`, maxHeight: `${size}px`, maxWidth: `${size}px`, ...style }}
    alt={provider}
  />
);

export default SupportedAiProviderLogo;
