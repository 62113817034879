// Common interface for user token properties
interface CommonUserToken {
  userId: string;
  refreshToken: string;
  tokenName: string;
  displayToken: string;
  expiryAt: string;
}

export interface UserToken extends CommonUserToken {}

// Extended user token interface with additional properties
export interface ExtendedUserToken extends CommonUserToken {
  id: string;
  createdAt: string;
  userName: string;
  accessToken: string;
  disabled: boolean;
}

export const INITIAL_USER_TOKEN: UserToken = {
  userId: '',
  tokenName : '',
  refreshToken: '',
  displayToken: '',
  expiryAt: '',
};

// Initial/default values for ExtendedUserToken
export const INITIAL_EXTENDED_USER_TOKEN: ExtendedUserToken = {
  id: '',
  userId: '',
  userName : '',
  refreshToken: '',
  tokenName: '',
  displayToken: '',
  accessToken: '',
  expiryAt: '',
  createdAt: '',
  disabled: false,
};