import { File } from './File';

export interface KnowledgeBaseModel {
  id: string;
  name: string;
  teamId?: string;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  isOrganization: boolean;
  files: File[];
}

export const EMPTY_KNOWLEDGE_MODEL = {
  id: '',
  name: 'Organization',
  teamId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deleted: false,
  isOrganization: false,
  files: [],
};

export interface CreateKnowledgeBaseDto {
  name: string;
  teamId?: string;
  isOrganization?: boolean;
  files: [
    {
      id: string;
      name: string;
    }
  ];
}
