import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import styled from 'styled-components';
import IconByAction from '../auditLogs/IconByAction';
import { COLOR_WHITE } from '../../../constants/colors';
import { getInfoDescription } from '../auditLogs/ConversationDetailPage';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { redactText } from '../../../utils/redactText';
import { RestrictionsEnum } from '../../../constants/restrictionsEnum';

type Interval = {
  Text?: string;
  Category?: string;
  startOffset?: number;
  endOffset?: number;
};

type CompletionDetailPanelProps = {
  closeModal: () => void;
};

type IntervalTextProps = {
  intervals: Interval[];
  type: 'blocked' | 'warned' | 'anonymized';
};

const IntervalText: React.FC<IntervalTextProps> = ({ intervals, type }) => {
  const getIntervalStyle = (): React.CSSProperties => {
    switch (type) {
      case 'blocked':
        return { color: '#C81E1E', fontWeight: 'bold' };
      case 'warned':
        return { color: '#E3A008', fontWeight: 'bold' };
      default:
        return {};
    }
  };

  return (
    <ListContainer>
      {intervals.map((interval, index) => (
        <ListItem key={index}>
          <span>•</span>
          <span style={getIntervalStyle()}>{interval.Text || `Category: ${interval.Category}`}</span>
        </ListItem>
      ))}
    </ListContainer>
  );
};

const CompletionDetailPanel: React.FC<CompletionDetailPanelProps> = ({ closeModal }) => {
  const {
    localizationStore: { i18next: i18n },
    auditLogsStore: { currentAuditLog },
  } = useStore();

  const [showMessage, setShowMessage] = useState(false);
  const [showBlockedIntervals, setShowBlockedIntervals] = useState(false);
  const [showWarnedIntervals, setShowWarnedIntervals] = useState(false);
  const [showAnonymizedIntervals, setShowAnonymizedIntervals] = useState(false);

  if (!currentAuditLog?.additionalInformation) {
    return <p>{i18n.t('adminPortal.auditLog.completion.detailedView.noData')}</p>;
  }

  const {
    source = i18n.t('adminPortal.auditLog.completion.detailedView.unknown'),
    isUserChat = false,
    message = '',
    wasBlocked = false,
    blockedTextIntervals = [],
    wasWarned = false,
    warnedTextIntervals = [],
    wasAnonymized = false,
    anonymizedTextIntervals = [],
    errorMessage = '',
  } = currentAuditLog.additionalInformation;

  const getRedactedText = (): string => {
    if (wasBlocked) {
      return redactText(message, blockedTextIntervals || [], RestrictionsEnum.BLOCKED, true);
    }

    if (wasWarned) {
      return redactText(message, warnedTextIntervals || [], RestrictionsEnum.WARNING, true);
    }

    return redactText(message, anonymizedTextIntervals || [], RestrictionsEnum.ANONYMIZE, true);
  };

  const restrictedChat = (
    <div
      dangerouslySetInnerHTML={{
        __html: getRedactedText(),
      }}
    />
  );

  return (
    <Container>
      <Header>
        <Title>{i18n.t('adminPortal.auditLog.panels.completion.title')}</Title>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Header>

      {errorMessage && (
        <ErrorMessage>
          <strong>{i18n.t('adminPortal.auditLog.completion.detailedView.errorMessage')}:</strong> {errorMessage}
        </ErrorMessage>
      )}

      {currentAuditLog?.action && (
        <Section>
          <IconByAction action={currentAuditLog?.action} />
          <span style={{ marginLeft: '8px' }}>{getInfoDescription(currentAuditLog)}</span>
        </Section>
      )}

      <Section>
        <SectionLabel>{i18n.t('adminPortal.auditLog.completion.detailedView.source')}:</SectionLabel>
        <span>{source}</span>
      </Section>

      <Section showIntervals={showBlockedIntervals}>
        <SectionLabel>{i18n.t('adminPortal.auditLog.completion.detailedView.wasBlocked')}:</SectionLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{wasBlocked ? 'Yes' : 'No'}</span>
          {wasBlocked && blockedTextIntervals.length > 0 && (
            <IconButton onClick={() => setShowBlockedIntervals(!showBlockedIntervals)}>
              {showBlockedIntervals ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          )}
        </div>
      </Section>
      {showBlockedIntervals && <IntervalText intervals={blockedTextIntervals} type="blocked" />}

      <Section showIntervals={showWarnedIntervals}>
        <SectionLabel>{i18n.t('adminPortal.auditLog.completion.detailedView.wasWarned')}:</SectionLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{wasWarned ? 'Yes' : 'No'}</span>
          {wasWarned && warnedTextIntervals.length > 0 && (
            <IconButton onClick={() => setShowWarnedIntervals(!showWarnedIntervals)}>
              {showWarnedIntervals ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          )}
        </div>
      </Section>
      {showWarnedIntervals && <IntervalText intervals={warnedTextIntervals} type="warned" />}

      <Section showIntervals={showAnonymizedIntervals}>
        <SectionLabel>{i18n.t('adminPortal.auditLog.completion.detailedView.wasAnonymized')}:</SectionLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{wasAnonymized ? 'Yes' : 'No'}</span>
          {wasAnonymized && anonymizedTextIntervals.length > 0 && (
            <IconButton onClick={() => setShowAnonymizedIntervals(!showAnonymizedIntervals)}>
              {showAnonymizedIntervals ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          )}
        </div>
      </Section>
      {showAnonymizedIntervals && <IntervalText intervals={anonymizedTextIntervals} type="anonymized" />}

      <Section>
        <SectionLabel>{i18n.t('adminPortal.auditLog.completion.detailedView.message')}:</SectionLabel>
        <IconButton onClick={() => setShowMessage(!showMessage)}>
          {showMessage ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </Section>
      {showMessage && restrictedChat}
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.h6`
  font-size: 18px;
  font-weight: 700;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const Section = styled.div<{ showIntervals?: boolean }>`
  margin-bottom: ${props => (props.showIntervals ? '0' : '12px')};
  display: flex;
  align-items: center;
`;

const SectionLabel = styled.strong`
  margin-right: 8px;
`;

const ErrorMessage = styled.p`
  color: red;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 12px;
`;

const ListContainer = styled.ul`
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-size: 16px;

  & > span {
    margin-left: 8px;
    color: ${props => props.color || '#555'};
  }
`;

export default observer(CompletionDetailPanel);
