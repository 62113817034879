// tokenStore.ts

import autoBind from 'auto-bind';
import { makeAutoObservable, runInAction } from 'mobx';

import { TokenApi } from '../api/TokenApi';
import { ExtendedUserToken, UserToken, INITIAL_EXTENDED_USER_TOKEN } from '../models/Token';

export class TokenStore {
  tokenApi: TokenApi;
  tokenList: Array<ExtendedUserToken> = [];
  selectedToken: ExtendedUserToken = INITIAL_EXTENDED_USER_TOKEN;

  constructor(tokenApi: TokenApi) {
    this.tokenApi = tokenApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedToken(token: ExtendedUserToken) {
    runInAction(() => {
      this.selectedToken = token;
    });
  }

  clearSelectedToken() {
    runInAction(() => {
      this.selectedToken = INITIAL_EXTENDED_USER_TOKEN;
    });
  }

  async getTokensByUserOrganization() {
    const tokenList = await this.tokenApi.getTokensByUserOrganization();

    runInAction(() => {
      this.tokenList = tokenList;
    });

    return tokenList;
  }

  async createToken(tokenDto: UserToken) {
    const result = await this.tokenApi.createToken(tokenDto);

    if (result?.errors) {
      return result;
    }

    await this.getTokensByUserOrganization();
  }

  async updateToken(id: string | undefined, updateTokenDto: Partial<UserToken>) {
    if (!id) {
      return;
    }

    const result = await this.tokenApi.updateToken(id, updateTokenDto);
    if (result?.errors) {
      return result;
    }

    runInAction(() => {
      if (this.selectedToken.id === id) {
        this.selectedToken = result;
      }
    });

    await this.getTokensByUserOrganization();
  }

  async getTokenById(id: string) {
    const token = await this.tokenApi.getTokenById(id);
    this.setSelectedToken(token);
  }

  async removeToken(id: string) {
    await this.tokenApi.removeToken(id);

    const updatedTokenList = this.tokenList.filter(token => token.id !== id);

    runInAction(() => {
      this.tokenList = updatedTokenList;
      this.selectedToken = INITIAL_EXTENDED_USER_TOKEN;
    });
  }
}
