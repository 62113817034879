import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import Joi from 'joi';
import { COLOR_ERROR, GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import { Card, CardActionsContainer } from './LoginComponents';
import Button from '../../components/buttons/Button';
import TextInput from '../inputs/TextInput';
import { Paths } from '../../constants/routes';
import { useNavigate } from 'react-router';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { Box } from '@mui/material';
import Flex from '../utils/flex/Flex';
import { isMobileOrTablet } from '../../utils/device';
import Logo from '../logo/Logo';

const getSchema = (i18n: any) => {
  return Joi.object().keys({
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: false })
      .messages({
        'string.email': 'errors.invalidEmailFormat.message',
        'string.empty': 'errors.emailRequired.message',
      })
      .label(i18n.t('labels_email')),
  });
};

interface FormProps {
  email: string;
}
const SignInCard = () => {
  const {
    authStore: { login, tenantRedirect, getRedirectResult, isUserLoggedIn, firebaseUser },
    userStore: { getUserInformation, updateUserInformation },
    localizationStore: { i18next: i18n },
  } = useStore();
  const isMobile = isMobileOrTablet();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [shouldRedirectToTenant, setShouldRedirectToTenant] = useState(false);
  const { firebaseDisplayName, firebaseProfilePicture } = firebaseUser;

  const getUserInfoAndNavigate = async (firebaseDisplayName: string, firebaseProfilePicture: string) => {
    const { fullName, profilePicture } = await getUserInformation();
    if (!fullName || !profilePicture) {
      // if the user has no name or no profile picture, we update the user with the data from firebase
      await updateUserInformation({
        ...(!fullName && firebaseDisplayName && { fullName: firebaseDisplayName }),
        ...(!profilePicture && firebaseProfilePicture && { profilePicture: firebaseProfilePicture }),
      });
    }
    if (!fullName && !firebaseDisplayName) {
      navigate(Paths.MEMBER_SIGN_UP);
    } else {
      navigate(Paths.ADMIN_PORTAL);
    }
  };
  if (isUserLoggedIn) {
    getUserInfoAndNavigate(firebaseDisplayName, firebaseProfilePicture);
  }
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { isLogged, firebaseDisplayName, firebaseProfilePicture, shouldRedirectToTenant } =
        await getRedirectResult();
      setShouldRedirectToTenant(!!shouldRedirectToTenant);
      // console.log('isUserLoggedIn ------------------->> ', isUserLoggedIn);
      if (isLogged || isUserLoggedIn) {
        getUserInfoAndNavigate(firebaseDisplayName || '', firebaseProfilePicture || '');
      } else {
        if (!shouldRedirectToTenant && isLogged === null && isMobile) {
          await login(isMobile);
        }
      }
      isLogged === false && setAuthenticationError(true);
      setLoading(false);
    })();
  }, []);

  const [formData, setFormData] = useState({
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
  });
  const [hasAuthenticationError, setAuthenticationError] = useState(false);

  const generalValidation = (input: FormProps, name?: string): string | undefined | null | { [key: string]: any } => {
    const results = getSchema(i18n).validate(input, { abortEarly: false });

    if (results.error && name) {
      const error = results.error.details.find(obj => obj.path[0] === name);
      return error?.message;
    }

    if (results.error && !name) {
      let errorsObj: { [key: string]: any } | null = null;
      results.error.details.forEach(detail => {
        errorsObj = {
          ...(errorsObj || {}),
          [detail.path[0]]: detail.message,
        };
      });

      return errorsObj;
    }

    return null;
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
    const error = generalValidation(updatedFormData, name);

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
  };

  const onSubmit = () => {
    const errors = generalValidation(formData);
    console.log('errors', errors);
    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }
    if (formData.email) {
      fireTenantLogin(formData.email);
    }
  };

  const fireTenantLogin = async (email: string) => {
    try {
      await tenantRedirect(email);
    } catch (e) {
      console.log('Error: ', e);
      setAuthenticationError(true);
    }
  };
  const fireLogin = async () => {
    try {
      await login(isMobile);
    } catch (e) {
      console.log('Error: ', e);
      setAuthenticationError(true);
    }
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Card>
      <Logo size={48} />

      {loading && (
        <Box>
          <LoadingSpinner />
        </Box>
      )}

      {!loading && (
        <CardActionsContainer width={'350px'}>
          {shouldRedirectToTenant ? (
            <>
              <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: '6px' }}>
                  {i18n.t('authentication.signIn.message')}
                </Typography>
                <Typography variant="body2" sx={{ color: GRAY_COLORS.GRAY_8 }}>
                  {i18n.t('authentication.signIn.description')}
                </Typography>
                {hasAuthenticationError && (
                  <Typography variant="body2" sx={{ color: COLOR_ERROR, lineHeight: '24px' }}>
                    {`Authentication error!`}
                  </Typography>
                )}
              </Flex>

              <Flex sx={{ marginTop: '24px', marginBottom: '24px', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant={'subtitle2'}>{i18n.t('labels.email')}</Typography>

                <TextInput
                  id="email"
                  name={'email'}
                  placeholder={'Enter your email...'}
                  value={formData.email}
                  error={formData.email && i18n.t(formErrors.email)}
                  onKeyUp={onKeyUp}
                  onChange={onChange}
                  variant={'outlined'}
                  type={'email'}
                  testId={'login-email'}
                />
              </Flex>
              {/*// TODO: fix on click*/}
              <Button onClick={onSubmit} sx={{ fontWeight: 600 }}>
                {i18n.t('authentication.next.action')}
              </Button>
            </>
          ) : (
            <>
              <Flex sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: '6px' }}>
                  {i18n.t('authentication.signIn.message')}
                </Typography>
                <Typography variant="body2" sx={{ color: GRAY_COLORS.GRAY_8 }}>
                  {i18n.t('authentication.signIn.descriptionSSO')}
                </Typography>
                {hasAuthenticationError && (
                  <>
                    <br />
                    <Typography variant="body2" sx={{ color: COLOR_ERROR, lineHeight: '24px' }}>
                      {`Authentication error!`}
                    </Typography>
                  </>
                )}
                <br />
                <br />
              </Flex>
              <Button onClick={() => fireLogin()} sx={{ fontWeight: 600 }}>
                {i18n.t('authentication.signIn.action')}
              </Button>
            </>
          )}
        </CardActionsContainer>
      )}
    </Card>
  );
};

export default observer(SignInCard);
